import 'base'
import { error_to_html } from '../elements'
import { h } from 'ext/el'
import * as fschema from 'htext/parser/schema'
import { block_from_htext as from_htext } from '../doc/htext_parsers'
import { BlockEl, ResolveContext, HtmlContext } from '../doc'
import { head_to_text } from 'keep/blocks'
import { screen_width_md_or_more7 } from 'ext/browser'

export class ImagesBlockEl implements BlockEl {
  type = 'images'
  resolved?: Result<string[]>
  constructor(
    readonly id: string | nil, readonly tags: string[],
    readonly path: string, readonly options: { cols?: number }
  ) {}

  async resolve(ctx: ResolveContext) {
    try {
      if (!this.path.end_with7('/')) raise(`Images path should end with /: ${this.path}`)
      const images = (await ctx.read_dir(this.path))
        .filter_map(({ type, path }) => type == 'file' ? path : nil)
        .order()
      this.resolved = to_success(images)
    } catch (e) {
      const error = error_message(e)
      this.resolved = to_error(error)
      ctx.errors.add({ error })
    }
  }

  to_text() { return [...head_to_text(this.tags, this.options), ...this.path] }

  to_html(ctx: HtmlContext) {
    const resolved = ensure(this.resolved)
    if (resolved.error7) return error_to_html(this.path, [resolved.error])
    const images = resolved.result.map(path => ctx.asset_path(path))

    let cols = this.options.cols || Math.min(images.length, 4)
    if (!screen_width_md_or_more7()) cols = cols > 4 ? 2 : 1

    const cols_separator_width = 1
    const image_width = (100 - (cols - 1)*cols_separator_width) / cols

    let i = 0; const tbody = h('tbody', {})
    for (let ri = 0; ri <= (images.length / cols).ceil(); ri++) {
      const row = h('tr', {}); tbody.children.add(row)
      for (let ci = 0; ci < cols * 2 - 1; ci++) {
        if (ci % 2 != 0) {
          row.children.add(h('td', { style: `width: ${cols_separator_width}%;` }))
        } else {
          const a_class = 'image_container overflow-hidden rounded bg-black' // border border-gray-200
          const a_style = `display: flex; width: 100%; aspect-ratio: 1.5`
          row.children.add(h('td', { style: `width: ${image_width}%; text-align: center; vertical-align: middle;` },
            i < images.length ? h('a', { href: images[i], target: '_blank', class: a_class, style: a_style },
              h('img', { src: images[i] })
            ) : nil
          ))
          i++
        }
      }
    }

    const style = "border-spacing: 0 0.6rem; margin: -0.6rem 0; border-collapse: separate;"
    return h('table', { cellspacing: 0, cellpadding: 0, style: style }, tbody).to_html()
  }
}
from_htext('images', async (id, tags, b: fschema.ImagesBlock) => {
  return new ImagesBlockEl(id, tags, b.path, b.options)
})