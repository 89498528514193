import 'base'
import { fs, get_base_name } from 'ext/fs'
import { parse } from 'htext/parser/parser'
import { Pos } from 'htext/lexer/schema'
import * as fschema from 'htext/parser/schema'
import { BlockEl, Chapter, Doc, DocError, HText, HTextItem, Section } from '../doc'
import { ErrorEl } from 'keep/elements'
import { Props } from 'keep/blocks'
import { block_parsers, element_parsers } from './htext_parsers'

export interface FromFtextContext { source: string, errors: DocError[] }

// Parser ------------------------------------------------------------------------------------------
export async function load_htext(path: string): Promise<Doc> {
  const source = await fs.read(path)
  return await parse_htext(source, get_base_name(path), path)
}

export async function parse_htext(source: string, second_title?: string, loc?: string): Promise<Doc> {
  const fd = parse(source), ctx: FromFtextContext = { source, errors: [] }

  const ids = new HSet<string>()
  function parse_id(props: Props | nil, pos: Pos | nil): string | nil {
    if (!props) return
    const raw_id = props['id']
    if (nil7(raw_id)) return
    if (!(string7(raw_id) || number7(raw_id))) {
      ctx.errors.add({ error: `id should be string or number: ${raw_id}`, context: pos ? source.get(...pos): nil })
      return
    }
    const id = '' + raw_id
    if (ids.has7(id)) {
      ctx.errors.add({ error: `duplicate id: ${id}`, context: pos ? source.get(...pos): nil })
      return
    }
    ids.add(id)
    return id
  }

  async function parse_block(fb: fschema.ABlock): Promise<BlockEl | nil> {
    const parser = block_parsers[fb.type]
    if (!parser) {
      ctx.errors.add({ error: `no parser for block type: ${fb.type}`, context: nil })
      return nil
    }
    try {
      const block = await parser(parse_id(fb.props, fb.pos), fb.tags || [], fb, ctx)
      if (block) block.loc = fb.pos
      return block
    } catch (e) {
      ctx.errors.add({ error: `can't parse block: ${get_error_message(e)}`, context: source.get(...fb.pos) })
      return nil
    }
  }

  async function parse_chapter(fc: fschema.Chapter): Promise<Chapter> { return new Chapter(
    parse_id(fc.props, fc.pos),
    fc.title,
    fc.tags || [],
    (await Promise.map_parallel(fc.blocks, parse_block)).filter_map(),
    fc.pos
  ) }

  async function parse_section(fs: fschema.Section): Promise<Section> { return new Section(
    parse_id(fs.props, fs.pos),
    fs.title,
    fs.tags || [],
    (await Promise.map_parallel(fs.chapters, parse_chapter)).filter_map(),
    fs.pos
  ) }

  const sections = (await Promise.map_parallel(fd.sections, parse_section)).filter_map()
  const fd_errors = (fd.errors || []).map(({ error, pos }) => ({ error, context: source.get(...pos) }))

  const doc = new Doc(nil, fd.title ?? second_title, fd.tags || [], loc)
  doc.sections.push(...sections)
  doc.errors.push(...fd_errors, ...ctx.errors)
  return doc
}

// Text parsers ------------------------------------------------------------------------------------
export async function parse_text_items(htext: fschema.HypertextItem, context: FromFtextContext): Promise<HTextItem>
export async function parse_text_items(htext: fschema.Hypertext, context: FromFtextContext): Promise<HText>
export async function parse_text_items(htext: fschema.Hypertext[], context: FromFtextContext): Promise<HText[]>
export async function parse_text_items(
  htext: fschema.HypertextItem | fschema.Hypertext | fschema.Hypertext[], context: FromFtextContext
) {
  if (array7(htext)) {
    if (htext.length == 0) {
      return []
    } else if (array7(htext[0])) {
      return await Promise.map_parallel(htext as fschema.Hypertext[], h => parse_text_items_impl(h, context))
    } else {
      return parse_text_items_impl(htext as fschema.Hypertext, context)
    }
  } else {
    const parsed = await parse_text_items_impl([htext], context)
    assert.equal(parsed.length, 1)
    return parsed[0]
  }
}

async function parse_text_items_impl(htext: fschema.Hypertext, ctx: FromFtextContext): Promise<HTextItem[]> {
  const r: HTextItem[] = []

  for (const item of htext) {
    const parser = element_parsers[item.t]
    if (!parser) {
      const text = ctx.source.get(...item.pos), error = `No parser for text el: ${item.t}`
      ctx.errors.push({ error, context: text })
      r.add(new ErrorEl(text, [error]))
      continue
    }

    const el = await parser(item, ctx) as HTextItem
    if (item.em) el.em = true
    r.push(el)
  }

  return r
}

// Block parsers -----------------------------------------------------------------------------------
if (import.meta.main) {
  // const text = await fs.read('keep/samples/sample-page.ht')
  const text = await fs.read('/notes/forest.ht')
  const doc = await parse_htext(text)
  inspect(doc)
}