import type { Interpolate, Mark, MarkSpec, Properties, FieldType, EncodignValue,
  Channel, Aggregate, Encoding, ScaleDomain, ScaleRange, TimeUnit, BinValue,
  Calculate, Filter, Facet, ColumnsFacet, RowFacet, ColumnFacet, Concat, Plot, ScaleType,
  EncodignSimpleValue, LayerPlot, Value, Data
} from "./schema"

export * from './schema'

export type DefinedValue = string | number | boolean | Date
export type TidyRow      = Record<string, DefinedValue | undefined>
export type TidyData     = TidyRow[]

// Properties --------------------------------------------------------------------------------------
export const PropertiesKeys = ['width', 'height', 'config']

export function parse_properties(properties: Properties): Properties {
  for (const key in properties)
    if (PropertiesKeys.indexOf(key) < 0) throw new Error(`invalid property '${key}'`)

  validate_type('width', properties.width, ['number', 'undefined'])
  validate_type('height', properties.height, ['number', 'undefined'])

  return properties
}


// Interpolate -------------------------------------------------------------------------------------
export const InterpolateValues = [
  'linear',  'linear-closed',  'step',  'step-before',  'step-after',  'basis',  'basis-open',
  'basis-closed',  'cardinal',  'cardinal-open',  'cardinal-closed',  'bundle',  'monotone']

export function parse_interpolate(value: Interpolate): Interpolate {
  if (InterpolateValues.indexOf(value) < 0)
    throw new Error(`invalid interpolate '${value}', available are ${InterpolateValues.join(', ')}`)
  return value
}


// Mark --------------------------------------------------------------------------------------------
export const MarkValues = [
  'point',  'circle',  'line',  'area',  'bar',  'rect',  'rule',  'square',  'text',  'tick',  'trail']

export function parse_mark(value: Mark): Mark {
  if (MarkValues.indexOf(value) < 0)
    throw new Error(`invalid mark '${value}', available are ${MarkValues.join(', ')}`)
  return value
}


// MarkSpec ----------------------------------------------------------------------------------------
export const MarkSpecKeys = ['mark', 'color', 'size', 'point', 'interpolate', 'opacity', 'thickness', 'vega']

export function parse_mark_spec(spec: MarkSpec): VegaMarkSpec {
  for (const key in spec) if (MarkSpecKeys.indexOf(key) < 0)
    throw new Error(`unknown mark property '${key}', available are ${MarkSpecKeys.join(', ')}`)

  const { mark, color, size, point, interpolate, opacity, thickness, vega } = spec
  validate_type('color', color, ['string', 'undefined'])
  validate_type('size', size, ['number', 'undefined'])
  validate_type('point', point, ['boolean', 'undefined'])
  if (interpolate) parse_interpolate(interpolate)
  validate_type('opacity', opacity, ['number', 'undefined'])
  validate_type('thickness', thickness, ['number', 'undefined'])
  validate_type('vega', vega, ['object', 'undefined'])

  return {
    type: parse_mark(mark),
    ...(color !== undefined ? { color } : {}),
    ...(size  !== undefined ? { size }  : {}),
    ...(point  !== undefined ? { point }  : {}),
    ...(interpolate  !== undefined ? { interpolate }  : {}),
    ...(opacity  !== undefined ? { opacity } : {}),
    ...(thickness  !== undefined ? { thickness } : {}),
    ...(vega  !== undefined ? vega  : {})
  }
}


// FieldType ---------------------------------------------------------------------------------------
export const FieldTypeValues = [
  'nominal',  'ordinal',  'quantitative',  'temporal']

export function parse_field_type(value: FieldType): FieldType {
  if (FieldTypeValues.indexOf(value) < 0)
    throw new Error(`invalid field type '${value}', available are ${FieldTypeValues.join(', ')}`)
  return value
}


// Scale -------------------------------------------------------------------------------------------
export const ScaleTypeValues = [
  'linear',  'pow',  'sqrt',  'symlog',  'log',  'time',  'utc',
  'ordinal',  'band',  'point',
  'bin-ordinal',  'quantile',  'quantize',  'threshold']

export function parse_scale_type(value: ScaleType): ScaleType {
  if (ScaleTypeValues.indexOf(value) < 0)
    throw new Error(`invalid scale type '${value}', available are ${ScaleTypeValues.join(', ')}`)
  return value
}


// Aggregate ---------------------------------------------------------------------------------------
export const AggregateValues = [
  'count',  'valid',  'missing',  'distinct',  'sum',  'mean',  'average',
  'variance',  'variancep',  'stdev',  'stdevp',  'stderr',  'median',  'q1',  'q3',  'ci0',  'ci1',
  'min',  'max',  'argmin',  'argmax',
  'product']

export function parse_aggregate(value: Aggregate): Aggregate {
  if (AggregateValues.indexOf(value) < 0)
    throw new Error(`invalid aggregate '${value}', available are ${AggregateValues.join(', ')}`)
  return value
}


// Channel -----------------------------------------------------------------------------------------
export const ChannelValues = [
  'x',  'y',  'x2',  'y2',  'xError',  'yError',  'xError2',  'yError2',
  'longitude',  'latitude',  'longitude2',  'latitude2',
  'color',  'opacity',  'fillOpacity',  'strokeOpacity',  'shape',  'size',  'strokeWidth',
  'text',  'tooltip',
  'href',
  'detail',
  'key',
  'order']

export function parse_channel(value: Channel): Channel {
  if (ChannelValues.indexOf(value) < 0)
    throw new Error(`invalid channel '${value}', available are ${ChannelValues.join(', ')}`)
  return value
}


// ScaleDomain -------------------------------------------------------------------------------------
export function parse_scale_domain(values: ScaleDomain): ScaleDomain {
  return values.map((v) => {
    validate_type('scale domain', v, ['number', 'string', 'boolean'])
    return v
  })
}


// ScaleRange --------------------------------------------------------------------------------------
export function parse_scale_range(value: ScaleRange): ScaleRange {
  if (typeof value == 'string') {
    validate_type('scale range', value, ['string'])
    return value
  } else {
    return value.map((v) => {
      validate_type('scale domain', v, ['number', 'string'])
      return v
    })
  }
}


// TimeUnit ----------------------------------------------------------------------------------------
export const TimeUnitValues = [
  'year',  'yearquarter',  'yearquartermonth',  'yearmonth',  'yearmonthdate',
  'yearmonthdatehours',  'yearmonthdatehoursminutes',  'yearmonthdatehoursminutesseconds',  'quarter',
  'quartermonth',  'month',  'monthdate',  'date',  'day',  'hours',  'hoursminutes',  'hoursminutesseconds',
  'minutes',  'minutesseconds',  'seconds',  'secondsmilliseconds',  'milliseconds']
export function parse_time_unit(value: TimeUnit): TimeUnit {
  if (TimeUnitValues.indexOf(value) < 0)
    throw new Error(`invalid time unit '${value}', available are ${TimeUnitValues.join(', ')}`)
  return value
}


// BinValue ----------------------------------------------------------------------------------------
export function parse_bin(value: BinValue): VegaBinSpec {
  validate_type('bin', value, ['string', 'number', 'boolean', 'undefined'])
  if (typeof value == 'string' && value != 'binned')
    throw new Error(`bin should have type of "boolean | number | 'binned'"`)

  if      (typeof value == 'boolean') return value
  else if (typeof value == 'string')  return value
  else                                return  { base: value, maxbins: value }
}


// Calculate ---------------------------------------------------------------------------------------
export const CalculateKeys = ['calculate', 'as']
export function parse_calculate(calculate: Calculate): Calculate {
  for (const key in calculate) if (CalculateKeys.indexOf(key) < 0)
    throw new Error(`unknown calculate property '${key}', available are ${CalculateKeys.join(', ')}`)
  return calculate
}


// Filter ------------------------------------------------------------------------------------------
export const FilterKeys = ['filter']
export function parse_filter(filter: Filter): Filter {
  for (const key in filter) if (FilterKeys.indexOf(key) < 0)
    throw new Error(`unknown filter property '${key}', available are ${FilterKeys.join(', ')}`)
  return filter
}


// Encoding ----------------------------------------------------------------------------------------
export const SharedEncodingKeys = [
  'type', 'aggregate', 'legend', 'title', 'labels', 'time_unit', 'timeUnit', 'bin', 'zero', 'domain', 'range',
  'scale', 'value', 'override_value', 'scheme', 'reverse', 'vega']

export function parse_encoding(encoding: Encoding): [Channel, VegaEncoding, TidyData?] {
  const channels: string[] = []
  for (const key in encoding) {
    if (SharedEncodingKeys.indexOf(key) < 0) {
      if (ChannelValues.indexOf(key) < 0)
        throw new Error(
          `unknown encoding property '${key}', available are ` +
          `${ChannelValues.join(', ')}, ${SharedEncodingKeys.join(', ')}`
        )
      else
        channels.push(key)
    }
  }
  if (channels.length == 0)
    throw new Error(`encoding channel not specified, please specify one of ${ChannelValues.join(', ')}`)
  if (channels.length > 1)
    throw new Error(
      `only one channel allowed in the same encoding, leave only one of ${channels.join(', ')}`
    )

  if (encoding.type)      parse_field_type(encoding.type)
  if (encoding.aggregate) parse_aggregate(encoding.aggregate)
  validate_type('legend', encoding.legend, ['string', 'boolean', 'undefined'])
  validate_type('title',  encoding.title,  ['string', 'boolean', 'undefined'])
  validate_type('labels', encoding.labels, ['boolean', 'undefined'])
  if (encoding.time_unit) parse_time_unit(encoding.time_unit)
  if (encoding.timeUnit)  parse_time_unit(encoding.timeUnit)
  if (encoding.bin)       parse_bin(encoding.bin)
  validate_type('zero', encoding.zero, ['boolean', 'undefined'])
  if (encoding.scale)     parse_scale_type(encoding.scale)
  if (encoding.domain)    parse_scale_domain(encoding.domain)
  if (encoding.range)     parse_scale_range(encoding.range)
  // validate_type('value', encoding.value, ['string', 'number', 'boolean', 'undefined'])
  validate_type('scheme', encoding.scheme, ['string', 'undefined'])
  validate_type('reverse', encoding.reverse, ['boolean', 'undefined'])
  validate_type('vega', encoding.vega, ['object', 'undefined'])

  // Parsing
  const { type, title, labels, aggregate, legend, timeUnit, time_unit, bin, zero, domain, range, scale,
    value, scheme, reverse, vega, override_value } = encoding
  const channel = ChannelValues.find((channel) => channel in encoding) as (Channel | undefined)
  if (!channel) throw new Error(`Channel not found in ${JSON.stringify(encoding)}!`)
  const field = (encoding as any)[channel]

  const axis: VegaAxis = {
    ...((vega !== undefined && 'axis' in vega) ? vega['axis'] as object : {}),

    // Vega allowing only string or null for title
    ...(
      (title && (typeof title == 'string')) ? { title } : (title ? {} : { title: null })
    ),
    ...(labels !== undefined ? { labels } : {})
  }

  const vega_scale: VegaScale = {
    ...((vega !== undefined && 'scale' in vega) ? vega['scale'] as object : {}),

    ...(scale !== undefined ? { type: scale } : {}),
    ...(domain !== undefined ? { nice: false } : {}),
    ...(zero !== undefined ? { zero } : {}),
    ...(domain !== undefined ? { domain } : {}),
    ...(range !== undefined ? { range } : {}),
    ...(scheme !== undefined ? { scheme } : {}),
    ...(reverse !== undefined ? { reverse } : {})
  }

  let vega_encoding: VegaEncoding = {
    ...(vega !== undefined ? vega : {}),

    ...(field !== "" ? { field } : {}),
    type: type || 'quantitative',
    ...(aggregate !== undefined ? { aggregate } : {}),
    ...(legend !== undefined ? { legend } : { legend: false }),
    ...(timeUnit !== undefined ? { timeUnit } : {}),
    ...(time_unit !== undefined ? { timeUnit: time_unit } : {}),
    ...(Object.keys(axis).length > 0 ? { axis } : {}),
    ...(Object.keys(vega_scale).length > 0 ? { scale: vega_scale } : {}),
    ...(bin !== undefined ? { bin: parse_bin(bin) } : {}),
  }

  let table_data: TidyData | undefined = undefined
  if (value) {
    const result = parse_and_add_encoding_value(value, channel, vega_encoding)
    vega_encoding = result[0]
    table_data = result[1]
  }

  return [channel, vega_encoding, table_data]
}


// EncodingValue -----------------------------------------------------------------------------------
// Simple case
//
//   `value: 'red'`
//
// Conditional value
//
//   `value: ['datum.value > 0', 'red']`
//   `value: ['datum.value > 0', 1, 2]`
//
// Explicit value value on `x` and `y` encoding channels will be transformed into data
// needed to simplify rule definition.
//
//   value: 1
//
// will be transformed into
//
//   data: {
//     values: {
//       explicit_value: 1
//     }
//   }
//
//   { field: 'explicit_value' }
//
const explicit_value_channels = ['x', 'y']
export function parse_and_add_encoding_value(
  value: EncodignValue, channel: Channel, vega_encoding: VegaEncoding
): [VegaEncoding, TidyData?] {
  const parse_simple_value = (v: EncodignSimpleValue | undefined): EncodignSimpleValue => {
    if (v === undefined) throw new Error(`encoding value shouldn't be undefined`)
    validate_type('encoding value', v, ['number', 'string', 'boolean'])
    return v
  }

  if (Array.isArray(value)) {
    // Conditional value
    // `value: ['datum.value > 0', 'red']`
    // `value: ['datum.value > 0', 1, 2]`

    if ('condition' in vega_encoding) throw new Error(`Conditional value can't be used with condition!`)

    if (value.length == 2) {
      const [condition, v] = value
      return [{
        ...vega_encoding,
        condition: {
          test:  condition,
          value: parse_simple_value(v)
        }
      }, undefined]
    } else if (value.length == 3) {
      const [condition, v, v2] = value
      return [{
        ...vega_encoding,
        value:     parse_simple_value(v2),
        condition: {
          test: condition,
          value: parse_simple_value(v)
        }
      }, undefined]
    } else
      throw new Error(
        `encoding value should be a simple value or array with 2 ['datum.value > 0', 'green']` +
        ` or 3 elements ['datum.value > 0', 1, -1]`
      )
  } if (explicit_value_channels.indexOf(channel) >= 0) {
    // Explicit value value
    //
    //   value: 1
    //
    // will be transformed into
    //
    //   data: {
    //     values: {
    //       explicit_value: 1
    //     }
    //   }
    //
    //   { field: 'explicit_value' }
    if (!(vega_encoding.field == '' || vega_encoding.field === undefined || vega_encoding.field === null))
      throw new Error(`value on ${explicit_value_channels.join(', ')} channels cannot be used with 'field'`)

    const table_data: TidyData = [
      { explicit_value: parse_simple_value(value) }
    ]
    return [{
      ...vega_encoding,
      field: 'explicit_value'
    }, table_data]
  } else {
    return [{ ...vega_encoding, value: parse_simple_value(value) }, undefined]
  }
}


// Facet -------------------------------------------------------------------------------------------
export const FacetKeys = ['column', 'row', 'type', 'facet']
export function parse_facet(facet: Facet): Facet {
  for (const key in facet) if (FacetKeys.indexOf(key) < 0)
    throw new Error(`unknown facet property '${key}', available are ${FacetKeys.join(', ')}`)
  return facet
}

function to_vega_facet(facets: Facet[], spec: VegaSpec, vega_props: VegaProps): VegaFacetSpec {
  let facet: ColumnsFacet | undefined = undefined
  for (const f of facets) if ('facet' in f)  facet = f
  if (facet) {
    if (facets.length > 1) throw new Error(`Can't other facets with 'facet' keyword`)
    return {
      facet: {
        field:   facet.facet,
        type:    facet.type || 'ordinal',
        header:  { title: null }
      },
      ...(facet.columns !== undefined ? { columns: facet.columns } : {}),
      spec,
      ...vega_props
    }
  } else {
    const row_count = facets.filter((definition) => 'row' in definition).length
    if (row_count > 1) throw new Error(`Facet can't have more than one row`)

    const column_count = facets.filter((definition) => 'column' in definition).length
    if (column_count > 1) throw new Error(`Facet can't have more than one column`)

    let  row: RowFacet | undefined = undefined, column: ColumnFacet | undefined = undefined
    for (const facet of facets) {
      if ('row' in facet)    row = facet
      if ('column' in facet) column = facet
    }

    return {
      facet: {
        ...(row ? { row: {
          field:  row.row,
          type:   row.type || 'ordinal',
          header: { title: null }
        } } : {}),
        ...(column ? { column: {
          field:  column.column,
          type:   column.type || 'ordinal',
          header: { title: null }
        } } : {})
      },
      spec,
      ...vega_props
    }
  }
}


// Concat ------------------------------------------------------------------------------------------
export const ConcatKeys = ['concat']
export function parse_concat(concat: Concat): Concat {
  for (const key in concat) if (ConcatKeys.indexOf(key) < 0)
    throw new Error(`unknown concat property '${key}', available are ${ConcatKeys.join(', ')}`)
  return concat
}


// Vega Interfaces ---------------------------------------------------------------------------------
export interface VegaAxis {
  readonly title?:  string | null
  readonly labels?: boolean
  readonly orient?: string
}

export interface VegaScale {
  readonly type?:    ScaleType
  readonly domain?:  ScaleDomain
  readonly range?:   ScaleRange
  readonly zero?:    boolean
  readonly nice?:    boolean
  readonly scheme?:  string
  readonly reverse?: boolean
}

export interface VegaMarkSpec {
  readonly type:    string
  readonly color?:  string
  readonly point?:  boolean
  readonly size?:   number
  readonly stroke?: string
}

export type VegaBinSpec = {
  base?:    number
  maxbins?: number
} | boolean | 'binned'

export interface VegaCondition {
  readonly test:  string
  readonly value: string | number | boolean
}

export interface VegaEncoding {
  readonly field?:     string
  readonly format?:    string
  readonly type:       FieldType
  readonly axis?:      VegaAxis
  readonly scale?:     VegaScale
  readonly aggregate?: Aggregate
  readonly legend?:    string | boolean
  readonly timeUnit?:  TimeUnit
  readonly bin?:       VegaBinSpec
  readonly condition?: VegaCondition
  readonly value?:     string | number | boolean
}

export interface VegaRowColumnFacet {
  readonly column?: { field: string, type: FieldType, header: { title?: string | null } }
  readonly row?:    { field: string, type: FieldType, header: { title?: string | null } }
}
export interface VegaColumnsFacet {
  field: string, type: FieldType, header: { title?: string | null }
}
export type VegaFacet = VegaRowColumnFacet | VegaColumnsFacet

export interface VegaLayerSpec {
  readonly mark?:      Mark | VegaMarkSpec
  readonly height?:    number
  readonly encoding?:  { readonly [key in Channel]?: VegaEncoding }
  // readonly width?:     number
  // readonly height?:    number
  readonly data?:      {
    values: TidyData
  }
}

export interface VegaSpec extends VegaLayerSpec {
  readonly layer?:     VegaLayerSpec[]
}

export interface VegaFacetSpec {
  readonly columns?: number
  readonly facet:    VegaFacet
  readonly spec:     VegaSpec
  // readonly width?:  number
  // readonly height?: number
  // readonly config?: any
}

export interface VegaConcatSpec {
  // readonly width?:  number
  // readonly height?: number
  readonly hconcat?: VegaSpec[]
  readonly vconcat?: VegaSpec[]
}

export interface VegaProps {
  readonly transform?: (Calculate | Filter)[]
  readonly config?:    any
  readonly width?:     number
  readonly height?:    number
}

export type VegaFullSpec = (VegaSpec | VegaFacetSpec | VegaConcatSpec) & VegaProps


// to_vega -----------------------------------------------------------------------------------------
export function to_vega(plot: Plot): VegaFullSpec {
  const top_level_marks: (Mark | VegaMarkSpec)[] = []
  let top_level_vega_encodings: { readonly [key in Channel]?: VegaEncoding } = {}
  let transforms: (Calculate | Filter)[] = []
  let vega_layers: VegaLayerSpec[] = [], custom_vega: Object | undefined = undefined
  let properties: Properties = {}
  const facets: Facet[] = [], concats: Concat[] = []
  for (const part of plot) {
    if      (typeof part == 'string') top_level_marks.push(parse_mark(part))
    else if ('mark' in part)          top_level_marks.push(parse_mark_spec(part))
    else if (Array.isArray(part))     vega_layers = [...vega_layers, to_vega_layer(part)]
    else if ('calculate' in part)     transforms.push(parse_calculate(part))
    else if ('filter' in part)        transforms.push(parse_filter(part))
    else if (
              'facet'  in part ||
              'row'    in part ||
              'column' in part
            )                         facets.push(part)
    else if ('concat' in part)        concats.push(parse_concat(part))
    else if (
              'height' in part ||
              'width'  in part ||
              'config' in part
            )                         properties = { ...properties, ...parse_properties(part) }
    else if (ChannelValues.some((channel) => channel in part)) {
      const [channel, vega_encoding, table_data] = parse_encoding(part as Encoding)
      if (channel in vega_encoding)
        throw new Error(`Can't redefine channel ${channel}!`)
      if (table_data !== undefined)
        throw new Error(`Explicit value for '${channel}' allowed only inside of a layer`)
      top_level_vega_encodings = { ...top_level_vega_encodings, [channel]: vega_encoding }
    }
    else if ('vega' in part)          custom_vega = part.vega
    else                              throw new Error(`unknown plot part ${JSON.stringify(part)}`)
  }

  if (top_level_marks.length > 1) throw new Error(`Multiple marks found ${top_level_marks.join(', ')}`)

  const vega_props: VegaProps = {
    ...(transforms.length > 0 ? { transform: transforms } : {}),
    ...properties,
    ...(custom_vega ? custom_vega as any : {})
  }

  function top_level_spec() {
    return {
      ...(top_level_marks.length > 0 ? { mark: top_level_marks[0] } : {}),
      ...(Object.keys(top_level_vega_encodings).length > 0 ? { encoding: top_level_vega_encodings } : {}),
      ...(vega_layers.length > 0 ? { layer: vega_layers } : {})
    }
  }

  if (facets.length > 0) {
    if (concats.length > 0) throw new Error(`concat can't be used with facet`)
    return to_vega_facet(facets, top_level_spec(), vega_props)
  } else if (concats.length > 0) {
    if (concats.length > 1)                  throw new Error(`there could be only one concat`)
    if (facets.length > 0)                   throw new Error(`concat can't be used with facet`)
    if (top_level_marks.length > 0)          throw new Error(`top level mark can't be used with concat`)
    if (Object.keys(top_level_vega_encodings).length > 0)
      throw new Error(`top level encoding can't be used with concat`)
    if (vega_layers.length == 0)             throw new Error(`concat requires at least one layer`)

    return {
      [concats[0].concat == 'vertical' ? 'vconcat' : 'hconcat']: vega_layers,
      ...vega_props
    }
  } else {
    return {
      ...top_level_spec(),
      ...vega_props
    }
  }
}


// to_vega_layer -----------------------------------------------------------------------------------
export function to_vega_layer(plot: LayerPlot): VegaLayerSpec {
  const marks: (Mark | VegaMarkSpec)[] = [], transforms: (Calculate | Filter)[] = []
  let vega_encodings: { readonly [key in Channel]?: VegaEncoding } = {}
  let vega: Object | undefined = undefined, properties: Properties = {}, table_data: TidyData | undefined
  for (const part of plot) {
    if      (typeof part == 'string') marks.push(parse_mark(part))
    else if ('mark' in part)          marks.push(parse_mark_spec(part))
    else if ('calculate' in part)     transforms.push(parse_calculate(part))
    else if ('filter' in part)        transforms.push(parse_filter(part))
    else if ('height' in part || 'width' in part)
      properties = { ...properties, ...parse_properties(part) }
    else if (ChannelValues.some((channel) => channel in part)) {
      const [channel, vega_encoding, td] = parse_encoding(part as Encoding)
      if (channel in vega_encoding) throw new Error(`Can't redefine channel ${channel}!`)
      table_data = td
      vega_encodings = { ...vega_encodings, [channel]: vega_encoding }
    }
    else if ('vega' in part)          vega = part.vega
    else                              throw new Error(`unknown plot layer part ${JSON.stringify(part)}`)
  }

  if (marks.length > 1) throw new Error(`Multiple marks found ${marks.join(', ')}`)

  return {
    ...(transforms.length > 0 ? { transform: transforms } : {}),
    ...(marks.length > 0 ? { mark: marks[0] } : {}),
    ...(Object.keys(vega_encodings).length > 0 ? { encoding: vega_encodings } : {}),
    ...properties,
    ...(table_data ? { data: { values: table_data }} : {}),
    ...(vega ? vega as any : {})
  }
}


// to_table_data -----------------------------------------------------------------------------------
// Converting list of arrays to table if needed
//
//   {
//     date:  ["2012", "2013"],
//     price: [1000, 2000.2]
//   }
//
// Into table
//
//   [
//     { date: "2012", price: 1000 },
//     { date: "2013", price: 2002.2 }
//   ]
//
// null or undefined columsn will be created, so if all column values are undefined it will be
// completelly erasesd. To avoid that its id will be returned in `columns`
export function to_tidy_data(data: Data, columns?: string[], keep_undefined = false): TidyData {
  const clean_row = keep_undefined ? replace_nulls_with_undefined : clean_nulls_and_undefined
  if (data instanceof Array) {
    if (data.length == 0) return []
    if (Array.isArray(data[0])) { // data = [[1, 2], [2, 3]]
      return array_row_to_map_row(data as Value[][], columns || abc_letters).map(clean_row)
    } else { // data = [{ x: 1, y: 2 }, { x: 2, y: 3 }]
      return (data as Record<string, Value>[]).map(clean_row)
    }
  } else { // data = { x: [1, 2], y: [1, 2] }
    if (!(data instanceof Object)) throw new Error('Arrays data expected to be an Object')
    const keys = Object.keys(data)
    if (keys.length == 0) throw new Error('Empty arrays data!')
    const key1 = keys[0]
    const length1 = data[key1].length

    // Ensuring arrays are of the same length
    for (const key2 of keys)
      if (data[key2].length != length1)
        throw new Error(
          `Arrays data has arrays of different length ` +
          `${key2} - ${data[key2].length} and ${key1} - ${length1}`
        )

    // Converting
    const table: TidyData = []
    for (let i = 0; i < length1; i++) {
      const row: any = {}
      for (const key of keys) row[key] = data[key][i]
      table.push(row)
    }
    return table.map(clean_row)
  }
}

const abc_letters = "abcdefghijklmnopqrstuvwxyz".split("")

function array_row_to_map_row(rows: Value[][], columns: string[]): Record<string, Value>[] {
  return rows.map((row) => {
    const mrow: Record<string, Value> = {}
    for (let i = 0; i < row.length; i++) {
      if (i < columns.length) mrow[columns[i]] = row[i]
    }
    return mrow
  })
}


function replace_nulls_with_undefined(row: Record<string, Value>): TidyRow {
  const drow: TidyRow = {}
  for (let k in row) {
    const v = row[k]
    drow[k] = v !== null ? v : undefined
  }
  return drow
}

function clean_nulls_and_undefined(row: Record<string, Value>): TidyRow {
  const drow: TidyRow = {}
  for (let k in row) {
    const v = row[k]
    if (v !== null && v !== undefined) drow[k] = v
  }
  return drow
}


// override_size_in_narrow_mode --------------------------------------------------------------------
export function override_size_in_narrow_mode(
  vega_spec: VegaFullSpec, max_width: number, max_height: number
): VegaFullSpec {
  if (!(vega_spec.width  && vega_spec.width  < max_width))  vega_spec = { ...vega_spec, width: max_width }
  if (!(vega_spec.height && vega_spec.height < max_height)) vega_spec = { ...vega_spec, height: max_height }
  return vega_spec
}


// Utils -------------------------------------------------------------------------------------------
function validate_type(
  key_name:      string,
  value:         undefined | string | boolean | number | Object,
  allowed_types: ('undefined' | 'boolean' | 'string' | 'number' | 'object')[]
) {
  if (allowed_types.indexOf(typeof(value) as any) < 0)
    throw new Error(`invalid type for '${key_name}' allowed types ${allowed_types.join(', ')}`)
}



// function to_vega_concat(concats: Facet[]): VegaFacet {
//   const row_count = facets.filter((definition) => 'row' in definition).length
//   if (row_count > 1) throw new Error(`Facet can't have more than one row`)

//   const column_count = facets.filter((definition) => 'column' in definition).length
//   if (column_count > 1) throw new Error(`Facet can't have more than one column`)

//   let row: RowFacet | undefined = undefined, column: ColumnFacet | undefined = undefined
//   for (const facet of facets) {
//     if ('row' in facet)    row = facet
//     if ('column' in facet) column = facet
//   }

//   return {
//     ...(row ? { row: {
//       field:  row.row,
//       type:   row.type || 'ordinal',
//       header: { title: null }
//     } } : {}),
//     ...(column ? { column: {
//       field:  column.column,
//       type:   column.type || 'ordinal',
//       header: { title: null }
//     } } : {}),
//   }
// }


// function truncate(s: string): string { return s.replace(/^[\s\n]+|[\s\n]+$/g, '') }

// function parse_js_value(s: string): string | number | boolean {
//   s = truncate(s)
//   if      ('' + parseInt(s) == s)   return parseInt(s)
//   else if ('' + parseFloat(s) == s) return parseFloat(s)
//   else if (s == 'false')            return false
//   else if (s == 'true')             return true
//   else                              return s
// }