import 'base'

function to_pred(c: string | RegExp | ((c: string) => boolean)): (c: string) => boolean {
  if      (function7(c)) return c
  else if (string7(c))   return (x: string) => x == c
  else                   {
    // Global RegExp is statefull and doesn't work properly
    // for the next call of `pred` function.
    if (c.global) raise('Cannot use global regex here')
    return (x: string) => c.test(x)
  }
}

export class Parser {
  constructor(
    public readonly text:  string,
    public          i:     number   = 0,
    public readonly warns: string[] = []
  ) {}

  copy(): Parser { return new Parser(this.text, this.i, this.warns) }

  get(shift = 0): string {
    const i = this.i + shift
    if (i < 0 && i >= this.text.length) raise(`Out of bounds: ${i}`)
    return this.text[i]
  }

  is7(c: string | RegExp, shift = 0): boolean {
    const i = this.i + shift
    if (i < 0 && i >= this.text.length) return false
    if (c instanceof RegExp) {
      return c.test(this.text[i])
    } else {
      assert(c.length == 1, 'single char expected')
      return this.text[i] == c
    }
  }

  start_with7(s: string | RegExp, shift = 0): boolean {
    const i = this.i + shift
    if (i < 0 && i >= this.text.length) return false
    return this.text.start_with7(s, i)
  }

  has7(shift = 0): boolean {
    const i = this.i + shift
    return i >= 0 && i < this.text.length
  }

  inc(by: number = 1): void {
    // this.i = Math.min(this.text.length, this.i + by)
    this.i += by
  }

  skip(cond: ((c: string) => boolean) | RegExp | string, shift = 0): void {
    this.inc(shift)
    const pred = to_pred(cond)
    while (this.has7() && pred(this.get())) this.inc()
  }

  skip_spaces(shift = 0): void { this.skip(/\s/, shift) }

  consume(cond?: ((c: string) => boolean) | RegExp | string | number, shift = 0): string {
    this.inc(shift)

    if (cond == nil) {
      const r = this.get()
      this.inc()
      return r
    } else if (number7(cond)) {
      const r: string[] = []
      for (let i = 0; i < cond && this.has7(); i++) r.add(this.consume())
      return r.join('')
    } else {
      const pred = to_pred(cond)
      const r: string[] = []
      while (this.has7() && pred(this.get())) {
        r.push(this.get())
        this.inc()
      }
      return r.join('')
    }
  }

  find(cond: ((c: string) => boolean) | RegExp | string, shift = 0, limit: number = -1): number | nil {
    const pred = to_pred(cond)
    let j = shift
    while (true) {
      if (limit >= 0 && j > limit) break
      const i = this.i + j
      if (i > this.text.length) break
      if (pred(this.text[i])) return j
      j++
    }
    return nil
  }

  rfind(cond: ((c: string) => boolean) | RegExp | string, shift = 0, limit: number = -1): number | nil {
    const pred = to_pred(cond)
    let j = shift
    while (true) {
      if (limit >= 0 && j > limit) break
      const i = this.i - j
      if (i < 0) break
      if (pred(this.text[i])) return j
      j++
    }
    return nil
  }

  fget(a: ((c: string) => boolean) | RegExp | string, shift = 0, limit: number = -1): string | nil {
    const i = this.find(a, shift, limit)
    if (i != nil) return this.get(i)
    return nil
  }

  starts_with(s: string, shift = 0): boolean {
    for (let j = 0; j < s.length; j++) {
      const i = this.i + shift + j
      if (i > this.text.length || s[j] != this.text[i]) return false
    }
    return true
  }

  rest(): string {
    return this.has7() ? this.text.get(this.i, -1) : ''
  }

  line_start7(shift = 0): boolean {
    return (shift + this.i) == 0 || this.is7("\n", shift - 1)
  }

  line_end7(shift = 0): boolean {
    return ((shift + this.i) == 0 && this.text.length == 0) ||
      shift == (this.text.length - 1) || this.is7("\n", shift + 1)
  }
}