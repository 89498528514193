// assert ------------------------------------------------------------------------------------------
import { to_s_inspect } from "./support"

export const assert = <Assert>function(condition, message): void {
  if (condition !== true) throw new Error(
    message ? (message instanceof Function ? message() : message) : 'Assert failed!'
  )
}
assert.equal = (a, b, message) => {
  if (!equal7(a, b)) {
    print_assert_details(a, 'equal7', b)
    throw new Error(message ?
      (message instanceof Function ? message() : message) : `Assert failed: ${to_s(a)} != ${to_s(b)}`
    )
  }
}
assert.fail = (cb, message) => {
  let failed = false
  try { cb() } catch { failed = true }
  if (!failed) throw new Error(message ?
    (message instanceof Function ? message() : message) : `Assert expected to fail but didn't`
  )
}
assert.aequal = (a, b, message, delta_relative) => {
  delta_relative = delta_relative || 0.001
  const average = (Math.abs(a) + Math.abs(b)) / 2
  const delta_absolute = average * delta_relative
  if (Math.abs(a - b) > delta_absolute) throw new Error(
    message ? (message instanceof Function ? message() : message) : `Assert failed: ${a} != ${b}`
  )
}
assert.never = function(v) { throw new Error('Assert never') }

function print_assert_details(a: unknown, op: string, b: unknown, message = 'Assert failed'): void {
  function color_diff(a: string, b: string): [string, string][] {
    const r: [string, string][] = []
    for (let i = 0; i < a.length; i++) {
      if      (i >= b.length) r.push([a[i], 'color: red;'])
      else if (a[i] == b[i])  r.push([a[i], ''])
      else                    r.push([a[i], 'color: red;'])
    }
    return r
  }

  const a_s = to_s_inspect(a).replace(/\n/g, "\n  "), b_s = to_s_inspect(b).replace(/\n/g, "\n  ")

  console.flog([message, 'color: red; font-weight: bold;'],
    [` ${op}`, 'font-weight: bold;'], [' a:', 'font-weight: bold;'])
  // console.flog('  ' + a_s)
  console.flog('  ', ...color_diff(a_s, b_s))
  console.flog(['b:', 'font-weight: bold;'])
  // console.flog('  ' + b_s)
  console.flog('  ', ...color_diff(b_s, a_s))
  console.log()
}

// const assert = window.assert
// window.assert = function(...args: any[]): void {
//   if        (args.length == 1) { const cond = args[0]
//     if (!cond) { throw new Error('Assert failed') }
//   } else if (args.length == 2) { const [a, b] = args
//     if (!equal7(args[0], args[1])) {
//       print_assert_details(a, 'equal7', b)
//       throw new Error('Assert failed')
//     }
//   }
// }
// window.assert_never = function(v: never): never { throw new Error('Assert never') }