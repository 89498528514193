import { runtime } from 'base'

// require -----------------------------------------------------------------------------------------
/** Requires file in both server and browser, synchronously, even in browser. */
export function unirequire(server_prefix: string, browser_prefix: string, path: string): any {
  return runtime == 'browser' ? browser_require_sync(browser_prefix + path) : require(server_prefix + path)
}

function browser_require_sync(url: string): any {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, false)
  xhr.send(null)
  if (xhr.status != 200) throw new Error(`Can't load script: ${url}`)

  const global = window as any
  const original_exports = 'exports' in global ? global.exports : false
  const original_module  = 'module'  in global ? global.module  : false
  try {
    global.exports = {}
    global.module  = { exports }
    eval(xhr.responseText)
    return global.module.exports
  } finally {
    if (original_exports === false) delete global.exports; else global.exports = original_exports
    if (original_module  === false) delete global.module;  else global.module  = original_module
  }
}

// ensure_loaded -----------------------------------------------------------------------------------
const loaded: Record<string, Promise<void>> = {}
/** Loads list of js and css, and waits till it loaded */
export async function ensure_loaded(...urls: string[]): Promise<void> {
  if (urls.length == 0) return
  const scripts: string[] = [], styles: string[] = []
  for (const url of urls) {
    if      (url.endsWith('.js'))  scripts.push(url)
    else if (url.endsWith('.css')) styles.push(url)
    else                           raise(`Not supported type: ${url}`)
  }

  const styles_p = (async function load_styles() {
    for (const url of styles) {
      if (!(url in loaded)) loaded[url] = load_style(url)
      await loaded[url]
    }
  })()

  const scripts_p = (async function load_scripts() {
    for (const url of scripts) {
      if (!(url in loaded)) loaded[url] = load_script(url)
      await loaded[url]
    }
  })()

  await scripts_p; await styles_p
}

// Non-bloking CSS loading -------------------------------------------------------------------------
export function load_style(url: string): Promise<void> { return new Promise((resolve, reject) => {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.onerror = () => {
    link.remove()
    reject(new Error(`Cannot load ${url}`))
  }
  link.onload = () => { resolve() }
  // link.setAttribute('reload', 'false')
  link.href = url
  document.head.appendChild(link)
}) }


// load_script -------------------------------------------------------------------------------------
export function load_script(url: string): Promise<void> { return new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = url
  script.onerror = () => {
    script.remove()
    reject(new Error(`Cannot load ${url}`))
  }
  script.onload = () => {
    script.remove()
    resolve()
  }
  document.body.append(script)
}) }

// load_text ---------------------------------------------------------------------------------------
export async function browser_load_text(url: string): Promise<string> {
  const response = await fetch(url);
  if (!response.ok) raise(`Can't load text: ${url}, status: ${response.status}`)
  return await response.text()
}

export function browser_load_text_sync(url: string): string {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, false)
  xhr.send(null)
  if (xhr.status != 200) throw new Error(`Can't load text: ${url}`)
  return xhr.responseText
}

// get_element_width --------------------------------------------------------------
export function get_element_width(el: HTMLElement, without_padding: boolean): number | undefined {
  function read_property(style: CSSStyleDeclaration, property: string): number | undefined {
    const vstr = ('' + (style as any)[property]).replace(/[^0-9\.]/, '')
    if (vstr == '0x') {
      return 0
    } else if (/[0-9]/.test(vstr)) {
      const v = parseInt(vstr)
      if (!isFinite(v)) throw new Error(`invalid element ${property}`)
      return v
    } else
      return undefined
  }

  var style = window.getComputedStyle(el, null)
  if (without_padding) return read_property(style, 'width')
  else {
    let width = read_property(style, 'width')
    if (width === undefined) return undefined
    const padding_left = read_property(style, 'paddingLeft')
    if (padding_left !== undefined) width -= padding_left
    const padding_right = read_property(style, 'paddingRight')
    if (padding_right !== undefined) width -= padding_right
    return width
  }
}

export function screen_width_md_or_more7(): boolean {
  return window.screen.width >= 768
}

// restore_scroll ----------------------------------------------------------------------------------
export function restore_scroll() {
  next_tick(() => {
    // Constantly trying to update scroll every 50ms while images and dynamic content are loaded.
    let set_scroll_timer: Timer | nil = setInterval(() => {
      const scroll_position = get_scroll_position()
      if (scroll_position && scroll_position != window.scrollY) window.scrollTo(0, scroll_position)
    }, 50)
    function stop_scroll_timer() { if (set_scroll_timer) { clearInterval(set_scroll_timer) } }
    setTimeout(stop_scroll_timer, 3000) // Stop trying to restore the scroll after a while

    // Updating scroll position with every scroll event
    let body_height = document.body.scrollHeight
    window.onscroll = () => {
      const scrolled_by_user = body_height == document.body.scrollHeight
      body_height = document.body.scrollHeight

      if (scrolled_by_user) {
        stop_scroll_timer()
        set_scroll_position()
      }
    }

    // Storing scroll position in local storage.
    function set_scroll_position() { sessionStorage.setItem('scroll_position', '' + window.scrollY) }
    function get_scroll_position(): number | nil {
      const s = sessionStorage.getItem('scroll_position'); if (!s) return nil
      return Number.parse(s)
    }
  })
}