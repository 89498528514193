import 'base'
import { def } from 'base'
import { ensure_loaded, get_element_width } from 'ext/browser'
import { head_to_text } from 'keep/blocks'
import * as fschema from 'htext/parser/schema'
import { BlockEl, HtmlContext, ResolveContext } from 'keep/doc'
import { error_to_html } from 'keep/elements'
import { Plot, Data, TidyData, VegaFullSpec, override_size_in_narrow_mode, to_tidy_data, to_vega } from 'plot/plot'
import { h,  Component, createRef } from 'preact'
import { block_from_htext as from_htext } from 'keep/doc/htext_parsers'
import { parse } from 'ext/parsers'
import { Progress } from 'keep/palette'

export class PlotBlockEl implements BlockEl {
  type = 'plot'

  constructor(
    readonly id: string | nil, readonly tags: string[],
    readonly plot: Plot, readonly data: Data, readonly vega_options?: object
  ) {}

  async resolve(ctx: ResolveContext) {}

  to_text() {
    return [
      ...head_to_text(this.tags),
      to_s(this.plot), to_s(this.data), ...(this.vega_options ? [to_s(this.vega_options)] : [])
    ]
  }

  to_html(ctx: HtmlContext) {
    return <PlotView plot={{ plot: this.plot, data: this.data, vega_options: this.vega_options }}/>
  }
}
from_htext('plot', async (id, tags, b: fschema.CustomBlock) => {
  const { plot, data, vega_options } = parse<{ plot: Plot, data: Data, vega_options?: object }>(b.content, 'yaml')
  return new PlotBlockEl(id, tags, plot, data, vega_options)
})

// // Vega integration --------------------------------------------------------------------------------

const figures_limit = 40

const figure_default_width_px = 400
const figure_min_width_px = 200
const figure_max_width_px = 1024
const figure_report_width_px = 800

const narrow_width = 480 // Should be same as in styles.styl
const height_in_narrow_width_mode = 200

export interface PlotFigure {
  plot:          Plot
  data:          Data
  vega_options?: Object
}

export interface VegaFigure {
  vega_spec:     Object
  vega_data?:    Data
  vega_options?: Object
}

type PlotWidgetState = { state: 'waiting' } | { state: 'loaded', suggested_width: number } | { state: 'error', error: string }
function initial_state(): PlotWidgetState { return { state: 'waiting' } }

export class PlotView extends Component<{ plot: PlotFigure | VegaFigure }, PlotWidgetState> {
  root_ref = createRef<HTMLDivElement>()
  state = initial_state()

  render() {
    const state = this.state
    const render_error = (error: string) => {
      return <div ref={this.root_ref} class="plot"
        dangerouslySetInnerHTML={{ __html: error_to_html(to_s(this.props.plot), [error]) }}
      />
    }
    switch (state.state) {
      case 'error':
        return render_error(state.error)
      case 'waiting':
        return <div ref={this.root_ref} class="plot"><Progress>Loading plot...</Progress></div>
      case 'loaded':
        try {
          const vega_props = to_vega_props(this.props.plot, false, nil)
          return <div ref={this.root_ref} class="plot">
            <VegaReactWrapper
              { ...vega_props }
              suggested_width={state.suggested_width}
              on_error={(error) => this.setState({ state: 'error', error })}
            />
          </div>
        } catch (e) {
          return render_error(error_message(e))
        }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.on_resize)
    const suggested_width = this.suggested_width(ensure(this.root_ref.current))

    // Loading vega
    ;(async () => {

      // Vega uses original Array.toString, temporarilly restoring it.
      def(Array.prototype, { toString: (Array.prototype as any).toStringOriginal })
      try {
        await ensure_loaded(
          '/vendor/vega/vega-5.9.1.js',
          '/vendor/vega/vega-lite-4.0.2.js',
          '/vendor/vega/vega-embed-6.2.1.js'
        )
        this.setState({ state: 'loaded', suggested_width })
      } catch (e) {
        this.setState({ state: 'error', error: error_message(e) })
      } finally {
        def(Array.prototype, { toString: Array.prototype.to_s })
      }
    })()
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.on_resize)
  }

  on_resize = (() => {
    const state = this.state, root_el = this.root_ref.current
    if (state.state == 'loaded' && root_el) {
      this.setState({ state: 'loaded', suggested_width: this.suggested_width(root_el) })
    }
  }).debounce(100)

  suggested_width(widget_element: HTMLElement) {
    const width = get_element_width(widget_element, false) || figure_default_width_px
    return (
      width > figure_max_width_px ? figure_max_width_px :
      width < figure_min_width_px ? figure_min_width_px :
      width
    )
  }
}

interface VegaReactWrapperProps {
  vega_spec:        Object,
  vega_data?:       TidyData,
  vega_options:     Object,
  suggested_width:  number
  on_error:        (e: string) => void
}

export class VegaReactWrapper extends Component<VegaReactWrapperProps> {
  protected root_ref = createRef<HTMLDivElement>()
  protected vega_view: any

  render() {
    return <div ref={this.root_ref} class="vega-plot"></div>
  }

  componentDidMount() { this.render_vega() }
  componentDidUpdate() { this.render_vega() }

  protected async render_vega() {
    let { suggested_width, vega_spec, vega_options, vega_data } = this.props

    const element = this.root_ref.current
    if (!element) throw new Error(`no div reference in VegaReactWrapper`)

    // Destroying current vega
    if (this.vega_view) {
      this.vega_view.finalize()
      this.vega_view = nil
    }

    // Rendering vega
    if (vega_data) vega_spec = { ...vega_spec, data: { values: vega_data }}
    // try {
      const result = await (window as any).vegaEmbed(
        element,
        { ...default_vega_spec({ suggested_width }), ...vega_spec },
        { ...default_vega_options(), ...vega_options }
      )

      this.vega_view = result.view
    // } catch(e) {
    //   this.props.on_error(get_error_message(e))
    // }
  }
}


function default_vega_spec({ suggested_width }: { suggested_width: number }) {
  return {
    // The format of url cannot be changed it expected to contain `schema/vega-lite/4.0.2.json` part.
    // $schema: `http://localhost:${port}/assets/vega/schema/vega-lite/v4.0.2.json`,
    autosize: {
      // type:     'fit-x',
      type:     'fit',
      contains: 'padding'
    },
    width: suggested_width,
    // height: 200,
  }
}

function default_vega_options() {
  return {
    // actions: false,
    // width:  300,
    // height: 200,
  }
}

function to_vega_props(
  props:            PlotFigure | VegaFigure,
  is_narrow:        boolean,
  suggested_width?: number
): { vega_spec: Object, vega_options: Object, vega_data?: TidyData } {
  function override_width(vega_spec: VegaFullSpec) {
    return suggested_width !== undefined && is_narrow ?
      override_size_in_narrow_mode(vega_spec, suggested_width, height_in_narrow_width_mode) :
      vega_spec
  }

  if ('plot' in props) {
    const { plot, data, vega_options } = props
    const vega_spec = override_width(to_vega(plot))
    const vega_data = to_tidy_data(data)
    // const vega_spec = { ...vega_spec_without_data, data: { values: table_data }}
    return { vega_spec, vega_options: vega_options || {}, vega_data }
  } else {
    let { vega_spec, vega_data, vega_options } = props
    vega_spec = override_width(vega_spec)
    if (vega_data) {
      // const table_data = to_table_data(vega_data)
      // vega_spec = { ...vega_spec, data: { values: table_data }}
      vega_data = to_tidy_data(vega_data)
    }
    return { vega_spec, vega_options: vega_options || {}, vega_data }
  }
}