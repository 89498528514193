import { value_to_s } from './support'
import { uniq_stable } from './misc'

export abstract class EnumerableImpl<T> implements Enumerable<T> {
  abstract [Symbol.iterator](): Iterator<T>

  empty7(): boolean {
    for (let _ of this) return false
    return true
  }

  filter(op: (v: T) => boolean): T[] {
    const r: T[] = []
    for (let v of this) if (op(v)) r.push(v)
    return r
  }

  map<R>(op: (v: T) => R): R[] {
    const r: R[] = []
    for (let v of this) r.push(op(v))
    return r
  }

  each(op: (v: T) => void): void {
    let i = 0
    for (let v of this) op(v)
  }

  all7(op: (v: T) => boolean): boolean {
    for (let v of this) if (!op(v)) return false
    return true
  }

  some7(op: (v: T) => boolean): boolean {
    let i = 0
    for (let v of this) if (op(v)) return true
    return false
  }

  has7(v: T): boolean
  has7(op: (v: T) => boolean): boolean
  has7(op: T | ((v: T) => boolean)): boolean {
    if (op instanceof Function) {
      for (let v of this) if (op(v)) return true
    } else {
      for (let v of this) if (equal7(v, op)) return true
    }
    return false
  }

  order(): T[]
  order(op: (v: T) => unknown): T[]
  order(op: (a: T, b: T) => number): T[]
  order(op?: ((a: T, b: T) => number) | ((v: T) => unknown)): T[] {
    // Usage:
    //   [1, 2].order()                          # => [1, 2]
    //   [1, 2].order((a, b) => a.compare(b))    # => [1, 2]
    //   [{ n: 1 }, { n: 2 }].order ({ n }) -> n # => [{ n: 1 }, { n: 2 }]
    let cmp: (a: T, b: T) => number
    if      (op == undefined) cmp = (a, b) => compare(a, b)
    else if (op.length == 1)  cmp = (a, b) => (op as any)(a).compare((op as any)(b))
    else if (op.length == 2)  cmp = op as any
    else                      raise(`invalid sorting arg: ${op}`)
    return ([...this] as any).sort(cmp)
  }

  inverse(): T[] { return ([...this] as any).reverse() }

  uniq(op?: (v: T) => unknown): T[] {
    return uniq_stable([...this], op)
  }

  join(sep = ''): string { return this.map(value_to_s).join(sep) }

  partition(op: (v: T) => boolean): [T[], T[]] {
    let i = 0, filtered: T[] = [], rejected: T[] = []
    for (let v of this) (op(v) ? filtered : rejected).push(v)
    return [filtered, rejected]
  }

  count(v: T): number {
    let i = 0
    for (let v of this) if (equal7(v, v)) i++
    return i
  }

  min_element<R>(op?: ((v: T) => R)): T {
    op ??= v => v as any
    let min_v = first(this), min = op(min_v)
    for (let v of this) if (compare(op(v), min) < 0) { min_v = v; min = op(v) }
    return min_v
  }

  max_element<R>(op?: ((v: T) => R)): T {
    op ??= v => v as any
    let max_v = first(this), min = op(max_v)
    for (let v of this) if (compare(op(v), min) > 0) { max_v = v; min = op(v) }
    return max_v
  }

  min_i<R>(op?: ((v: T) => R)): number {
    op ??= v => v as any
    let i = 0, min_i = i, min = op(first(this))
    for (let v of this) {
      const opv = op(v)
      if (compare(opv, min) < 0) { min_i = i; min = opv }
      i++
    }
    return min_i
  }

  max_i<R>(op?: ((v: T) => R)): number {
    op ??= v => v as any
    let i = 0, max_i = 0, max = op(first(this))
    for (let v of this) {
      const opv = op(v)
      if (compare(opv, max) > 0) { max_i = i; max = opv }
      i++
    }
    return max_i
  }

  min(this: number[]): number
  min<R>(op: ((v: T) => R)): R
  min<R>(op?: ((v: T) => R)) {
    op ??= v => v as any
    let min = op(first(this))
    for (let v of this) {
      const opv = op(v)
      if (compare(opv, min) < 0) { min = opv }
    }
    return min
  }

  max(this: number[]): number
  max<R>(op: ((v: T) => R)): R
  max<R>(op?: ((v: T) => R)) {
    op ??= v => v as any
    let max = op(first(this))
    for (let v of this) {
      const opv = op(v)
      if (compare(opv, max) > 0) { max = opv }
    }
    return max
  }

  group<K>(op: (v: T) => K): Hash<K, T[]> {
    const r = new Hash<K, T[]>(); let i = 0
    for (const v of this) r.get_or(op(v), []).push(v)
    return r
  }

  to_hash<K>(op: (v: T) => K): Hash<K, T> {
    const r = new Hash<K, T>()
    for (const v of this) {
      const k = op(v)
      if (r.has_key7(k)) raise(`duplicate key: ${k}`)
      r.set(k, v)
    }
    return r
  }

  to_set(): HSet<T> { return new HSet(...this) }

  to_object(op: (v: T) => string): { [key: string]: T } {
    const r: { [key: string]: T } = {}
    for (const v of this) {
      const k = op(v)
      if (k in r) raise(`duplicate key: ${k}`)
      r[k] = v
    }
    return r
  }

  filter_map(): NonNullable<T>[]
  filter_map<R>(op: (v: T) => R | nil): R[]
  filter_map<R>(op?: (v: T) => R | nil) {
    const r: R[] = []; let i = 0
    op ??= v => v as any
    for (const v of this) {
      const mapped = op(v)
      if (mapped !== nil) r.push(mapped)
    }
    return r
  }

  batch(n: number): T[][] {
    const result: T[][] = [], this_as_array = [...this]
    let i = 0
    while (true) {
      const group: T[] = []
      if (i < this_as_array.length) result.push(group)

      for (let j = 0; j < n; j++) {
        if ((i + j) < this_as_array.length) group.push(this_as_array[i + j])
        else return result
      }

      i+= n
    }
  }

  quantile(this: Enumerable<number>, q: number, sorted7 = false): number {
    // if (this.length == 0) throw new Error("can't calculate quantile on empty array")
    const sorted = sorted7 ? [...this] : [...this].order((a, b) => a - b)
    const pos = (sorted.length - 1) * q
    const base = Math.floor(pos)
    const rest = pos - base
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base])
    } else {
      return sorted[base]
    }
  }

  median(this: Enumerable<number>, sorted7 = false): number {
    return this.quantile(.5, sorted7)
  }

  mean(this: Enumerable<number>): number {
    let sum = 0, count = 0
    for (const v of this) { sum += v; count++ }
    return sum / count
  }

  sum(this: Enumerable<number>): number {
    let sum = 0
    for (const v of this) sum += v
    return sum
  }
}

function first<T>(iterable: Iterable<T>): T {
  for (let v of iterable) return v
  raise('at least one element required')
}