import { def } from './support'
import { compare_with_lt, cyrb53, uniq_stable } from './misc'

def(String.prototype, {
  hash_code(this: string): number { return cyrb53(this) },

  equal7(this: string, other: string) { return this == other },

  compare(this: string, other: string) { return compare_with_lt(this, other) },

  plus(this: string, other: string): string { return this + other },

  has7(this: string, v: string | RegExp): boolean {
    return (typeof v == 'string') ? this.includes(v) : v.test(this)
  },

  empty7(this: string): boolean { return this.length == 0 },

  indent(this: string, n: number): string {
    const indent = ' '.repeat(n)
    return indent + this.replace(/\n/g, `\n${indent}`)
  },

  downcase(this: string): string { return this.toLowerCase() },

  upcase(this: string): string { return this.toUpperCase() },

  ljust(this: string, n: number, c: string = ' '): string { return this.padEnd(n, c) },

  rjust(this: string, n: number, c: string = ' '): string { return this.padStart(n, c) },

  get(this: string, i: number, i2?: number): string {
    // Both indexes inclusive
    if (i2 == undefined) {
      if (i < 0) {
        const j = i + this.length
        if (j < 0) raise(`index out of bounds: ${i}`)
        return this[j]
      } else {
        if (i >= this.length) raise(`index out of bounds: ${i}`)
        return this[i]
      }
    } else {
      // i = (i   >= 0 ? i  : this.length + i ).max(0).min(this.length - 1)
      // i2 = (i2 >= 0 ? i2 : this.length + i2).max(i).min(this.length - 1)
      i =  i  >= 0 ? i  : this.length + i
      i2 = i2 >= 0 ? i2 : this.length + i2
      if (i < 0)            raise(`index out of bounds i: ${i}`)
      if (i > i2)           raise(`invalid indexes, i should be less or equal i2: ${i}, ${i2}`)
      if (i2 > this.length) raise(`index out of bounds i2: ${i2}`)
      return this.slice(i, i2 + 1)
    }
  },

  get_relaxed(this: string, i: number, i2: number): string {
    // Both indexes inclusive
    i =  i  >= 0 ? i  : this.length + i
    i2 = i2 >= 0 ? i2 : this.length + i2
    if (i < 0)            i = 0
    if (i2 > this.length) i2 = this.length - 1
    if (i > i2)           i = i2
    return this.length == 0 ? '' : this.slice(i, i2 + 1)
  },

  uniq(this: string): string {
    return uniq_stable(this.divide('')).join('')
  },

  divide(this: string, sep: string | RegExp, limit?: number): string[] {
    if (this.length == 0) return []
    return (this as any).split(sep, limit)
  },

  dedent(this: string): string {
    const text = this.replace(/^\s*\n|[\n\s]+$/g, "") // Replacing the first and last empty line
    const match = text.match(/^(\s+)/)
    if (!match) return text
    const indent = match[0]
    return ((text as any).split("\n")
      .map((s: string) => s.startsWith(indent) ? s.replace(indent, '') : s).join("\n")).trim()
  },

  size(this: string): number { return this.length },

  start_with7(this: string, pattern: string | RegExp, start_i = 0): boolean { // Index inclusive
    const i = start_i >= 0 ? start_i : this.length + start_i
    if (i < 0)           raise(`index out of bounds start_i: ${i}`)
    if (i > this.length) raise(`index out of bounds start_i: ${i}`)

    if (typeof pattern == 'string') {
      return this.startsWith(pattern, i)
    } else {
      if (!pattern.global) raise(
        'For correct usage with start_with7, the pattern should be global, ' +
        `otherwise it cant use the lastIndex properly: /${pattern}/`
      )
      assert.equal(pattern.lastIndex, 0) // To detect improper usage
      pattern.lastIndex = i
      const match = pattern.exec(this)
      pattern.lastIndex = 0
      return !!(match && match.index == i)
    }
  },

  end_with7(this: string, pattern: string | RegExp, end_i = -1): boolean { // Index inclusive
    const i = end_i >= 0 ? end_i : this.length + end_i
    if (i < 0)           raise(`index out of bounds start_i: ${i}`)
    if (i > this.length) raise(`index out of bounds start_i: ${i}`)

    if (typeof pattern == 'string') {
      return this.endsWith(pattern, i + 1)
    } else {
      raise('Not implemented yet')
    }
  }
})