import { def } from './support'
import { compare_with_lt } from './misc'

def(Boolean.prototype, {
  hash_code(this: boolean): number { return this ? 1 : 0 },

  equal7(this: boolean, other: boolean) { return this === other },

  compare(this: boolean, other: boolean) { return compare_with_lt(this, other) },
})

def(Boolean, {
  parse(s: string): boolean | undefined {
    s = s.trim().toLowerCase()
    if (s == 'true') return true
    if (s == 'false') return false
    if (s == 'yes' || s == 'y' || s == 'on' || s == '1' || s == 't') return true
    if (s == 'no' || s == 'n' || s == 'off' || s == '0' || s == 'f') return false
    return undefined
  }
})