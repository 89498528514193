import 'base'
import { Parser } from 'ext/parser'
import { TagToken, uname_chars, uname_start_chars } from './schema'

export function line_n(s: string, pos_n: number): number {
  let result = 1
  for (let i = 0; i <= s.length; i++) {
    if (i > pos_n) break
    if (s[i] == "\n") result++
  }
  return result
}

export function after_non_char_or_number(pr: Parser) {
  return pr.i == 0 || !new RegExp(`[${uname_chars}\d]`, 'ui').test(pr.get(-1))
}

// Tags --------------------------------------------------------------------------------------------
export function tag_start7(pr: Parser): boolean {
  return pr.is7('#') && pr.is7(new RegExp(`[${uname_start_chars}]`, 'ui'), 1)
    && after_non_char_or_number(pr)
}

export function consume_tag(pr: Parser, shift: number): TagToken {
  assert(tag_start7(pr))
  const start = pr.i
  pr.inc()
  pr.consume(new RegExp(`[${uname_chars}]`, 'ui'))
  return { t: 'tag', pos: [shift + start, shift + pr.i - 1] }
}

export function try_consume_tags(pr: Parser, shift: number, separator: RegExp): TagToken[] {
  const tags: TagToken[] = []
  let tags_end = pr.i // To avoid consuming empty space after tags
  while (tag_start7(pr)) {
    tags.add(consume_tag(pr, shift))
    tags_end = pr.i
    pr.skip(separator)
  }
  pr.i = tags_end
  return tags
}