import 'base'
import './elements'
import './blocks'
import { VNode } from 'preact'
import { EntryHead } from 'ext/store'

export type HTextItem = El & { em?: boolean }
export type HText = HTextItem[]

export interface El {
  type:       string
  to_html(ctx: HtmlContext):  string
  to_text():  string[]
  resolve?(ctx: ResolveContext): Promise<void>
}

export interface BlockEl {
  id?:        string
  type:       string
  to_html(ctx: HtmlContext):  string | VNode | VNode[]
  to_text():  string[]
  resolve?(ctx: ResolveContext): Promise<void>
  show_tags7?: boolean
  tags:        string[]
  loc?:        unknown
}

export interface ResolveContext {
  errors: DocError[]
  resolve_path(path: string): Promise<EntryHead | nil>
  read_dir(path: string): Promise<EntryHead[]>
  ensure_assets: string[] // Required JS or CSS to be loaded
}

export interface HtmlContext {
  link_path(path: string): string
  tag_path(tag: string): string
  asset_path(path: string): string
}

export interface DocError { error: string, context?: string }

export class Section {
  constructor(
    readonly id: string | nil, readonly title: string | nil, readonly tags: string[],
    readonly chapters: Chapter[], readonly loc?: unknown
  ) {}
}

export class Chapter {
  constructor(
    readonly id: string | nil, readonly title: string | nil, readonly tags: string[], readonly blocks: BlockEl[], readonly loc?: unknown
  ) {}
}

export class Doc {
  readonly sections: Section[] = []
  readonly errors: DocError[]  = []
  constructor(
    readonly id: string | nil, readonly title: string | nil, readonly tags: string[], readonly loc?: unknown
  ) {}

  async resolve(ctx: ResolveContext): Promise<void> {
    const promises: (Promise<unknown> | nil)[] = []
    this.sections.each(section => section.chapters.each(chapter => chapter.blocks.each(block =>
      promises.add(block.resolve?.(ctx)
    ))))
    await Promise.all(promises)
  }
}