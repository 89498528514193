import { nothing } from './types'
import { RJSON } from './vendor/relaxed-json-1.0.3'
import { build_flog, deep_normalize } from './misc'
import { assert as assert_impl } from './assert'

// runtime -----------------------------------------------------------------------------------------
declare let global: any
declare let require: any
if (typeof window === 'undefined') global.window = global // Otherwise `window` is not defined in bun.js

function get_runtime(): 'deno' | 'node' | 'bun' | 'browser' {
  const w = window as any
  if (w.Deno)    return 'deno'
  if (w.Bun)     return 'bun'
  if (w.process) return 'node'
  return 'browser'
}
export const runtime = get_runtime()

// def ---------------------------------------------------------------------------------------------
interface DefPropsOptions { overload: boolean }
function def<T>(o: T, k: string, v: unknown, options?: DefPropsOptions): void
function def<T>(o: T, props: { [k: string]: unknown }, options?: DefPropsOptions): void
function def<T>(o: T, klass: Function, options?: DefPropsOptions): void
function def<T>(o: T, arg2: string | { [k: string]: unknown } | Function, arg3?: unknown, arg4?: DefPropsOptions): void {
  if (typeof arg2 == 'string') {
    const k = arg2, v = arg3, options = arg4
    const overload = options?.overload !== false
    if (!overload && o!.hasOwnProperty(k)) return
    Object.defineProperty(o, k, { value: v, enumerable: false, writable: true, configurable: true })
  } else {
    const props = typeof arg2 == 'function' ? arg2.prototype : arg2, options = arg3 as DefPropsOptions
    const overload = options?.overload !== false
    for (let k of Object.getOwnPropertyNames(props)) {
      if (k == 'constructor') continue
      if (!overload && o!.hasOwnProperty(k)) continue
      Object.defineProperty(o, k, { value: props[k], enumerable: false, writable: true, configurable: true })
    }
  }
}
export { def }

// to_s, from_s ------------------------------------------------------------------------------------
;[Boolean, Function, Number, RegExp, String].forEach(klass => {
  def(klass.prototype, {
    to_s() { return (this as any).toString() }
  })
})

def(Object.prototype, {
  to_s() {
    return (this.constructor == Object ? '' : (this.constructor?.name || '')) + '{' + object_to_s(this) + '}'
  }
})

;(window as any).to_s = function(v: unknown): string { switch (v) {
  case undefined: return 'undefined'
  case null:      return 'null'
  default:        return (v as any).to_s()
} }

;(window as any).from_s = function(s: string) {
  return RJSON.parse(s, { relaxed: true, tolerant: true })
}

// inspect -----------------------------------------------------------------------------------------
export function to_s_inspect(v: unknown): string { switch (v) {
  case undefined: return 'undefined'
  case null:      return 'null'
  // default:        return (v as any).to_s()
  default:        switch (typeof v) {
    case 'string':   return to_quoted_s(v)
    case 'function': return `->${(v as any).name || 'fn'}`
    default:         return (v as any).to_s()
  }
} }
export function to_quoted_s(v: string): string {
  v = v.replace(/\n/g, '\\n').replace(/\t/g, '\\t')
  // return v.includes("'") ? `"${v.replace(/"/g, '\"')}"` : `'${v}'`
  return `"${v.replace(/"/g, '\"').replace(/\n/g, '\\n').replace(/\t/g, '\\t')}"`
}
export function to_quoted_sym(v: string): string {
  return v.match(/^[a-zA-Z0-9_]*$/) ? v : to_quoted_s(v)
}
// export function value_to_s(v: unknown): string { switch (typeof v) {
//   case 'string':   return to_quoted_s(v)
//   case 'function': return `->${(v as any).name || 'fn'}`
//   default:         return to_s_inspect(v)
// } }
export const value_to_s = to_s_inspect
export function key_to_s(k: string): string {
  return k.match(/^[a-zA-Z0-9_]+$/) ? k : to_quoted_s(k)
}
export function object_to_s(o: Object): string {
  return Object.entries(deep_normalize(o))
    .map(([k, v]) => key_to_s(k) + ': ' + value_to_s(v)).join(', ')
}

// type checks -------------------------------------------------------------------------------------
window.string7   = function(v: unknown): v is string { return typeof v == 'string' }
window.number7   = function(v: unknown): v is number { return typeof v == 'number' }
window.boolean7  = function(v: unknown): v is boolean { return typeof v == 'boolean' }
window.array7    = function(v: unknown): v is unknown[] { return Array.isArray(v) }
window.object7   = function(v: unknown): v is object { return typeof v == 'object' }
window.record7   = function(v: unknown): v is Record<string, unknown> {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}
window.nil7      = function(v: unknown): v is undefined { return v === undefined }
window.function7 = function(v: unknown): v is Function { return typeof v == 'function' }

// p, inspect --------------------------------------------------------------------------------------
window.p       = function(...args) { console.log(...args.map(value_to_s)) }
window.inspect = function(v) {
  v = deep_normalize(v)
  switch (runtime) {
    case 'deno':
      return (window as any).Deno.inspect(v, { depth: 10, showHidden: false })
    case 'node': case 'bun':
      return console.log(
        require('util').inspect(v, { depth: 10, showHidden: false, color: true, sorted: true })
      )
    default:
      return console.dir(v)
  }
}

// nil, nil7, equal7, compare, raise ---------------------------------------------------------------------
window.equal7 = function equal7<T>(a: T, b: T) {
  if (a == null || b == null) return a === b
  return (a as any).equal7(b)
} as any

window.compare = function compare<T>(a: T, b: T): number {
  if (a == undefined && b == undefined) return 0
  if (a == undefined)                   return -1
  if (b == undefined)                   return 1
  return a.compare(b)
} as any

export function remove_last_lines(e: Error, n: number): Error {
  const stack: string[] = (e.stack || '').divide('\n').slice(n) // n + 1
  const match = /at(.*)\((.*):([0-9]+):([0-9]+)\)/.exec(stack[0])
  if (match) {
    const [_m, _0, source, line, column] = match
    e.stack = stack.join('\n')
    ;(e as any).line = parseInt(line, 10)
    ;(e as any).column = parseInt(column, 10)
    ;(e as any).sourceURL = source
  }
  return e
}

window.raise = function raise(message: string): never {
  throw remove_last_lines(new Error(message), 1)
}

window.error_message = function error_message(e: unknown, msg = 'Unknown error'): string {
  if (e === nil || e === null) return msg
  if (typeof e == 'string')    return e
  if (e instanceof Error)      return e.message
  return `${msg}: ${to_s_inspect(e)}`
}

window.nil7 = function nil7(v: unknown): boolean { return v == null } as any
window.nil  = undefined

window.deep_clone = function<T>(o: T): T {
  if        (Array.isArray(o)) {
    return o.map(deep_clone) as T
  } else if (typeof o === 'object' && o !== null) {
    const r: any = {}
    for (let k in o) r[k] = deep_clone(o[k])
    return r
  } else {
    return o
  }
}

window.shallow_clone = function<T>(o: T): T {
  if      (Array.isArray(o))                    return [...o] as T
  else if (typeof o === 'object' && o !== null) return {...o} as T
  else                                          return o
}

window.ensure = function<T>(v: T, msg = `can't be undefined`): NonNullable<T> {
  if (v === undefined || v === null) raise(msg)
  return v
}

// env ---------------------------------------------------------------------------------------------
function get_env(): Record<string, string> {
  let ENV: Record<string, string> = (window as any).ENV || {}

  function divide(s: string, sep: string): string[] {
    if (s == '') return []
    return (s as any).split(sep)
  }
  switch (runtime) {
    case 'deno':
      const Deno = (window as any).Deno;
      for (let arg of Deno.args) {
        let [k, v] = divide(arg, '=')
        ENV[k] = v || 'true'
      }
      break
    case 'node':
    case 'bun':
      for (const k in global.process.env) ENV[k] = '' + global.process.env[k]
      const node_args = global.process?.argv
      for (let i = 2; i < node_args.length; i++) {
        let [k, v] = divide(node_args[i], '=')
        ENV[k] = v || 'true'
      }
      break
    default:
      ENV = ((window as any).ENV || {})
  }
  for (let k in ENV) if (!(typeof ENV[k] == 'string')) console.warn(`ENV.${k} is not a string`)
  return ENV
}
window.ENV = get_env()

// JSON --------------------------------------------------------------------------------------------
JSON.encode = function(v: unknown, pretty = false): string {
  return pretty ?
    (JSON as any).stringify(deep_normalize(v), null, 2) :
    (JSON as any).stringify(deep_normalize(v))
}
JSON.decode = function(s: string): any { return JSON.parse(s) }

// console.flog ------------------------------------------------------------------------------------
def(console, { flog: build_flog(runtime) })

// test --------------------------------------------------------------------------------------------
export function test(name: string, test: () => void): void {
  if (ENV.test == 'true' || ENV.test == name) {
    console.flog(['  test | ' + name, 'color: grey;'])
    test()
  }
}

export function build_test(module_name: string) {
  return (name: string, t: () => void): void => test(`${module_name} ${name}`, t)
}

window.assert = assert_impl

// Result ------------------------------------------------------------------------------------------
window.to_success = function<T, E>(r: T): Result<T, E> { return { error7: false, result: r } }
window.to_error   = function<T, E>(e: E): Result<T, E> { return { error7: true, error: e } }

// Polyfils ----------------------------------------------------------------------------------------
if (!('Promise.withResolvers' in Promise)) (Promise as any).withResolvers = function withResolvers(this: PromiseConstructor) {
  let resolve, reject
  const promise = new this(function (res, rej) { resolve = res; reject = rej })
  return { resolve, reject, promise }
}

// async -------------------------------------------------------------------------------------------
window.next_tick = runtime == 'browser' ? (f: () => void) => setTimeout(f, 0) : global.process.nextTick

window.sleep = function sleep(ms: number): Promise<void> { return new Promise((resolve) => setTimeout(resolve, ms)) }