import { def } from './support'
import { compare_with_lt } from './misc'

def(Number.prototype, {
  hash_code(this: number): number { return this },
  equal7(this: number, other: number) { return this === other },
  compare(this: number, other: number) { return compare_with_lt(this, other) },

  floor(this: number) { return Math.floor(this) },
  ceil(this: number) { return Math.ceil(this) },

  min(this: number, v: number) { return Math.min(this, v) },
  max(this: number, v: number) { return Math.max(this, v) },

  pow(this: number, v: number) { return Math.pow(this, v) },

  div(this: number, v: number) { return Math.floor(this / v) },
  rem(this: number, v: number) { return this % v },
  div_rem(this: number, v: number): [number, number] { return [Math.floor(this / v), this % v] },

  pluralize(this: number, singular: string, plural?: string): string {
    return this == 1 ? singular : (plural || singular + 's')
  },

  round(this: number, digits: number = 0): number {
    return digits == 0 ?
      Math.round(this) :
      Math.round((this + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits)
  }
})

def(Number, {
  parse(s: string): number | undefined {
    s = s.trim()
    if (s.includes('.')) s = s.replace(/\.?0+$/, '')
    const n = s.includes('.') ? parseFloat(s) : parseInt(s, 10)
    return Number.isFinite(n) && to_s(n) == s ? n : undefined
  }
})