import 'base'
import { BlockParser, CodeBlock, CustomBlock, DocError, HtmlBlock, Hypertext, ImageBlock, ImagesBlock, ListBlock, ParserContext, TableBlock, TableOptions, TextItemBlock } from './schema'
import * as lexer from 'htext/lexer/schema'
import { Pos } from 'htext/lexer/schema'
import { Props } from 'htext/lexer/schema'
import { ParagraphsBlock } from 'htext/parser/schema'
import { parse_lexed_text, parse_lexed_text_item } from './parse_text'
import { Parser } from 'ext/parser'
import { lex_text } from 'htext/lexer/lex_text'
import { lex_code_block, lex_embed_block, lex_html_block, lex_image_block, lex_list_block, lex_paragraphs_block } from 'htext/lexer/lex_block'

// Context -----------------------------------------------------------------------------------------
export function get_block_parsers(): Record<string, BlockParser> { return {
  paragraphs: parse_paragraphs_block,
  list:       parse_list_block,
  code:       parse_code_block,
  html:       parse_html_block,
  embed:      parse_embed_block,
  image:      parse_image_block,
  images:     parse_images_block,
  table:      parse_table_block
} }

// Custom ------------------------------------------------------------------------------------------
/** When there's no specific prser */
export const parse_custom_block: BlockParser<CustomBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return { content: ctx.source.get(...content).trim() }
}

// Paragraphs --------------------------------------------------------------------------------------
const parse_paragraphs_block: BlockParser<ParagraphsBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_paragraphs_block(content, ctx), content, ctx, ({ paragraphs }) => ({
    paragraphs: parse_lexed_text(paragraphs, ctx)
  }))
}

// List --------------------------------------------------------------------------------------------
const parse_list_block: BlockParser<ListBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_list_block(content, ctx), content, ctx, ({ list }) => ({
    list: parse_lexed_text(list, ctx)
  }))
}

// Code --------------------------------------------------------------------------------------------
const parse_code_block: BlockParser<CodeBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_code_block(content, ctx), content, ctx, ({ lang, code, code_type }) => ({
    lang: lang ? ctx.source.get(...lang) : nil,
    code: code ? code.code : '',
    code_type
  }))
}

// Embed -------------------------------------------------------------------------------------------
const parse_embed_block: BlockParser<TextItemBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_embed_block(content, ctx), content, ctx, ({ embed }) => ({
    item: parse_lexed_text_item(embed, ctx)
  }))
}

// Image -------------------------------------------------------------------------------------------
const parse_image_block: BlockParser<ImageBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_image_block(content, ctx), content, ctx, ({ path_pos, title_pos }) => ({
    path:  ctx.source.get(...path_pos),
    title: title_pos ? ctx.source.get(...title_pos) : nil
  }))
}

// Html --------------------------------------------------------------------------------------------
const parse_html_block: BlockParser<HtmlBlock> = function (content, _props, ctx) {
  if (!content) return nil
  return with_result(lex_html_block(content, ctx), content, ctx, ({ html }) => ({
    html: parse_lexed_text(html, ctx)
  }))
}

// Images ------------------------------------------------------------------------------------------
const parse_images_block: BlockParser<ImagesBlock> = function (content, props, ctx) {
  if (!content) return nil
  const path = ctx.source.get(...content)
  const options: ImagesBlock['options'] = {
    cols: get_number(props, 'cols', ctx.errors, content)
  }
  return { path, options }
}

// Table -------------------------------------------------------------------------------------------
const parse_table_block: BlockParser<TableBlock> = function (content, props, ctx) {
  if (!content) return nil
  const text = ctx.escaped.get(...content), pr = new Parser(text)

  const col_delimiter = text.has7('|') ? '|' : ','
  const col_delimiter7 = () => pr.is7(col_delimiter)

  // Default delimiter is newline, but if there's double newline happens anywhere in table text, then
  // the double newline used as delimiter.
  const row_delimiter7 = text.has7("\n\n") ?
    () => pr.get() == '\n' && pr.get(1) == '\n' :
    () => pr.get() == '\n'

  // Parsing table options
  let options: TableOptions = {}
  const style = get_string(props, 'style', ctx.errors, content)
  if (style == 'cards') {
    options = {
      style:            'cards',
      cols:             get_number(props, 'cols', ctx.errors, content),
      img_aspect_ratio: get_number(props, 'img_aspect_ratio', ctx.errors, content)
    }
  } else if (style == 'table' || style == nil) {
  } else {
    ctx.errors.add({ error: `Invalid table style: ${style}`, pos: content })
  }
  options.header = get_boolean(props, 'header', ctx.errors, content)

  // Parsing table content
  const shift = content[0]
  const rows: Hypertext[][] = []
  let row: Hypertext[] = []
  while (pr.has7()) {
    if (row_delimiter7()) {
      rows.add(row)
      row = []
      pr.skip('\n')
    } else if (col_delimiter7()) {
      pr.skip(col_delimiter)

      if (row_delimiter7()) { // Special case when last cell is empty
        row.add([])
        rows.add(row)
        row = []
        pr.skip('\n')
      }
    }

    pr.skip_spaces()

    const start = pr.i
    const cell_s = pr.consume(() => !(row_delimiter7() || col_delimiter7())).trim()

    if (cell_s.empty7()) {
      row.add([])
    } else {
      const cell_pos: lexer.Pos = [shift + start, shift + start + cell_s.length - 1]
      const lexed = lex_text(cell_pos, ctx.escaped)
      const text = parse_lexed_text(lexed, ctx)
      row.add(text)
    }
  }
  if (!row.empty7()) rows.add(row)

  return { rows, options }
}

// Helpers -----------------------------------------------------------------------------------------
function get_number(props: Props, key: string, errors: DocError[], pos: lexer.Pos): number | nil {
  if (key in props) {
    if (number7(props[key])) return props[key] as number
    errors.add({ error: `The props is not a number: ${key}`, pos })
  }
  return nil
}
function get_boolean(props: Props, key: string, errors: DocError[], pos: lexer.Pos): boolean | nil {
  if (key in props) {
    if (boolean7(props[key])) return props[key] as boolean
    errors.add({ error: `The props is not a boolean: ${key}`, pos })
  }
  return nil
}
function get_string(props: Props, key: string, errors: DocError[], pos: lexer.Pos): string | nil {
  if (key in props) {
    if (string7(props[key])) return props[key] as string
    errors.add({ error: `The props is not a string: ${key}`, pos })
  }
  return nil
}
function with_result<T, R>(r: Result<T, string[]>, content: Pos, ctx: ParserContext, op: ((v: T) => R | nil)): R | nil {
  if (r.error7) {
    ctx.errors.add(...r.error.map(e => ({ error: e, pos: content })))
    return
  }
  return op(r.result)
}