import 'base'
import { Parser } from 'ext/parser'
import { TagsBlock, Pos, uname_start_chars, BlockToken, DocHeaderBlock, SectionHeaderBlock, ChapterHeaderBlock, ContentBlock, BlockHeaderBlock, name_chars } from './schema'
import { try_consume_tags } from './helpers'
import { split_text_block } from './split_text_block'
import { parse_header } from './lex_header'

// Separating bloks --------------------------------------------------------------------------------
export type HeaderBlock = DocHeaderBlock | SectionHeaderBlock | ChapterHeaderBlock | BlockHeaderBlock

/** Splitting doc into blocks, like header, list, code, paragraphs, tags etc.
 *
 * It's not depends on parsing the content of blocks, it treats block as a raw text, except of headers. */
export function separate_blocks(escaped: string, source: string): BlockToken[] {
  const pr = new Parser(escaped)
  let blocks: BlockToken[] = []

  let block_type: string | nil = nil
  while (pr.has7()) { // Rest of blocks
    const start = pr.i
    pr.skip("\n")

    const found: BlockToken | nil =
      try_consume_header(pr, source) ||
      try_consume_tags_block(pr) ||
      try_consume_content_block(pr, block_type)
    block_type = nil

    if (found) {
      blocks.add(found)
      if (found.t == 'block_header' && found.has_content_block) block_type = found.type?.[1]
    } else if (pr.i == start) { // No progress
      const rest = pr.rest().trim()
      if (!rest.empty7()) {
        const errors = [`Invalid block: ${rest}`]
        blocks.add({ t: 'error', pos: [pr.i, pr.i + rest.length - 1], errors })
      }
      break
    }
  }

  // Text block made of sub blocks like paragraphs, code, etc., splitting text block into
  // separate blocks.
  blocks = blocks.map(b =>
    b.t == 'block' && b.type == 'text' ? split_text_block(b, escaped) : b
  ).flat()

  return blocks
}

function explicit_block_start7(pr: Parser): boolean {
  return header_start7(pr) || tags_block_start7(pr)
}

/** Consumes any text until any explicit block starts */
function try_consume_content_block(pr: Parser, type?: string): ContentBlock | nil {
  if (explicit_block_start7(pr)) return
  const start = pr.i
  const text = (pr.consume() + pr.consume(() => !explicit_block_start7(pr))).trimEnd()
  if (text.empty7()) return
  return { t: 'block', pos: [start, start + text.length - 1], type: (type || 'text') }
}

/** Examples `# Title ...`, `## Section title ...`, `### Doc Title ...`, `--`, `-- list ...` */
function header_start7(pr: Parser): boolean {
  if (!(pr.line_start7() && pr.is7(/[#\-=]/))) return false // Line start with #, -, =

  function space_or_props_or_line_end(shift: number) {
    return (pr.is7(/\s/, shift) || pr.line_end7(shift)) || pr.is7('{', shift)
  }

  // function space_or_props_or_block_name_or_line_end(shift: number) {
  //   return space_or_props_or_line_end(shift) || pr.is7(new RegExp(`[${name_chars}]`, 'i'), shift)
  // }

  return (
    (pr.start_with7('#')   && space_or_props_or_line_end(1)) || // #  Chapter
    (pr.start_with7('##')  && space_or_props_or_line_end(2)) || // ## Section
    (pr.start_with7('###') && space_or_props_or_line_end(3)) || // ## Doc
    (pr.start_with7('--')  && space_or_props_or_line_end(2)) || // -- block
    (pr.start_with7('==')  && space_or_props_or_line_end(2))    // == block

    // (pr.start_with7('--')  && space_or_props_or_block_name_or_line_end(2)) || // --block
    // (pr.start_with7('==')  && space_or_props_or_block_name_or_line_end(2))    // ==block
  )
}
function try_consume_header(pr: Parser, source: string): HeaderBlock | nil {
  if (!header_start7(pr)) return

  // const end7 = pr.start_with7('--') ?
  //   (() => !pr.is7("\n")) : // Block head can't span multiple lines
  //   (() => !pr.start_with7(/\n\s*\n/g))

  const start = pr.i; let embed = false
  while (pr.has7()) {
    if (embed) {
      if (pr.is7('}')) embed = false
    } else {
      if (pr.is7('{')) embed = true
      if (pr.is7("\n")) break
    }
    pr.inc()
  }
  const pos: Pos = [start, pr.i - 1]
  pr.skip("\n")
  return parse_header(pos, pr.text, source)
}

/** Example `#a #b` */
function tags_block_start7(pr: Parser): boolean {
  if (!pr.is7('#'))                                           return false // Start with #
  if (!(pr.i == 0 || (pr.is7("\n", -1) && pr.is7("\n", -2)))) return false // After empty line
  if (!pr.is7(new RegExp(`[${uname_start_chars}]`, 'ui'), 1)) return false // Any letter

  // Lookahead, checking that block contains tags only
  const start = pr.i
  const tags_block_text = pr.consume(() => !pr.start_with7("\n\n")).trim()
  pr.i = start
  const pr2 = new Parser(tags_block_text)
  try_consume_tags(pr2, 0, /\s/)
  return pr2.i == tags_block_text.length
}
function try_consume_tags_block(pr: Parser): TagsBlock | nil {
  if (!tags_block_start7(pr)) return
  const start = pr.i
  const tags_block_text = pr.consume(() => !pr.start_with7("\n\n")).trimEnd()
  const pr2 = new Parser(tags_block_text)
  const tags = try_consume_tags(pr2, start, /\s/)
  assert(tags.length > 0)
  return { t: 'tags',  pos: [start, pr.i - 1], tags }
}