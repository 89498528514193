import { def } from './support'
import './string'

def(Error.prototype, {
  equal7<T extends Error>(this: T, other: T): boolean {
    return this.message == other.message && this.stack == other.stack
  },

  compare<T extends Error>(this: T, other: T): number {
    return this.message.compare(other.message)
  },

  to_s(): string {
    return (this as unknown as Error).message || 'Unknown error'
  },
})