import { Parser } from "ext/parser"
import { Pos, name_chars, name_start_chars, uname_chars, TextToken, EmToken, ImageToken, TagToken, LinkToken, EmbedToken, Hypertext, CodeToken, ErrorToken, HtmlToken } from "./schema"
import { after_non_char_or_number, consume_tag, tag_start7 } from "./helpers"

export function lex_text(pos: Pos, escaped: string): Hypertext {
  const pr = new Parser(escaped.get(...pos)), tokens: Hypertext = [], shift = pos[0]
  let prev_pos: Pos | nil
  while (pr.has7()) {
    let found =
      try_consume_embed(pr, pos[0]) ||
      try_consume_html(pr, pos[0]) ||
      try_consume_link(pr, pos[0]) ||
      try_consume_tag(pr, pos[0]) ||
      try_consume_image(pr, pos[0]) ||
      try_consume_em(pr, pos[0]) ||
      try_consume_code(pr, pos[0]) ||
      try_consume_text(pr, pos[0]) // should be last

    if (found) {
      // Checking there's no skipping of text
      assert.equal(found.pos[0], prev_pos ? prev_pos[1] + 1 : shift)
      assert.equal(found.pos[1], shift + pr.i - 1)
      prev_pos = found.pos

      tokens.add(found)
    } else {
      const rest = pr.rest().trim()
      if (!rest.empty7()) {
        const errors = [`Invalid text: ${rest}`]
        tokens.add({ t: 'error', pos: [shift + pr.i, shift + pr.i + rest.length - 1], errors })
      }
      break
    }
  }
  return tokens
}

// Text --------------------------------------------------------------------------------------------
function token_start7(pr: Parser): boolean {
  return embed_start7(pr) || html_start7(pr) || embed_start7(pr) || link_start7(pr) ||
    tag_start7(pr) || image_start7(pr) || em_start7(pr) || code_start7(pr)
}
function try_consume_text(pr: Parser, shift: number): TextToken | nil {
  const start = shift + pr.i
  const text = pr.consume(() => !token_start7(pr))
  if (text.empty7()) return
  return { t: 'text', pos: [start, start + text.length - 1] }
}

// Em ----------------------------------------------------------------------------------------------
function em_start7(pr: Parser): boolean { return pr.start_with7('**') }
/** Example `a **b**` */
function try_consume_em(pr: Parser, shift: number): EmToken | nil {
  if (!em_start7(pr)) return
  const start = shift + pr.i
  pr.inc(2)
  return { t: 'em', pos: [start, start + 1] }
}

// Link --------------------------------------------------------------------------------------------
function link_start7(pr: Parser): boolean {
  return after_non_char_or_number(pr) && (
    pr.start_with7(/\[[^\]\n]*\]\(([^\)\n]+)\)(\n?|$)/g) ||                                // `[title](/some/path)`
    pr.start_with7(new RegExp(`\\[[${uname_chars}/\\-_]+\\]([^${uname_chars}]|$)`, 'ugi')) // `[/some/path]`
  )
}
/** Example `a [/some/path] b` or `a [title](/some/path) b ` */
function try_consume_link(pr: Parser, shift: number): LinkToken | ErrorToken | nil {
  if (!link_start7(pr)) return
  const a_start = shift + pr.i
  pr.consume()
  const a = pr.consume(() => !pr.is7(']'))
  // const a_pos: Pos = [a_start, shift + pr.i - 1 ]
  pr.consume()
  const a_end = shift + pr.i - 1

  if (!pr.is7('(')) {
    return { t: 'link', pos: [a_start, a_end], link_pos: [a_start + 1, a_end - 1] }
  } else {
    const b_start = shift + pr.i
    pr.consume()
    pr.consume(() => !pr.is7(')'))
    pr.consume()
    const b_end = shift + pr.i - 1
    return {
      t: 'link', pos: [a_start, b_end], link_pos: [b_start + 1, b_end - 1],
      title_pos: a.empty7() ? nil : [a_start + 1, a_end - 1]
    }
  }
}

// Image -------------------------------------------------------------------------------------------
function image_start7(pr: Parser): boolean {
  return after_non_char_or_number(pr) && pr.start_with7(/!\[[^\]\n]*\]\(([^\)\n]+)\)(\n?|$)/g)
}
/** Example `a ![title](/image.png) b ` */
function try_consume_image(pr: Parser, shift: number): ImageToken | ErrorToken | nil {
  if (!image_start7(pr)) return
  const start = shift + pr.i
  pr.consume(2)
  const title_start = shift + pr.i
  const title = pr.consume(() => !pr.is7(']')).trimEnd()
  const title_pos: Pos | nil = title.empty7() ? nil : [title_start, title_start + title.length - 1]
  pr.consume(2)
  const image_start = shift + pr.i
  const image = pr.consume(() => !pr.is7(')')).trimEnd()
  const image_pos: Pos | nil = image.empty7() ? nil: [image_start, shift + pr.i - 1]
  pr.consume()
  const pos: Pos = [start, shift + pr.i - 1]
  return image_pos ?
    { t: 'image', pos, title_pos, path_pos: image_pos } :
    { t: 'error', pos, errors: ['Invalid link'] }
}

// Tag --------------------------------------------------------------------------------------------
/** Example `a #tag b` */
function try_consume_tag(pr: Parser, shift: number): TagToken | nil {
  if (!tag_start7(pr)) return
  // const start = shift + pr.i
  // return { t: 'tag', pos: [start, shift + pr.i - 1], tag_pos: [start + 1, shift + pr.i - 1] }
  return consume_tag(pr, shift)
}

// Embed -------------------------------------------------------------------------------------------
function embed_start7(pr: Parser): boolean {
  return pr.start_with7(new RegExp(`([${name_start_chars}][${name_chars}]*)?\\{`, 'ugi'))
}
/** Example `a {2^2} b img{a.png} c` */
function try_consume_embed(pr: Parser, shift: number): EmbedToken | ErrorToken | nil {
  if (!embed_start7(pr)) return
  const start = shift + pr.i
  const type = pr.start_with7('{') ? nil : pr.consume(() => !pr.is7('{'))
  const embed_type_pos: Pos | nil = nil7(type) ? nil : [start, start + type.length - 1]
  pr.consume()
  const body_start = shift + pr.i
  const body = pr.consume(() => !pr.is7('}'))
  const embed_pos: Pos | nil = nil7(body) ? nil : [body_start, body_start + body.length - 1]
  pr.consume()
  const pos: Pos = [start, shift + pr.i - 1]
  return { t: 'embed', pos, embed_type_pos, embed_pos }
}

// Html --------------------------------------------------------------------------------------------
function html_start7(pr: Parser): boolean {
  return pr.start_with7(new RegExp(`</?[${name_start_chars}][${name_chars}]*[\s>\n]`, 'gi'))
}
/** Example `<div>`, `</div>`, `<a href="link">` */
function try_consume_html(pr: Parser, shift: number): HtmlToken | nil {
  if (!html_start7(pr)) return
  const start = shift + pr.i
  pr.consume(() => !pr.is7('>'))
  pr.consume()
  return { t: 'html', pos: [start, shift + pr.i - 1] }
}

// Code --------------------------------------------------------------------------------------------
function code_start7(pr: Parser): boolean { return pr.is7('`') }
/** Example `a {2^2} b img{a.png} c` */
function try_consume_code(pr: Parser, shift: number): CodeToken | nil {
  if (!code_start7(pr)) return
  const start = shift + pr.i
  pr.consume()
  pr.consume(() => !pr.is7('`'))
  pr.consume()
  const pos: Pos = [start, shift + pr.i - 1]
  const code_pos: Pos | nil = pos[1] - pos[0] > 1 ? [pos[0] + 1, pos[1] - 1] : nil
  return { t: 'code', pos, code_pos }
}