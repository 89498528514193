import './support'

Promise.map_parallel = async function<T, R>(list: T[], op: (v: T) => Promise<R>): Promise<R[]> {
  const r: R[] = []
  const promises = list.map(op)
  for (const p of promises) r.push(await p)
  return r
}

Promise.map_sequential = async function<T, R>(list: T[], op: (v: T) => Promise<R>): Promise<R[]> {
  const r: R[] = []
  for (const v of list) r.push(await op(v))
  return r
}

Promise.map = Promise.map_parallel