import { def } from './support';
import './array'
import './number'
import './string'
import { EnumerableImpl } from './enumerable'

class HSet<T> {
  _buckets = new Map<number, T[]>()
  _size    = 0

  constructor(...items: T[]) {
    for (let v of items) this.add(v)
  }

  hash_code(): number { return [...this].order().hash_code() }

  to_s(): string { return this.constructor.name + '(' + [...this].order().join(', ') + ')' }

  compare<T>(this: T[], other: T[]): number { return this.order().compare(other.order()) }

  equal7(other: HSet<T>): boolean {
    if (this._size != other._size) return false
    return [...this].equal7([...other])
  }

  add(v: T): void {
    const hash = v?.hash_code() ?? 0
    let values = this._buckets.get(hash)
    if (!values) this._buckets.set(hash, values = [])
    if (values.has7(v)) return
    values.push(v)
    this._size++
  }

  *[Symbol.iterator](): IterableIterator<T> {
    for (let values of this._buckets.values()) for (let v of values) yield v
  }

  del(v: T): void {
    const hash = v?.hash_code() ?? 0
    const values = this._buckets.get(hash)
    if (!values) return
    const i = values.find_i(v)
    if (i == undefined) return
    if (values.length == 1) this._buckets.delete(hash)
    else                    values[i] = values.pop()!
    this._size--
  }

  has7(v: T): boolean
  has7(op: (v: T, i: number) => boolean): boolean
  has7(op: T | ((v: T, i: number) => boolean)): boolean {
    let i = 0
    if (op instanceof Function) {
      for (let v of this) if (op(v, i++)) return true
    } else {
      const v = op; const hash = v?.hash_code() ?? 0
      return !! this._buckets.get(hash)?.has7(v)
    }
    return false
  }

  size(): number { return this._size }

  clear(): void { this._buckets.clear(); this._size = 0 }
}

def(HSet.prototype, EnumerableImpl)
;(window as any).HSet = HSet