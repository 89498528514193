import 'base'
import * as fschema from 'htext/parser/schema'
import { BlockEl, El } from 'keep/doc'
import { FromFtextContext } from './htext'

// Elements ----------------------------------------------------------------------------------------
type ElFromFtext<T> = (t: T, ctx: FromFtextContext) => Promise<El>
export const element_parsers: Record<string, ElFromFtext<fschema.HypertextItem>> = {}

export function el_from_htext<T extends fschema.HypertextItem>(t: string, parse: ElFromFtext<T>) {
  element_parsers[t] = parse as any
}

// Block elements ----------------------------------------------------------------------------------
type BlockElFromFtext<T> = (id: string | nil, tags: string[], t: T, ctx: FromFtextContext) => Promise<BlockEl | nil>
export const block_parsers: Record<string, BlockElFromFtext<fschema.ABlock>> = {}

export function block_from_htext<T extends fschema.ABlock>(t: string, parse: BlockElFromFtext<T>) {
  block_parsers[t] = parse as any
}