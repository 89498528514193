import { key_to_s, value_to_s } from './support'
import './number'
import './string'
import './array'

class Hash<K, V> {
  _buckets = new Map<number, [K, V][]>()
  _size    = 0

  constructor() {}

  get(k: K): V {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, v] of pairs) if (equal7(vk, k)) return v
    raise(`key not found: ${k}`)
  }

  get_or(k: K, dflt: V | ((k: K) => V)): V {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, v] of pairs) if (equal7(vk, k)) return v
    const d = dflt instanceof Function ? dflt(k) : dflt
    this.set(k, d)
    return d
  }

  set(k: K, v: V) {
    const hash = k?.hash_code() ?? 0
    let pairs = this._buckets.get(hash)
    if (!pairs) this._buckets.set(hash, pairs = [])
    for (const [vk, _v] of pairs) if (equal7(vk, k)) return
    pairs.push([k, v])
    this._size++
  }

  del(...keys: K[]): void {
    for (const k of keys) {
      const hash = k?.hash_code() ?? 0
      const pairs = this._buckets.get(hash)
      if (pairs) {
        for (let i = 0; i < pairs.length; i++) {
          if (equal7(pairs[i][0], k)) {
            pairs.splice(i, 1)
            this._size--
            return
          }
        }
      }
    }
  }

  has_key7(k: K): boolean {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, _v] of pairs) if (equal7(vk, k)) return true
    return false
  }

  equal7(other: Hash<K, V>): boolean {
    if (this._size != other._size) return false
    for (const [k, v] of this) if (!equal7(v, other.get(k))) return false
    return true
  }

  keys(): K[] {
    const keys: K[] = []; for (const [k, _] of this) keys.push(k); return keys
  }

  values(): V[] {
    const values: V[] = []; for (const [_, v] of this) values.push(v); return values
  }

  entries(): [K, V][] { return [...this] }

  hash_code(): number {
    return [...this].order((pair) => pair[0]).hash_code()
  }

  compare(other: this): number {
    return this.entries().order((pair) => pair[0]).compare(other.entries().order((pair) => pair[0]))
  }

  size(): number { return this._size }

  empty7(): boolean { return this._size == 0 }

  to_object(): Record<string, V> {
    const obj: Record<string, V> = {}
    for (const [k, v] of this) {
      if (typeof k != 'string') raise(`key must be a string: ${to_s(k)}`)
      obj['' + k] = v
    }
    return obj
  }

  to_s(): string {
    const entries = this.entries().map(([k, v]) => key_to_s(to_s(k)) + ': ' + value_to_s(v))
    return this.constructor.name + '{' + entries.join(', ') + '}'
  }

  *[Symbol.iterator](): Iterator<[K, V]> {
    for (const pairs of this._buckets.values()) for (const pair of pairs) yield pair
  }

  filter(op: (v: V, k: K) => boolean): Hash<K, V> {
    const h = new Hash<K, V>()
    for (const [k, v] of this) if (op(v, k)) h.set(k, v)
    return h
  }

  map<R>(op: (v: V, k: K) => R): Hash<K, R> {
    const h = new Hash<K, R>()
    for (const [k, v] of this) h.set(k, op(v, k))
    return h
  }

  each(op: (v: V, k: K) => void): void {
    for (const [k, v] of this) op(v, k)
  }
}
function ensure_impl_match_with_global_type() { const tmp: globalThis.Hash<unknown, unknown> = new Hash() }
;(window as any).Hash = Hash

function H<V>(items: Record<string, V>): Hash<string, V>
function H<K, V>(...items: [K, V][]): Hash<K, V>
function H<K, V>(...items: (Record<string, V> | [K, V])[]): Hash<any, any> {
  const h = new Hash()
  if (items.length > 0) {
    if (items.length == 1 && !(items[0] instanceof Array)) {
      const record = items[0] as Record<string, V>
      for (const k in record) h.set(k, record[k])
    } else {
      for (const [k, v] of items as [K, V][]) h.set(k, v)
    }
  }
  return h
}
(window as any).H = H