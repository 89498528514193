import 'base'
import * as lexer from '../lexer/schema'
import { Pos } from '../lexer/schema'
import { CodeItem, DocError, EmbedItem, ErrorItem, HtmlItem, Hypertext, HypertextItem, ImageItem, LinkItem, ParserContext, TagItem, TextItem } from './schema'
import { lex_list, lex_paragraphs } from 'htext/lexer/lex_block'

export function parse_lexed_text_item(item: lexer.HypertextToken, ctx: ParserContext): HypertextItem {
  const text = parse_lexed_text([item], ctx)
  assert.equal(text.length, 1)
  return text[0]
}

export function parse_lexed_text(tokens: lexer.Hypertext, ctx: ParserContext): Hypertext
export function parse_lexed_text(tokens: lexer.Hypertext[], ctx: ParserContext): Hypertext[]
export function parse_lexed_text(tokens: lexer.Hypertext | lexer.Hypertext[], ctx: ParserContext) {
  if      (tokens.empty7())   {
    return []
  } else if (array7(tokens[0])) {
    return (tokens as lexer.Hypertext[]).map(t => parse_lexed_text(t, ctx))
  } else {
    return parse_lexed_text_impl(tokens as lexer.Hypertext, ctx)
  }
}

function parse_lexed_text_impl(tokens: lexer.Hypertext, { source, errors }: ParserContext): Hypertext {
  const text: Hypertext = []; let em: { em?: boolean } = {}
  function add<T extends HypertextItem>(t: T) { text.push({ ...t, ...em }) }

  for (const t of tokens) {
    const pos = t.pos
    if (t.t == 'code') {
      add<CodeItem>({ t: 'code', pos, code: t.code_pos ? source.get(...t.code_pos) : '' })
    } else if (t.t == 'embed') {
      const type = t.embed_type_pos ? source.get(...t.embed_type_pos) : 'eval'
      const text = t.embed_pos ? source.get(...t.embed_pos) : ''

      const built_in_types = [
        'embed', 'text', 'link', 'image', 'code', 'html',
        'paragraphs', 'list'
      ]
      if (type && built_in_types.has7(type)) {
        if (type == 'image') {
          add<ImageItem>({ t: 'image', pos, title: nil, path: text })
        } else {
          add<ErrorItem>({ t: 'error', pos, text, errors: [`Invalid type for embed: ${type}`] })
        }
      } else {
        add<EmbedItem>({ t: type, pos, text })
      }
    } else if (t.t == 'em') {
      em = em.em ? {} : { em: true }
    } else if (t.t == 'error') {
      errors.push(...t.errors.map(error => ({ error, pos })))
      add<ErrorItem>({ t: 'error', pos, text: source.get(...pos), errors: t.errors })
    } else if (t.t == 'image') {
      const title = t.title_pos ? source.get(...t.title_pos) : nil
      const image = source.get(...t.path_pos)
      add<ImageItem>({ t: 'image', pos, title, path: image })
    } else if (t.t == 'link') {
      const link = source.get(...t.link_pos); const title = t.title_pos ? source.get(...t.title_pos) : nil
      add<LinkItem>({ t: 'link', pos, link, title })
    } else if (t.t == 'tag') {
      add<TagItem>({ t: 'tag', pos, tag: source.get(pos[0] + 1, pos[1]) })
    } else if (t.t == 'html') {
      add<HtmlItem>({ t: 'html', pos, text: source.get(...pos) })
    } else if (t.t == 'text') {
      add<TextItem>({ t: 'text', pos, text: source.get(...pos) })
    } else {
      raise(`Unknown token: ${to_s(t)}`)
    }
  }

  return text
}

export function parse_list(text: string, ctx: ParserContext): Hypertext[] {
  const tokens = lex_list([0, text.length - 1], text)
  return parse_lexed_text(tokens, ctx)
}

export function parse_paragraphs(text: string, ctx: ParserContext): Hypertext[] {
  const tokens = lex_paragraphs([0, text.length - 1], text)
  return parse_lexed_text(tokens, ctx)
}