import { def } from './support'
import { compare_with_lt } from './misc'

def(RegExp.prototype, {
  hash_code(this: RegExp): number { return this.source.hash_code() },

  equal7(this: RegExp, other: RegExp): boolean { return this == other },

  compare(this: RegExp, other: RegExp): number {
    return compare_with_lt(this.source, other.source)
  },

  parse(this: RegExp, s: string): string[] {
    const found = this.exec(s)
    // const found = s.match(this)
    if (!found) return []
    if (found.length == 1) return [] // matched but there's no capture groups
    return found.slice(1, found.length)
      .map(v => nil7(v) ? '' : v) // It may contain nil for optional match groups
  },

  parse1(this: RegExp, s: string): string {
    const found = this.parse(s)
    if (found.length != 1) raise(`expected 1 match but found ${found.length}`)
    return found[0]
  },

  parse2(this: RegExp, s: string): [string, string] {
    const found = this.parse(s)
    if (found.length != 2) raise(`expected 2 matches but found ${found.length}`)
    return found as [string, string]
  },

  parse3(this: RegExp, s: string): [string, string, string] {
    const found = this.parse(s)
    if (found.length != 3) raise(`expected 3 matches but found ${found.length}`)
    return found as [string, string, string]
  },

  parse_named(this: RegExp, s: string): Record<string, string> {
    const found = this.exec(s)
    // return { ...(found?.groups || {}) } // Wrapping needed because it's not an object, broken in bun v 1.1.16
    return Object.assign({}, found?.groups || {})
  }
})
