import { def } from './support'
import { object_hash_code, compare_iterables } from './misc'

def(Object.prototype, {
  of7<C extends Function>(this: Object, klass: C): boolean {
    if (typeof this == 'object') {
      return this instanceof klass
    } else {
      switch (klass as any) {
        case String:  return typeof this == 'string'
        case Number:  return typeof this == 'number'
        case Boolean: return typeof this == 'boolean'
        case BigInt:  return typeof this == 'bigint'
        case Symbol:  return typeof this == 'symbol'
        case Array:   return Array.isArray(this)
        default:      raise(`unknown primitive ${klass}`)
      }
    }
  },

  equal7<T extends Object>(this: T, other: T): boolean {
    let props_count = 0
    for (const k in this) {
      if (!Object.hasOwn(this, k) || this[k] === undefined) continue
      props_count++
      if (!equal7(this[k], other[k])) return false
    }

    for (const k in other) {
      if (!Object.hasOwn(other, k) || other[k] === undefined) continue
      props_count--
    }

    return props_count == 0
  },

  hash_code(): number { return object_hash_code(this as any) },

  in7<K>(this: K, collection: { has7(k: K): boolean }): boolean { return collection.has7(this) },

  compare<T>(this: T, other: T): number {
    return compare_iterables(
      Object.entries(this! ).order((pair) => pair[0]),
      Object.entries(other!).order((pair) => pair[0])
    )
  },

  lt7<T>(this: T, b: T): boolean { return this!.compare(b) < 0 },
  lte7<T>(this: T, b: T): boolean { return this!.compare(b) <= 0 },
  gt7<T>(this: T, b: T): boolean { return this!.compare(b) > 0 },
  gte7<T>(this: T, b: T): boolean { return this!.compare(b) >= 0 },
})

def(Object, {
  all_keys(obj: object): string[] {
    const keys = new Set<string>()
    do {
      for (const k of Object.getOwnPropertyNames(obj)) if (!keys.has(k)) keys.add(k)
      obj = Object.getPrototypeOf(obj)
    } while (obj)
    return [...keys]
  },

  from<T>(entries: [string, T][]): { [key: string]: T } {
    const o: { [key: string]: T } = {}
    for (const [k, v] of entries) o[k] = v
    return o
  }
})

window.size = function(o: Record<string, unknown> | { size(): number }): number {
  if (Object.hasOwn(o, 'size')) return (o as any).size()
  let i = 0
  for (let k in o) {
    if (!Object.hasOwn(o, k)) continue
    i++
  }
  return i
}

window.empty7 = function(o: Record<string, unknown> | { size(): number }): boolean { return size(o) == 0 }

window.has7 = function<K extends string, V>(
  o: Record<K, V> | { has7(k: K): boolean }, k: K | string
): boolean {
  if (Object.hasOwn(o, 'has7')) return (o as any).has7(k)
  return Object.hasOwn(o, k as string)
}

window.keys = function(o) { return Object.getOwnPropertyNames(o) as any }
window.values = function(o) { return Object.values(o) }
window.entries = function(o) { return Object.entries(o) as any }

window.subset_of7 = function subset_of7<T extends object>(subset: Partial<T>, superset: T): boolean {
  for (let k of Object.getOwnPropertyNames(subset)) {
    if (!equal7((subset as any)[k], (superset as any)[k])) return false
  }
  return true
}