import 'base'
import { Parser } from 'ext/parser'
import { ContentBlock, ErrorToken, Pos, name_chars, name_start_chars } from './schema'

// Split text block --------------------------------------------------------------------------------
/** Text block has multiple sub blocks like paragraphs, list, code.
 *  Splitting text block into smaller blocks */
export function split_text_block(block: ContentBlock, escaped: string): (ContentBlock | ErrorToken)[] {
  assert.equal(block.type, 'text')
  // Skipping empty block
  if (escaped.get(...block.pos).trim().empty7()) return []

  const pr = new Parser(escaped.get(...block.pos)), shift = block.pos[0]
  let blocks: (ContentBlock | ErrorToken)[] = []

  while (pr.has7()) {
    pr.skip("\n")
    const start = pr.i
    let found =
      try_consume_list(pr) ||
      try_consume_quoted_code(pr) ||
      try_consume_indented_code(pr) ||
      try_consume_embed_block(pr) ||
      try_consume_html_block(pr) ||
      try_consume_image_block(pr) ||
      try_consume_text(pr)
    const progressed = pr.i != start

    if (found) {
      const [type, text] = found
      assert(text.length > 0)
      const pos_start = shift + start
      const pos: Pos = [pos_start, pos_start + text.length - 1]
      // Implicit blocks don't have header, so `pos` is same as `embed_pos`.
      blocks.push({ t: 'block', type, pos })
    } else {
      if (!progressed) {
        // Checking for invalid content
        const rest = pr.rest()
        if (!rest.empty7()) blocks.add({
          t: 'error', pos: [shift + pr.i, shift + pr.i + rest.length - 1], errors: ['Unexpected text in text block']
        })
        break
      }
    }
  }
  assert(!blocks.empty7())

  return blocks
}


function list_start7(pr: Parser): boolean {
  return pr.line_start7() && pr.start_with7("- ")
}
function list_end7(pr: Parser): boolean {
  return pr.start_with7(/\n\n/g)
}
function try_consume_list(pr: Parser): [string, string] | nil {
  if (!list_start7(pr)) return
  const text = pr.consume(() => !list_end7(pr))
  return ['list', text.trimEnd()]
}

function quoted_code_start7(pr: Parser): boolean {
  return pr.line_start7() && pr.start_with7("```")
}
function quoted_code_end7(pr: Parser): boolean {
  return pr.start_with7(/\n```/g)
}
function try_consume_quoted_code(pr: Parser): [string, string] | nil {
  if (!quoted_code_start7(pr)) return
  const text = pr.consume(3) + pr.consume(() => !quoted_code_end7(pr)) + pr.consume(4)
  return ['code', text.trimEnd()]
}

function indented_code_start7(pr: Parser): boolean {
  return pr.line_start7() && pr.start_with7("  ")
}
function indented_code_end7(pr: Parser): boolean {
  return pr.is7("\n") && !((pr.is7(' ', 1) && pr.is7(' ', 2)) || pr.is7("\n", 1))
}
function try_consume_indented_code(pr: Parser): [string, string] | nil {
  if (!indented_code_start7(pr)) return
  const text = pr.consume(3) + pr.consume(() => !indented_code_end7(pr))
  return ['code', text.trimEnd()]
}

function embed_block_start7(pr: Parser): boolean {
  return pr.line_start7() &&
    pr.start_with7(new RegExp(`([${name_start_chars}][${name_chars}]*)?\\{`, 'gi'))
}
function embed_block_end7(pr: Parser): boolean { return pr.start_with7('}', -1) }
function try_consume_embed_block(pr: Parser): [string, string] | nil {
  if (!embed_block_start7(pr)) return
  const text = pr.consume(() => !embed_block_end7(pr))
  return ['embed', text]
}

function html_block_start7(pr: Parser): boolean {
  return pr.line_start7() &&
    pr.start_with7(new RegExp(`<[${name_start_chars}][${name_chars}]*[\s>\n]`, 'gi'))
}
function try_consume_html_block(pr: Parser): [string, string] | nil {
  if (!html_block_start7(pr)) return
  let level = 0; const buff: string[] = []
  while (pr.has7()) {
    if      (pr.start_with7('</')) {
      level--
      buff.add(pr.consume(() => !pr.is7('>')))
      buff.add(pr.consume())
    } else if ( pr.start_with7('/>')) {
      level--
      buff.add(pr.consume(2))
    } else if (pr.start_with7('<')) {
      level++
    }
    if (level == 0) break
    buff.add(pr.consume())
  }
  return ['html', buff.join('')]
}

function image_block_start7(pr: Parser): boolean {
  return pr.line_start7() && pr.start_with7(/!\[[^\]\n]*\]\(([^\)\n]+)\)(\n?|$)/g)
}
function try_consume_image_block(pr: Parser): [string, string] | nil {
  if (!image_block_start7(pr)) return
  const text =
    pr.consume(2) +
    pr.consume(() => !pr.is7(']')) + // title
    pr.consume(2) +
    pr.consume(() => !pr.is7(')')) + // link
    pr.consume()
  return ['image', text]
}

function block_start7(pr: Parser): boolean {
  return quoted_code_start7(pr) || indented_code_start7(pr) || list_start7(pr) ||
    embed_block_start7(pr) || image_block_start7(pr) || html_block_start7(pr)
}
function try_consume_text(pr: Parser): [string, string] | nil {
  pr.skip("\n")
  let text = pr.consume(() => !block_start7(pr)).trimEnd()
  return text.empty7() ? nil : ['paragraphs', text]
}