import { runtime } from 'base'

export type Entry = { type: 'dir' | 'file', name: string }

export interface FS {
  read(path: string): Promise<string>
  /** Does nothing if entry doesn't exist. */
  del(path: string): Promise<void>
  write(path: string, data: string): Promise<void>
  exist(path: string): Promise<boolean>
  read_dir(path: string): Promise<{ name: string, type: 'file' | 'dir' }[]>
  info(path: string): Promise<'file' | 'dir' | nil>
  /** `owerwrite` is true by default */
  copy(from: string, to: string, overwrite?: boolean): Promise<void>
}

export const fs = (runtime == 'node' || runtime == 'bun') ? build_for_node_fs() : build_for_browser()

// Node, Bun ---------------------------------------------------------------------------------------
function build_for_node_fs(): FS {
  const path_fs = require('path')
  const node_fs = require('node:fs/promises')

  return {
    async del(path: string): Promise<void> {
      switch(await this.info(path)) {
        case 'file': await node_fs.unlink(path); break
        case 'dir':  await node_fs.rmdir(path, { recursive: true }); break
      }
    },

    async copy(from: string, to: string, overwrite = true): Promise<void> {
      if (!(await this.exist(from))) raise(`Source don't exist: ${from}`)

      if (await this.exist(to)) {
        if (!overwrite) raise(`Destination already exists: ${to}`)
        await this.del(to)
      }

      switch (await this.info(from)) {
        case 'file': {
          try {
            await node_fs.copyFile(from, to)
          } catch (e) {
            const to_parent = get_parent_dir(to)
            if (to_parent && !(await this.exist(to_parent))) await node_fs.mkdir(to_parent, { recursive: true })
            else                                             throw e
            await node_fs.copyFile(from, to)
          }
          break
        }
        case 'dir': {
          for (const { name } of await this.read_dir(from)) await this.copy(`${from}/${name}`, `${to}/${name}`, overwrite)
          break
        }
      }
    },

    read(path: string): Promise<string> {
      return node_fs.readFile(path, 'utf-8')
    },

    async write(path: string, data: string): Promise<void> {
      try {
        await node_fs.writeFile(path, Buffer.from(data, 'utf-8'))
      } catch (e) {
        // Checking if parent dirs exists, creating it and retrying
        const dirname = path_fs.dirname(path)
        if (!(await node_fs.exists(dirname))) {
          await node_fs.mkdir(dirname, { recursive: true })
          await node_fs.writeFile(path, Buffer.from(data, 'utf-8'))
        } else throw e
      }
    },

    async exist(path: string): Promise<boolean> {
      // try { return node_fs.statSync(path).isFile() }
      try   { return await node_fs.exists(path) }
      catch { return false }
    },

    async read_dir(path: string): Promise<{ name: string, type: 'file' | 'dir' }[]> {
      const r = []
      const found = await node_fs.readdir(path)
      for (const name of found) {
        const type = await this.info(`${path}/${name}`)
        if (nil7(type)) raise('internal error')
        r.push({ name: name, type })
      }
      return r
    },

    async info(path: string): Promise<'file' | 'dir' | nil> {
      let stats
      try{ stats = await node_fs.lstat(path) }
      catch (e) { return }
      if (stats.isSymbolicLink()) {
        const resolvedPath = await node_fs.realpath(path)
        const resolvedStats = await node_fs.stat(resolvedPath)
        return resolvedStats.isFile() ? 'file' : 'dir'
      } else {
        return stats.isFile() ? 'file' : 'dir'
      }
    }
  }
}

// Browser -----------------------------------------------------------------------------------------
function build_for_browser(): FS {
  return {
    async del(path: string): Promise<void> { raise(`not implemented`) },

    async copy(from: string, to: string, overwrite = true): Promise<void> { raise(`not implemented`) },

    read(path: string): Promise<string> { raise(`not implemented`) },

    async write(path: string, data: string): Promise<void> { raise(`not implemented`) },

    async exist(path: string): Promise<boolean> { raise(`not implemented`) },

    async read_dir(path: string): Promise<{ name: string, type: 'file' | 'dir' }[]> {
      raise(`not implemented`)
    },

    async info(path: string): Promise<'file' | 'dir' | nil> {
      raise(`not implemented`)
    }
  }
}

// Helpers -----------------------------------------------------------------------------------------
export function get_base_name(path: string): string {
  return (path as any).split('/').slice(-1)[0].replace(/\..*/, '')
}

export function get_name(path: string): string {
  return (path as any).split('/').slice(-1)[0]
}

export function get_base_path(path: string): string {
  return path.replace(/\.[^\.]*$/, '')
}

export function get_parent_dir(path: string): string | nil {
  if (path === '/') return nil
  if (path.startsWith('/') && !path.includes('/', 1)) return '/'
  const parent = (path as any).split('/').slice(0, -1).join('/')
  return parent || '/'
}

export function get_extension(path: string): string | nil {
  return path.has7('.') && !path.end_with7('.') ? path.divide('.').slice(-1)[0] : nil
}

export function join_paths(...parts: string[]): string {
  return parts.join('/').replace(/\/+/g, '/')
}

// Test --------------------------------------------------------------------------------------------
if (import.meta.main) {
  p(await fs.read_dir('.'))
}