import 'base'
import { AHeaderBlock, EmbedToken, Pos, Props, TagToken, name_chars, name_start_chars } from './schema'
import { HeaderBlock } from './separate_blocks'
import { tag_start7, try_consume_tags } from './helpers'
import { Parser } from 'ext/parser'

/** Examples
 * - `# Title #tag`
 * - `# {id: 1} Title #tag`
 * - `-- images{some/path} #tag` */
export function parse_header(pos: Pos, escaped: string, source: string): HeaderBlock {
  const text = escaped.get(...pos)
  const pr = new Parser(text), shift = pos[0] //, r: AHeaderBlock = { pos }

  let errors: string[] | nil
  function add_error(e: string) { if (!errors) errors = []; errors.add(e) }

  // Type `# Chapter`, `## Section`, `### Doc`
  let type: HeaderBlock['t'], has_content_block = true
  if      (pr.start_with7('###'))   type = 'doc_header'
  else if (pr.start_with7('##'))    type = 'section_header'
  else if (pr.start_with7('#'))     type = 'chapter_header'
  else if (pr.start_with7('--'))    type = 'block_header'
  else if (pr.start_with7('=='))  { type = 'block_header'; has_content_block = false }
  else                              raise('invalid')

  if (type == 'block_header') {
    pr.skip(has_content_block ? '-' : '=')
    pr.skip(/\s/)
    const block = parse_block_name()
    const props = parse_props()
    if (props && 'content' in props[1]) has_content_block = false
    pr.skip(/\s/)
    const tags  = parse_tags()
    check_nothing_left()
    return { t: type, pos, type: block, props, tags, errors, has_content_block }
  } else {
    pr.skip('#')
    pr.skip(/\s/)
    const props = parse_props()
    pr.skip(/\s/)
    const title = parse_title()
    pr.skip(/\s/)
    const tags  = parse_tags()
    check_nothing_left()
    return { t: type, pos, title, props, tags, errors }
  }

  function parse_tags(): TagToken[] | nil {
    const tags = try_consume_tags(pr, shift, /\s/)
    return tags.empty7() ? nil : tags
  }

  function parse_title(): Pos | nil {
    const start = pr.i
    const title = pr.consume(() => !tag_start7(pr)).trimEnd()
    return title.empty7() ? nil : [shift + start, shift + start + title.length - 1]
  }

  function parse_block_name(): [Pos, string] | nil {
    const start = pr.i
    const name = pr.consume(new RegExp(`[${name_chars}]`, 'i')).trimEnd()
    return name.empty7() ? nil : [[shift + start, shift + start + name.length - 1], name]
  }

  function parse_props(): [Pos, Props] | nil {
    if (!pr.is7('{')) return nil
    const start = pr.i
    pr.inc()
    pr.skip(/[^\}]/)
    pr.inc()
    const pos: Pos = [shift + start, shift + pr.i - 1]

    // Parsing
    try {
      return [pos, parse_header_props(source.get(...pos))]
    } catch (e) {
      add_error(`Invalid props: ${error_message(e)}`)
      return [pos, {}]
    }
  }

  function check_nothing_left() {
    pr.skip(/\s/)
    if (!pr.rest().empty7()) add_error(`Unexpected text: ${pr.rest()}`)
  }
}

/** Examples `{block: list, id: 1}`, `{some/path}` - will be parsed as `{content: 'some/path'}` */
export function parse_header_props(s: string): Props {
  s = s.trim().replace(/^\{/, '').replace(/\}$/, '')
  if (s.empty7()) return {}

  // Simple expressions like `some/path` should be parsed as `{content: 'some/path'}`.
  // Complex expressions like `a: 1, b: 2` should be wrapped into brackets as `{a: 1, b: 2}`
  // const is_simple_expression = new RegExp(`^[${name_start_chars}][${name_chars}]*$`, 'i').test(s)
  // if (!is_simple_expression && !s.start_with7('{')) s = `{${s}}`
  if (s.has7(':')) s = `{${s}}`

  let v = from_s(s)
  if (string7(v) || number7(v)) {
    const v_s = '' + v
    v = empty7(v_s) ? nil : { content: v_s }
  }
  if (!record7(v)) raise(`Invalid props in block header: ${s}`)

  // if ('block' in v && !string7(v.block)) raise(`Invalid block type: ${v.block}`)
  if ('id' in v) {
    if (!(string7(v.id) || number7(v.id))) raise(`Invalid id: ${v.id}`)
    v.id = v.id.to_s()
  }
  return v
}