import 'base'

// h, Fragment, JSX --------------------------------------------------------------------------------
export function h(tag: string | TagFn, attrs: Record<string, any> | null | nil, ...children: (ElContent | ElContent[])[]): El {
  return new El(tag, attrs || undefined, ...children)
}

export function Fragment(attrs: object, children: ElContent[]): El {
  return new El('fragment', attrs, children)
}

export namespace h {
  export namespace JSX {
    export interface IntrinsicElements {
      [tag: string]: any
    }
  }
}

// El ----------------------------------------------------------------------------------------------
export interface CommonElAttrs {
  tag?:   string
  id?:    string
  class?: string
  c?:     boolean
}
export type TagFn = (attrs: object, children: ElContent[], context: object) => El | El[] | undefined
export type ElContent = El | string | undefined
export type Els = ElContent[]

interface ElNode { tag: string, attrs?: Record<string, any>, children?: MixedElNode[] }
type MixedElNode = ElNode | string | number | boolean

export class El {
  readonly tag:      string | TagFn
  readonly attrs?:   object
  readonly children: ElContent[]

  constructor(tag: string | TagFn, attrs?: object, ...children: (ElContent | ElContent[])[]) {
    if (!tag) raise(`tag is required: ${this.attrs}`)
    this.tag = tag; this.attrs = attrs; this.children = children.flat()
  }

  to_html(context: object = {}): string {
    const html: string[] = []; to_html_impl(html, this.to_tree(context), ''); return html.join('')
  }

  to_tree(context: object = {}): ElNode | MixedElNode[] {
    const tree: ElNode[] = []; to_tree(tree, context, this)
    if (this.tag == Fragment) return tree
    else {
      if (tree.length > 1) raise(`tag must produce single node: ${this.tag}`)
      return tree[0]
    }
  }
}

export function to_html(els: ElContent | ElContent[], context: object = {}): string {
  const tree: ElNode[] = []; to_tree(tree, context, els)
  const html: string[] = []; to_html_impl(html, tree, ''); return html.join('')
}

export function escape_js(js: string | number | boolean): string {
  return JSON.encode(to_s(js)).replace(/^\"|\"$/g, '')
}

export function escape_id(id: string | number | boolean): string {
  return to_s(id).replace(/[^a-zA-Z0-9-]/g, '-')
}

export function compress_js(s: string): string { return s.replace(/\n+/g, '; ') }
export function compress_css(s: string): string { return s.replace(/[\n\s]+/g, ' ') }

export function escape_html(html: string | number | boolean | undefined, quotes = true): string {
  const HTML_ESCAPE_RE                = /[&<>'"]/g
  const HTML_ESCAPE_WITHOUT_QUOTES_RE = /[&<>]/g
  return to_s(html)
    .replace((quotes ? HTML_ESCAPE_RE : HTML_ESCAPE_WITHOUT_QUOTES_RE), c => HTML_ESCAPE_MAP[c])
}

function to_tree(tree: MixedElNode[], context: object, o?: ElContent | ElContent[]): void {
  if (o == undefined) {
    return
  } else if (o instanceof El) {
    let tag = o.tag
    if (tag instanceof Function) {
      let els = tag(o.attrs || {}, o.children, context)
      if (tag == Fragment) {
        if (els instanceof El) to_tree(tree, context, els.children)
        else              raise(`fragment must produce El`)
      } else {
        to_tree(tree, context, els)
      }
    } else {
      const children: MixedElNode[] = []
      to_tree(children, context, o.children)
      const node: ElNode = { tag }
      if (o.attrs) node.attrs = o.attrs
      if (children.length > 0) node.children = children
      tree.push(node)
    }
  } else if (Array.isArray(o)) {
    for (const item of o) if (item) to_tree(tree, context, item)
  } else {
    const children = to_s(o)
    if (children != '') tree.push(children)
  }
}

function to_html_impl(html: string[], o: MixedElNode | MixedElNode[], indent: string): boolean {
  if (Array.isArray(o)) {
    if (o.length == 0) return false
    const all_els = o.every(v => el_node7(v) && v.tag != 'raw')
    if (all_els) html.add("\n")
    const cond_indent = all_els ? indent : ''
    for (const item of o) {
      if (el_node7(item)) {
        if (item.tag == 'raw') {
          assert(!item.attrs || empty7(item.attrs), () => `Raw html node cannot have attrs: ${item.attrs}`)
          item.children?.each((c) => html.add(to_s(c)))
        } else {
          to_html_impl(html, item, cond_indent)
        }
      } else {
        html.add(escape_html(item))
      }
      if (all_els) html.add("\n")
    }
    if (all_els) html.add(indent.substring(0, indent.length - 2))
    return true
  } else if (el_node7(o)) {
    if (o.tag == 'html') html.add("<!DOCTYPE html>\n")
    const attrs = o.attrs || {}, children = o.children || []

    // Newline for better readability
    if ((attrs['c']) && !(html.at(-1) == "\n" && html.at(-2) == "\n")) html.add("\n")
    html.add(`${indent}<${o.tag}`)

    const attr_names = Object.keys(attrs)
      .filter(k => k != 'c' && k != 'tag' && !(k == 'class' && /^\s*$/.test(attrs[k] as string)))
    if (attr_names.length > 0) {
      html.add(' ')
      ;(attr_names as any).sort()
      html.add(attr_names.map(k => `${k}="${escape_html(attrs[k])}"`).join(' '))
    }
    html.add(">")

    if (children?.length > 0) to_html_impl(html, children, `  ${indent}`)

    html.add(`</${o.tag}>`)
    if (attrs['c']) html.add("\n") // Newline for better readability

    return true
  } else {
    html.add(escape_html(o))
    return true
  }
}

function el_node7(o: any): o is ElNode {
  return (typeof o == 'object') && ('tag' in o)
}

// Helpers -----------------------------------------------------------------------------------------
const HTML_ESCAPE_MAP: Record<string, string> = {
  '&': '&amp;', '<': '&lt;', '>': '&gt;', '\"': '&quot;', "'": '&#39;'
}

export function to_css(
  styles:
    string |
    string[] |
    Record<string, string | number | Record<string, string | number>> |
    Record<string, string | number | Record<string, string | number>>[],
  { format = false, compress = false } = {}
): string {
  // Usage:
  //  to_css('.name': { color: 'black' })
  //  to_css(['.name': { color: 'black' }, '.date': { color: 'blue' }])
  // Shorthand for `@apply`:
  //  to_css('.name': 'py-1 text-gray-100')

  function props_to_css(selector: string, props: object): string {
    return selector + " {\n" + Object.entries(props).map(([prop, value]) => {
      prop = (prop == 'apply' ? '@apply' : `${prop}:`).replace(/_/g, '-')
      return `  ${prop} ${value};\n`
    }).join('') + "}"
  }

  let css: string
  if        (Array.isArray(styles)) {
    css = styles.map(s => to_css(s)).join("\n")
  } else if (typeof styles == 'string') {
    css = styles
  } else if (typeof styles == 'object') {
    css = Object.entries(styles).map(([selector, props_or_apply]) => {
      if(typeof props_or_apply == 'string') return props_to_css(selector, { apply: props_or_apply })
      if(props_or_apply instanceof Object)  return props_to_css(selector, props_or_apply)
      raise(`invalid selector props: ${props_or_apply}`)
    }).join("\n")
  } else {
    raise(`invalid styles: ${styles}`)
  }

  // Removing comments
  css = css.replace(/\/\/[^\n]*\n/g, '')

  if (format) css = `\n${css.indent(2)}\n`.indent(4)
  if (compress) css = compress_css(css)
  return css
}