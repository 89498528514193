import { def } from './support'

def(Function.prototype, {
  equal7<T extends Function>(this: T, other: T): boolean { return this === other },

  to_safe(this: Function) {
    const self = this
    return function(...args: unknown[]) {
      try {
        return { error7: false, result: self(...args) }
      } catch (e) {
        return { error7: true, error: error_message(e) }
      }
    }
  },

  debounce<F extends (...args: any[]) => void >(this: F, timeout: number, immediate = false): F {
    let timer: any = undefined, self = this
    return ((...args: any[]) => {
      if (immediate) {
        immediate = false
        self(...args)
      } else {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => self(...args), timeout)
      }
    }) as F
  },

  once<F extends Function>(f: F): F {
    let called = false, result: any = undefined
    return function (this: any) {
      if (called) return result
      result = f.apply(this, arguments)
      called = true
      return result
    } as any
  }
})