import 'base'
import { unirequire } from './browser'

export const parsers: Record<string, (s: string) => any> = {
  yml:  from_yaml,
  yaml: from_yaml,
  json: (data) => from_s(data),
  csv:  from_csv,
}

export function parse<T>(data: string, type: string): T {
  const parse = parsers[type] || raise(`No parser for: ${type}`)
  return parse(data)
}

// CSV ---------------------------------------------------------------------------------------------
export interface CSVTable {
  columns: string[]
  rows:    Record<string, any>[]
}

export function from_csv(csv: string): CSVTable {
  const { errors, data, meta: { fields } } = get_csv().parse(csv, { header: true, dynamicTyping: true })
  if (errors.lenght > 0) throw new Error(error_message(errors[0]))
  return { columns: fields, rows: data }
}

let CSV: any
function get_csv(): any {
  if (!CSV) CSV = unirequire('..', '', '/vendor/papaparse-5.0.2.min.js')
  return CSV
}

// YAML --------------------------------------------------------------------------------------------
let YAML: any
function get_yaml() {
  if (!YAML) YAML = unirequire('..', '', '/vendor/js-yaml-4.1.0.min.js')
  return YAML
}

export function to_yaml(object: any): string {
  return get_yaml().dump(object, { sortKeys: true }).trim()
}

export function from_yaml<T>(text: string): T {
  return get_yaml().load(text)
}

// Helpers -----------------------------------------------------------------------------------------
// export function get_key(data: unknown, k: string, errors: string[]): unknown {
//   if (data === null || data === nil) return nil
//   if (!object7(data)) {
//     errors.add(`Can't get key from data: ${k}, ${data}`)
//     return nil
//   }
//   return k in data ? (data as any)[k] : nil
// }

// export function get_number(data: unknown, k: string, errors: string[]): number | nil {
//   const v = get_key(data, k, errors)
//   if (v === nil) return nil
//   if (number7(v)) return v
//   errors.add(`The value is not a number: ${v}`)
//   return nil
// }

// export function get_string(data: unknown, k: string, errors: string[]): string | nil {
//   const v = get_key(data, k, errors)
//   if (v === nil) return nil
//   if (string7(v)) return v
//   errors.add(`The value is not a string: ${v}`)
//   return nil
// }

// export function get_boolean(data: unknown, k: string, errors: string[]): boolean | nil {
//   const v = get_key(data, k, errors)
//   if (v === nil) return nil
//   if (boolean7(v)) return v
//   errors.add(`The value is not a boolean: ${v}`)
//   return nil
// }

// export function get_array(data: unknown, k: string, errors: string[]): unknown[] | nil {
//   const v = get_key(data, k, errors)
//   if (v === nil) return nil
//   if (array7(v)) return v
//   errors.add(`The value is not an array: ${v}`)
//   return nil
// }

// export function get_record(data: unknown, k: string, errors: string[]): Record<string, unknown> | nil {
//   const v = get_key(data, k, errors)
//   if (v === nil) return nil
//   if (record7(v)) return v
//   errors.add(`The value is not a record: ${v}`)
//   return nil
// }

// export function check_allowed_keys(data: unknown, allowed_keys: string[], errors: string[]) {
//   if (!object7(data)) {
//     errors.add(`The data is not an object: ${data}`)
//     return
//   }
//   for (const k of keys(data)) if (!allowed_keys.includes(k)) errors.add(`Unknown key: ${k}`)
// }

// Test --------------------------------------------------------------------------------------------
if (import.meta.main) {
  const o = { b: [1, 'c', true], a: 1,  }

  p(parse(to_yaml(o), 'yaml'))
  p(parse('a: 1', 'yaml'))

  const csv = `
    a,b,c
    1,2,3
  `.dedent()
  p(parse(csv, 'csv'))
}