import 'base'
import { to_html, to_text } from '../elements'
import { h } from 'ext/el'
import * as fschema from 'htext/parser/schema'
import { block_from_htext as from_htext } from '../doc/htext_parsers'
import { BlockEl, ResolveContext, HtmlContext, HText } from '../doc'
import { parse_text_items } from 'keep/doc/htext'
import { head_to_text } from 'keep/blocks'
import { screen_width_md_or_more7 } from 'ext/browser'

export interface TableAsCardOptions { style: 'cards', header?: boolean, cols?: number, img_aspect_ratio?: number }

export type TableOptions = { header?: boolean } & (
  { style?: 'table' } | TableAsCardOptions
)

export class TableBlockEl implements BlockEl {
  type = 'table'
  resolved?: { cols: number }
  constructor(
    readonly id: string | nil, readonly tags: string[],
    readonly rows: HText[][], readonly options: TableOptions
  ) {}

  async resolve(ctx: ResolveContext) {
    // Validating table
    const cols = (!this.rows.empty7() && this.options.header) ?
      this.rows[0].length :
      this.rows.max(r => r.length)

    for (const r of this.rows) {
      if (r.length > cols) {
        ctx.errors.add({ error: 'Too many cols in row', context: this.to_text().join(' ') })
      } else if (r.length < cols) {
        ctx.errors.add({ error: 'Missing cols in row', context: this.to_text().join(' ') })
      }
    }

    const promises: (Promise<unknown> | nil)[] = []
    this.rows.map(row => row.each(cell => cell.each(item => promises.add(item.resolve?.(ctx)))))
    await Promise.all(promises)

    this.resolved = { cols }
  }

  to_text() {
    const rows = this.rows.map(row => row.map(cell => to_text(cell))).flat()
    return [...head_to_text(this.tags, this.options), ...rows]
  }

  to_html(ctx: HtmlContext) {
    const { cols } = ensure(this.resolved)

    // Detecting if some columns has only images
    const single_image_cols: boolean[] = []
    for (let ci = 0; ci < cols; ci++) {
      single_image_cols[ci] = true
      for (let ri = (this.options.header ? 1 : 0); ri < this.rows.length; ri++) {
        const cell = this.rows[ri][ci] || []
        const single_image = cell.length == 0 || (cell.length == 1 && cell[0].type == 'image')
        if (!single_image) {
          single_image_cols[ci] = false
          break
        }
      }
    }

    return this.options.style == 'cards' ?
      this.to_html_as_cards(cols, single_image_cols, this.options, ctx) :
      this.to_html_as_table(cols, single_image_cols, ctx)
  }

  to_html_as_table(cols: number, single_image_cols: boolean[], ctx: HtmlContext): string {
    const tbody = h('tbody', {})

    // Header
    if (this.options.header && this.rows.length > 0) {
      const header = h('tr', { class: 'border-b border-gray-200' }); tbody.children.add(header)
      for (let ci = 0; ci < cols; ci++) {
        const cell_el = h('raw', {}, to_html(this.rows[0][ci] || [], ctx))
        header.children.add(h('th', {
          class: 'py-1' + (ci < cols - 1 ? ' pr-4' : ''),
          style: single_image_cols[ci] ?
            //  If columns has only images, making it no more than 25%
            'vertical-align: middle; text-align: center; width: 25%;' :
            'vertical-align: middle; text-align: left;'
        }, cell_el))
      }
    }

    // Rows
    for (let ri = (this.options.header ? 1 : 0); ri < this.rows.length; ri++) {
      const klass = ri < this.rows.length - 1 ? 'border-b border-gray-200' : ''
      const row = h('tr', { class: klass }); tbody.children.add(row)
      for (let ci = 0; ci < cols; ci++) {
        const cell_el = h('raw', {}, to_html(this.rows[ri][ci] || [], ctx))
        const klass = 'py-1' + (ci < cols - 1 ? ' pr-4' : '')
        const style = single_image_cols[ci] ?
          //  If columns has only images, making it no more than 25%
          'vertical-align: middle; text-align: center; width: 25%;' :
          'vertical-align: middle;'
        row.children.add(h('td', { class: klass, style },
          single_image_cols[ci] ?
            h('div', { class: 'image_container overflow-hidden rounded' }, cell_el) :
            cell_el
        ))
      }
    }

    return h('table', {}, tbody).to_html()
  }

  to_html_as_cards(table_cols: number, single_image_cols: boolean[], options: TableAsCardOptions, ctx: HtmlContext): string {
    let card_cols = options.cols ?? 4, img_aspect_ratio = options.img_aspect_ratio ?? 1.5
    if (!screen_width_md_or_more7()) card_cols = card_cols > 4 ? 2 : 1

    const cols_separator_width = 1
    const card_width = (100 - (card_cols - 1)*cols_separator_width) / card_cols

    const tbody = h('tbody', {})
    let i = 0
    for (let ri = 0; ri < (this.rows.length / card_cols).ceil(); ri++) {
      const tr_el = h('tr', {}); tbody.children.add(tr_el)
      for (let ci = 0; ci < card_cols * 2 - 1; ci++) {
        if (ci % 2 != 0) {
          tr_el.children.add(h('td', { style: `width: ${cols_separator_width}%;` }))
        } else {
          const cell_el = h('td', { style: `width: ${card_width}%; vertical-align: top;` })
          tr_el.children.add(cell_el)
          if (i < this.rows.length) {
            const card_el = h('div', {
              class: 'flex flex-col space-y-1 py-1 overflow-hidden rounded border border-gray-200'
            }); cell_el.children.add(card_el)
            for (let j = 0; j < Math.min(table_cols, this.rows[i].length); j++) {
              let cell_html = to_html(this.rows[i][j], ctx)
              if (single_image_cols[j]) {
                const ratio_style = `aspect-ratio: ${img_aspect_ratio};`
                card_el.children.add(
                  // The aspect ratio style had to be set on both image and container
                  h('div', { class: 'card_fixed_height_image', style: ratio_style },
                    h('raw', {}, cell_html.replace("<img", `<img style="${ratio_style}"`))
                  )
                )
              } else {
                card_el.children.add(
                  h('div', { class: 'px-2 whitespace-nowrap' + (j == 0 ? ' font-bold' : '') },
                    h('raw', {}, cell_html)
                  )
                )
              }
            }
          }
          i++
        }
      }
    }

    const table_style =
      "border-spacing: 0 0.6rem; margin: -0.6rem 0; border-collapse: separate;" + // setting margin after each row
      " table-layout: fixed; width: 100%" // Preventing table cell to get wider with `white-space: nowrap`:
    return h('div', {}, // It has to be nested in div otherwise `table-layout: fixed` doesn't work
      h('table', { cellspacing: 0, cellpadding: 0, style: table_style }, tbody)
    ).to_html()
  }
}
from_htext('table', async (id, tags, b: fschema.TableBlock, ctx) => {
  const rows = await Promise.map_parallel(b.rows, row => parse_text_items(row, ctx))
  return new TableBlockEl(id, tags, rows, b.options)
})