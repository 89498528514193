import 'base'
import { Table } from '../../vendor/datatable/schema'
import { Data } from 'plot/plot'
import TableImpl from '../../vendor/datatable/datatable'
import { head_to_text } from 'keep/blocks'
import * as fschema from 'htext/parser/schema'
import { BlockEl, HtmlContext, ResolveContext } from 'keep/doc'
import { h,  Component, createRef } from 'preact'
import { block_from_htext as from_htext } from 'keep/doc/htext_parsers'
import { parse } from 'ext/parsers'

export class DataTableBlockEl implements BlockEl {
  type = 'datatable'

  constructor(
    readonly id: string | nil, readonly tags: string[],
    readonly table: Table, readonly data: Data
  ) {}

  async resolve(ctx: ResolveContext) {}

  to_text() { return [ ...head_to_text(this.tags), to_s(this.table), to_s(this.data)] }

  to_html(ctx: HtmlContext) {
    return <TableView options={this.table} data={this.data}/>
  }
}
from_htext('datatable', async (id, tags, b: fschema.CustomBlock) => {
  const content = parse<Data | { table: Table, data: Data }>(b.content, 'yaml')
  let table: Table, data: Data
  if ('data' in content) {
    table = (content.table || {}) as Table; data = content.data as Data }
  else {
    table = b.props || {}; data = content
  }
  return new DataTableBlockEl(id, tags, table, data)
})

class TableView extends Component<{ options: Table, data: Data }> {
  dt_ref = createRef<HTMLDivElement>()

  render() {
    return <div ref={this.dt_ref}/>
  }

  componentDidMount() {
    new TableImpl({ target: ensure(this.dt_ref.current), props: this.props })
  }
}