// Hash Code ---------------------------------------------------------------------------------------
export function cyrb53(str: string, seed = 0) {
  // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed
  for(let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i)
      h1 = Math.imul(h1 ^ ch, 2654435761)
      h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export function Hashes(seed = 0) {
  return {
    code: seed,
    add: function (code: number) {
      // Taken from Nim `!&` Hash operator.
      this.code = this.code + code
      this.code = this.code + (this.code << 10)
      return this.code = this.code ^ this.code >>> 6
    }
  }
}

export function object_hash_code(o: Object) {
  const h = Hashes()
  for (let k of (Object.getOwnPropertyNames(o) as any).sort()) {
    const v = (o as any)[k]
    h.add(cyrb53(k))
    h.add(v.hash_code())
  }
  return h.code
}

export function iterator_hash_code<T>(iterator: Iterable<T>) {
  const h = Hashes()
  for (let v of iterator) h.add(v?.hash_code() ?? 0)
  return h.code
}

export function array_hash_code<T>(...args: T[]) {
  const h = Hashes()
  for (let i = 0; i < args.length; i++) {
    h.add(args[i]?.hash_code() ?? 0)
  }
  return h.code
}

// uniq_stable --------------------------------------------------------------------------------------
export function uniq_stable<T, K>(list: Iterable<T>, fn = function (v: T): K { return v as any }): T[] {
  // Checking if it's Hasheable
  let is_empty = true, first = undefined
  for (let v of list) {
    is_empty = false; first = v
    break
  }
  if (is_empty) return []
  return fn(first!)?.hash_code ? uniq_stable_fast(list, fn as any) : uniq_stable_slow(list, fn)
}

export function uniq_stable_fast<T, K>(list: Iterable<T>, op: (v: T) => K): T[] {
  // Fast algo using hash code and equality
  const r = []
  const seen = new Map<number, K[]>()
  for (let v of list) {
    const k = op(v), k_hash = k?.hash_code() ?? 0
    if (seen.has(k_hash)) {
      const seen_keys = seen.get(k_hash)!
      if (!(seen_keys as any).has7(k)) {
        seen_keys.push(k)
        r.push(v)
      }
    } else {
      seen.set(k_hash, [k])
      r.push(v)
    }
  }
  return r
}

export function uniq_stable_slow<T, K>(list: Iterable<T>, op: (v: T) => K): T[] {
  // Slow algo using equality only
  const r = [], seen: K[] = []
  for (let v of list) {
    const k = op(v)
    if (!(seen as any).has7(k)) {
      seen.push(k)
      r.push(v)
    }
  }
  return r
}

// Compare -----------------------------------------------------------------------------------------
export function compare_with_lt<T>(a: T, b: T): number {
  if (a < b) return -1
  if (b < a) return 1
  return 0
}

export function compare_iterables<T>(a: Iterable<T>, b: Iterable<T>): number {
  const it = a[Symbol.iterator](), it2 = b[Symbol.iterator]()
  while (true) {
    const v = it.next(), v2 = it2.next()
    if (!v.done && !v2.done) {
      const r = compare(v.value, v2.value)
      if (r != 0) return r
    } else if (v.done && v2.done) {
      return 0
    } else if (v.done) {
      return -1
    } else {
      return 1
    }
  }
}

/** Clones any value, with properties sorted and `undefined` removed, recursively. */
export function deep_normalize<T>(o: T): T {
  if      (Array.isArray(o))  return o.map(deep_normalize) as any
  else if (typeof o === 'object' && o !== null) {
    if ('to_data' in o)  {
      return deep_normalize((o as any).to_data())
    } else {
      return Object.assign({},
        ...Object.entries(o)
          .filter(([_k, v]) => v !== undefined)
          .sort(([a]: any, [b]: any) => a.localeCompare(b))
          .map(([k, v]) => ({ [k]: deep_normalize(v) })
      ))
    }
  }
  else return o
}

// Console colors ----------------------------------------------------------------------------------
const colors = {
  reset: "\x1b[0m",
  black: "\x1b[30m", green: "\x1b[32m", grey: "\x1b[90m", yellow: "\x1b[33m", red: "\x1b[31m"
}

/** Formatted log, example `console.flog(['a', 'color: red;'], 'b', ['c', 'color: green;'])` */
export function build_flog(runtime: 'deno' | 'node' | 'bun' | 'browser') {
  return function flog(...formatted_message: ([string, string] | string)[]): void {
    const messages: string[] = [], formats: string[] = []
    for (let chunk of formatted_message) {
      if (Array.isArray(chunk)) { messages.push(to_s(chunk[0])); formats.push(chunk[1]) }
      else                      { messages.push(to_s(chunk));    formats.push('') }
    }
    if (['browser', 'deno'].includes(runtime)) {
      console.log(messages.map((m) => `%c${m}`).join(''), ...formats)
    } else if (['node', 'bun'].includes(runtime)) {
      const formatted = messages.map((m, i) => {
        const found = formats[i].match(/color: ([a-z]+);/)
        return found ? (((colors as any)[found[1]] || colors.black) + m + colors.reset) : m
      })
      console.log(formatted.join(''))
    } else {
      console.log(...messages)
    }
  }
}