// Hypertext ---------------------------------------------------------------------------------------
export type Hypertext = HypertextToken[]
export type HypertextToken =
  TagToken | TextToken | LinkToken | TagToken | EmbedToken | HtmlToken | ImageToken | EmToken |
  CodeToken | ErrorToken

export interface TagToken   extends AToken { t: 'tag' }
export interface EmbedToken extends AToken { t: 'embed', embed_pos?: Pos, embed_type_pos?: Pos }
export interface TextToken  extends AToken { t: 'text' }
export interface LinkToken  extends AToken { t: 'link',  title_pos?: Pos, link_pos: Pos }
export interface ImageToken extends AToken { t: 'image', title_pos?: Pos, path_pos: Pos }
export interface EmToken    extends AToken { t: 'em' }
export interface CodeToken  extends AToken { t: 'code', code_pos?: Pos }
export interface HtmlToken  extends AToken { t: 'html' }

export interface AToken { pos: Pos }
export interface ErrorToken extends AToken { t: 'error', errors: string[] }

// BlockTokens -------------------------------------------------------------------------------------
export type BlockToken =
  DocHeaderBlock | SectionHeaderBlock | ChapterHeaderBlock | BlockHeaderBlock | TagsBlock | ContentBlock | ErrorToken

export interface DocHeaderBlock     extends AHeaderBlock { t: 'doc_header',     title?: Pos }
export interface SectionHeaderBlock extends AHeaderBlock { t: 'section_header', title?: Pos }
export interface ChapterHeaderBlock extends AHeaderBlock { t: 'chapter_header', title?: Pos }
export interface BlockHeaderBlock   extends AHeaderBlock { t: 'block_header',   type?: [Pos, string],
  /** If block defined with `==` or have `content` property, it has no content block, otherwise then next
   * block read as content */
  has_content_block: boolean
}
export interface TagsBlock          extends AToken       { t: 'tags', tags: TagToken[] }
/** Parsed later with with the parser specific for the given block type.
 *  Examples: paragraphs, list, code, table, gallery etc.
 *  Could be extended with custom parsers. */
export interface ContentBlock<T extends object = object> extends AToken { t: 'block', type: string }

export interface AHeaderBlock extends AToken {
  tags?:   TagToken[]
  props?:  [Pos, Props]
  errors?: string[]
}

// Basic content blocks ----------------------------------------------------------------------------
export interface ParagraphsBlock { paragraphs: Hypertext[] }
export interface ListBlock       { list: Hypertext[] }
export interface HtmlBlock       { html:  Hypertext }
export interface EmbedBlock      { embed: EmbedToken }
export interface ImageBlock      { path_pos: Pos, title_pos?: Pos }
export interface CodeBlock       { code?: { code: string, pos: Pos }, lang?: Pos, code_type: 'escaped' | 'indented' }

// Custom Lexers -----------------------------------------------------------------------------------
export type BlockLexer<T> = (content: Pos, context: LexerContext) => Result<T, string[]>

export interface LexerContext { source: string, escaped: string }

// Helpers -----------------------------------------------------------------------------------------
export type Pos = [number, number]
export function pos7(v: unknown): v is Pos { return array7(v) && v.length == 2 && number7(v[0]) && number7(v[1]) }
export type Props = Record<string, unknown>

export const uname_start_chars = "\\p{L}\\d"
export const uname_chars       = "\\p{L}\\d\\-_"
export const name_start_chars  = "a-zA-Z"
export const name_chars        = "a-zA-Z0-9\\-_"