import 'base'
import { ParagraphsBlock, Pos, ListBlock, CodeBlock, ContentBlock, ErrorToken, Hypertext, LexerContext, BlockLexer, ImageBlock, EmbedBlock, HtmlBlock } from './schema'
import { Parser } from 'ext/parser'
import { lex_text } from './lex_text'

// Context -----------------------------------------------------------------------------------------
export function get_block_lexers(): Record<string, BlockLexer<unknown>> { return {
  paragraphs: lex_paragraphs_block,
  list:       lex_list_block,
  code:       lex_code_block,
  embed:      lex_embed_block,
  html:       lex_html_block,
  image:      lex_image_block,
} }

// Paragraphs --------------------------------------------------------------------------------------
export const lex_paragraphs_block: BlockLexer<ParagraphsBlock> = function (content, { escaped }) {
  const paragraphs = lex_paragraphs(content, escaped)
  return to_success({ paragraphs })
}

export function lex_paragraphs(pos: Pos, escaped: string): Hypertext[] {
  const pr = new Parser(escaped.get(...pos)), r: Hypertext[] = []
  while (pr.has7()) {
    const start = pos[0] + pr.i
    const text = pr.consume(() => !pr.start_with7(/\n\s*\n/g)).trimEnd()
    assert(!text.trim().empty7())
    r.add(lex_text([start, start + text.length - 1], escaped))
    pr.skip(/[\n\s]/)
  }
  return r
}

// List --------------------------------------------------------------------------------------------
export const lex_list_block: BlockLexer<ListBlock> = function (content, context) {
  const list = lex_list(content, context.escaped)
  return to_success({ list })
}

export function lex_list(pos: Pos, escaped: string): Hypertext[] {
  const text = escaped.get(...pos)
  assert(!text.start_with7(/\s/g))
  return (text.start_with7('-') ? lex_list_as_list : lex_paragraphs)(pos, escaped)
}

function lex_list_as_list(pos: Pos, escaped: string): Hypertext[] {
  const pr = new Parser(escaped.get(...pos)), r: Hypertext[] = []
  assert(pr.is7('-'))
  while (pr.has7()) {
    pr.skip('-')
    pr.skip(/[ \t]/)
    const start = pos[0] + pr.i
    const text = pr.consume(() => !pr.start_with7(/\n-/g)).trimEnd()
    assert(!text.trim().empty7())
    r.add(lex_text([start, start + text.length - 1], escaped))
    pr.skip(/\n/)
  }
  return r
}

// Code --------------------------------------------------------------------------------------------
export const lex_code_block: BlockLexer<CodeBlock> = function (content, context) {
  const pr = new Parser(context.escaped.get(...content)), shift = content[0]
  if (pr.start_with7('```')) {
    // Lang
    pr.inc(3)
    pr.skip(/[ \t]/)
    const lang_start = shift + pr.i
    const elang = pr.consume(() => !pr.is7("\n")).trimEnd()
    const lang: Pos | nil = !elang.empty7() ? [lang_start, lang_start + elang.length - 1] : nil
    // Code
    pr.skip(/\s\n/)
    const code_start = shift + pr.i
    const ecode = pr.consume(() => !pr.start_with7("\n```")).trimEnd()
    let code: CodeBlock['code'] | nil
    if (!ecode.empty7()) {
      const code_pos: Pos = [code_start, code_start + ecode.length - 1]
      const code_content = context.source.get(...code_pos).trim()
      code = { code: code_content, pos: code_pos }
    }
    return to_success({ code, lang, code_type: 'escaped' })
  } else if (pr.start_with7('  ')) {
    const code_pos: Pos = [shift + pr.i, shift + pr.text.length - 1]
    const code_content = context.source.get(...code_pos).trim().replace(/^ {2}/gm, '')
    return to_success({ code: { code: code_content, pos: code_pos }, code_type: 'indented' })
  } else {
    return to_error(['Invalid format of the code block'])
  }
}

// Embed -------------------------------------------------------------------------------------------
export const lex_embed_block: BlockLexer<EmbedBlock> = function (content, context) {
  const text = lex_text(content, context.escaped)
  if (text.length == 1 && text[0].t == 'embed') {
    return to_success({ embed: text[0] })
  } else {
    return to_error(['Invalid embed block'])
  }
}

// Html --------------------------------------------------------------------------------------------
export const lex_html_block: BlockLexer<HtmlBlock> = function (content, context: LexerContext) {
  const text = lex_text(content, context.escaped)
  assert(text.length >= 1)
  assert.equal(text[0].t, 'html')
  return to_success({ html: text })
}

// Image -------------------------------------------------------------------------------------------
/** Example `![](path/img.jpg)` `path/img.jpg` */
export const lex_image_block: BlockLexer<ImageBlock> = function (content, context) {
  const text = lex_text(content, context.escaped)
  if (text.length == 1 && text[0].t == 'image') { // ![](img.png)
    return to_success({ path_pos: text[0].path_pos, title_pos: text[0].title_pos })
  } else if (text.length == 1 && text[0].t == 'text') { // img.png
    return to_success({ path_pos: text[0].pos })
  } else {
    return to_error(['Invalid image block'])
  }
}