import 'base'
import { ABlock, BlockParser, BlockParserResult, Chapter, Doc, DocError, ParserContext, Section } from './schema'
import { escape_data } from 'htext/lexer/escape_data'
import { separate_blocks } from 'htext/lexer/separate_blocks'
import { BlockHeaderBlock, BlockLexer, ContentBlock, Pos, TagToken } from 'htext/lexer/schema'
import { get_block_parsers, parse_custom_block } from './parse_block'

export interface ParseOption {
  block_parsers?: Record<string, BlockParser>
  block_lexers?: Record<string, BlockLexer<unknown>>
}
export function parse(source: string, options: ParseOption = {}): Doc {
  const escaped = escape_data(source), errors: DocError[] = []
  const block_parsers = { ...get_block_parsers(), ...options.block_parsers }

  // Separating blocks
  let tokens = separate_blocks(escaped, source)

  // Building the doc
  const doc: Doc = { sections: [] }
  if (tokens.empty7()) return doc

  let section: Section | nil, chapter: Chapter | nil
  let previous_with_tags: { tags?: string[] } | nil
  function get_section(): Section {
    if (section == nil) {
      section = { chapters: [] }
      doc.sections.add(section)
    }
    return section
  }
  function get_chapter(): Chapter {
    if (chapter == nil) {
      chapter = { blocks: [] }
      get_section().chapters.add(chapter)
    }
    return chapter
  }

  const ctx: ParserContext = { source, escaped, errors }
  function add_block(header: BlockHeaderBlock | nil, content: ContentBlock | nil) {
    const chapter = get_chapter()

    const props = { ...(header?.props?.[1] || {}) }
    const content_from_props = 'content' in props ? props.content : nil
    delete props.content
    const tags = resolve_tags(header?.tags || [], source)
    const type = header?.type?.[1] || content?.type || 'paragraphs'
    if (content) assert.equal(type, content.type)
    const whole_block_pos: Pos = [
      header?.pos[0]  ?? content?.pos[0] ?? raise('internal error, no header nor content 1'),
      content?.pos[1] ?? header?.pos[1]  ?? raise('internal error, no header nor content 2')
    ]
    if (header?.errors) raise('internal error, header with errors shouldnt be processed')

    const parser: BlockParser = block_parsers[type] || parse_custom_block
    previous_with_tags = {}
    if (parser != nil) {
      let parsed: BlockParserResult
      if (content) {
        parsed = parser(content.pos, props, ctx)
      } else {
        const content_s = to_s(nil7(content_from_props) ? '' : content_from_props)
        const ctx2: ParserContext = { source: content_s, escaped: escape_data(content_s), errors: [] }
        parsed = parser(content_s.length > 0 ? [0, content_s.length - 1] : nil, props, ctx2)
        ctx.errors.add(...ctx2.errors.map(({ error }) => ({ error, pos: whole_block_pos }))) // Replacing pos for errors
      }

      if (parsed) {
        for (const k of ['type', 'tags', 'props']) {
          if (k in parsed) raise(`Block parser shouldn't set property: '${k}' ${type}`)
        }
        const block: ABlock = {
          ...parsed,
          type, pos: whole_block_pos,
          ...(empty7(props) ? {} : { props }),
          ...(tags.empty7() ? {} : { tags })
        }
        chapter.blocks.add(block)
        previous_with_tags = block
      }
    }
  }

  for (let i = 0; i < tokens.length; i++) {
    const t = tokens[i]
    if        (t.t == 'doc_header') { // Doc header
      if (i == 0) {
        const { title, tags, props, errors } = t
        if (title)                     doc.title = source.get(...title)
        if (tags && !empty7(tags))     doc.tags  = resolve_tags(tags, source)
        if (props && !empty7(props))   doc.props = { ...props[1] }
        if (errors && !empty7(errors)) ctx.errors.add(...errors.map(error => ({ error, pos: t.pos })))
      } else {
        ctx.errors.add({ error: 'Doc header should be the first block', pos: t.pos })
      }
    } else if (t.t == 'section_header') { // Section header
      section = { chapters: [], pos: t.pos }; chapter = nil
      doc.sections.push(section)
      const { title, tags, props, errors } = t
      if (title)                    section.title = source.get(...title)
      if (tags && !empty7(tags))    section.tags  = resolve_tags(tags, source)
      if (props && !empty7(props))  section.props = { ...props[1] }
      if (errors)                   ctx.errors.add(...errors.map(error => ({ error, pos: t.pos })))
      previous_with_tags = section
    } else if (t.t == 'chapter_header') { // Chapter header
      chapter = { blocks: [], pos: t.pos }
      get_section().chapters.add(chapter)
      const { title, tags, props, errors } = t
      if (title)                    chapter.title = source.get(...title)
      if (tags && !empty7(tags))    chapter.tags  = resolve_tags(tags, source)
      if (props && !empty7(props))  chapter.props = { ...props[1] }
      if (errors)                   ctx.errors.add(...errors.map(error => ({ error, pos: t.pos })))
      previous_with_tags = chapter
    } else if (t.t == 'block_header') { // Block header
      if (t?.errors && !t.errors?.empty7()) {
        ctx.errors.add(...t.errors.map(error => ({ error, pos: t.pos })))
      } else {
        let content_block: ContentBlock | nil = nil
        if (t.has_content_block && (i < tokens.length - 1)) {
          const next = tokens[i + 1]
          if (next.t == 'block') {
            content_block = next
            i++
          }
        }
        add_block(t, content_block)
      }
    } else if (t.t == 'block') { // Block
      add_block(nil, t)
    } else if (t.t == 'tags') {
      const tags = resolve_tags(t.tags, source)
      function add_to(v: { tags?: string[] }) {
        if (!tags.empty7()) v.tags = [...(v.tags || []), ...tags].uniq().order()
      }

      if      (i == tokens.length - 1)  add_to(doc) // Last tags are for doc
      else if (previous_with_tags)      add_to(previous_with_tags)
      else                              {
        ctx.errors.add({ error: 'Dangling tags', pos: t.pos })
      }
    } else if (t.t == 'error') {
      ctx.errors.add(...t.errors.map(error => ({ error, pos: t.pos })))
    } else {
      assert.never(t)
    }
  }

  if (!ctx.errors.empty7()) doc.errors = ctx.errors
  return doc
}

function resolve_tags(tags: TagToken[], source: string): string[] {
  return tags.map(({ pos: [a, b] }) => source.get(a + 1, b)).uniq().order()
}