import 'base'
import { h, FunctionComponent, createContext, Fragment, VNode, render } from 'preact'
import { useContext, useState } from 'preact/hooks'
import { parse_htext } from './doc/htext'
import { browser_load_text, ensure_loaded } from 'ext/browser'
import { Doc, DocError, ResolveContext, HtmlContext } from './doc'
import { parse } from 'ext/parsers'
import { PlotView } from './blocks/plot'
import { EntryHead } from 'ext/store'

export const PaletteContext = createContext({
  tags_path:   '/tags',
  mockup_mode: false,
  assets_path: '',
})

export function mockup_mode7(): boolean { return useContext(PaletteContext).mockup_mode }
export function no_mockup(klass: string): string { return mockup_mode7() ? '' : klass }
export function asset_path(path: string): string { return useContext(PaletteContext).assets_path + path }
export function tag_path(path: string): string { return useContext(PaletteContext).tags_path + "/" + path }

// const styles: string[] = []
// function add_styles(s: string) { styles.add(s.dedent()) }

// Elementary --------------------------------------------------------------------------------------
export const IconLink: FunctionComponent<{
  icon: string, title?: string, size?: string, color?: string, url?: string
}> = (props) => {
  const { icon, title, size, color, url } = { ...{ size: 'w-5 h-5', color: 'bg-blue-800', url: '#' }, ...props }
  const style = { '-webkit-mask-image': `url(${asset_path('/palette/icons/' + icon + '.svg')})` }
  return <a class={`block svg-icon ${size} ${color}`} href={url} title={title || ''} style={style}/>
}

export const IconButton: FunctionComponent<{
  icon: string, title?: string, size?: string, color?: string, onClick?: () => void
}> = (props) => {
  const { icon, title, size, color, onClick } = { ...{ size: 'w-5 h-5', color: 'bg-gray-500' }, ...props }
  const style = { '-webkit-mask-image': `url(${asset_path('/palette/icons/' + icon + '.svg')})` }
  return <button class={`block svg-icon ${size} ${color}`} title={title || ''} style={style} onClick={onClick}/>
}

export const SymButton: FunctionComponent<{
  sym: string, size?: string, color?: string, onClick?: () => void
}> = (props) => {
  const { sym, size, color, onClick } = { ...{ size: 'w-5 h-5', color: 'gray-500' }, ...props }
  return <button class={`block ${size} ${color}`} onClick={onClick}>{sym}</button>
}

export const TextButton: FunctionComponent<{ text: string, color?: string }> = (props) => {
  const { text, color } = { ...{ color: 'text-blue-800' }, ...props }
  return <button class={color}>{text}</button>
}

export const TextLink: FunctionComponent<{ text: string, color?: string, url?: string, class?: string }> = (props) => {
  const { text, color, url } = { ...{ color: 'text-blue-800', url: '#' }, ...props }
  return <a class={color + (props.class ? ' ' + props.class : '')} href={url}>{text}</a>
}

export const Message: FunctionComponent<{ text: string, type?: 'info' | 'error', top?: boolean }> = (props) => {
  const { text, type, top } = { ...{ type: 'info', top: false }, ...props }
  const klass = `pmessage block p-2 rounded bg-slate-50 ${type == 'error' && 'text-orange-800'} ${top && 'm-5'}`
  return <p class={klass}>{text}</p>
}

export const Link: FunctionComponent<{ text: string, link: string }> = ({ text, link }) => {
  return <a class='text-blue-800' href={link}>{text}</a>
}

export const Progress: FunctionComponent = ({ children }) =>
  <div class="animate-pulse text-gray-500">{children || "Processing..."}</div>


//  Right ------------------------------------------------------------------------------------------
export const RBlock: FunctionComponent<{ name?: string, title?: string, closed?: boolean }> = (props) => {
  const { name, title, closed } = { ...{ name: 'prblock', closed: false }, ...props }
  if (closed) {
    assert(!!title && !title.empty7(), "can't have empty title on closed rsection")
    return h(name, { class: 'block relative c' },
      <div class='text-gray-300'>{title}</div>,
      <div class='absolute top-0 right-0 pt-1'>
        <IconButton icon='left' size='w-4 h-4' color='bg-gray-300'/>
      </div>
    )
  } else {
    function invisible_button() {
      const b = <IconButton icon='edit'/>
      b.props.class += ' opacity-0'
      return b
    }

    return h(name, { class: 'block relative c' },
      title && <div class='text-gray-300'>{title}</div>,
      nil7(props.children) ? invisible_button() : props.children
    )
  }
}

export const Favorites: FunctionComponent<{ links: [string, string][], closed?: boolean }> = (props) => {
  return <RBlock name='prblock-favorites' title='Favorites' closed={props.closed}>
    {props.links.map(([text, link]) => <TextLink text={text} url={link} class="block"/>)}
  </RBlock>
}

export const Backlinks: FunctionComponent<{ links: [string, string][], closed?: boolean }> = (props) => {
  return <RBlock name='prblock-backlinks' title='Backlinks' closed={props.closed}>
    {props.links.map(([text, link]) => <TextLink text={text} url={link} class="block text-sm"/>)}
  </RBlock>
}

export interface CloudTag { tag: string, type?: 'normal' | 'included' | 'excluded' | 'ignored' }
export const TagsCloud: FunctionComponent<{ tags: CloudTag[], closed?: boolean }> = (props) => {
  const classes = {
    normal:   'text-blue-800 bg-blue-50 border-blue-50',
    included: 'text-blue-800 bg-blue-50 border-blue-50',
    excluded: 'text-pink-800 bg-pink-50 border-pink-50',
    ignored:  'text-gray-400 border-gray-200'
  }
  return <RBlock name='prblock-tags' title='Tags' closed={props.closed}>
    <div class='-mr-1'>
      {props.tags.map(tag =>
        <><a key={tag} class={`mr-1 rounded px-1 border ${classes[tag.type || 'normal']}`}>{tag.tag}</a> </>
      )}
    </div>
  </RBlock>
}

export const SearchField: FunctionComponent<{ text: string, info?: string }> = ({ text, info }) => {
  const klass =
    'border rounded border-gray-300 placeholder-gray-400 px-1 w-full focus:outline-none ' +
    'placeholder-gray-500 resize-none rows-2'
  return <>
    <textarea name="_search" class={klass} placeholder='Find...'>{text}</textarea>
    {info && <div class='text-sm text-gray-400'>{info}</div>}
  </>
}

export const RSpaceInfo: FunctionComponent<{
  space: string, warns: [string, string][], closed?: boolean
}> = (props) => {
  return <RBlock name='prblock-space-info' title='Space' closed={props.closed}>
    <TextLink text={props.space} url='#'/>
    {props.warns.length > 0 && <div class='border-l-2 border-orange-800 flex'>
      {props.warns.map(([text, link]) =>
        <a class='block ml-2 text-sm text-orange-800' href={link}>{text}</a>
      )}
    </div>}
  </RBlock>
}

// Content -----------------------------------------------------------------------------------------
/** Could be used separately or inside of RBlock */
export const Warnings: FunctionComponent<{ warns: [string, string | nil][], closed?: boolean }> = (props) => {
  const klass = 'block ml-2 text-sm text-orange-800'
  return <div class='border-l-2 border-orange-800 flex'>
    {props.warns.map(([text, link]) =>
      link ? <a class={klass} href={link}>{text}</a> : <div class={klass}>{text}</div>
    )}
  </div>
}

export const Table: FunctionComponent<{header?: VNode[], rows: VNode[][] }> = ({ header, rows }) => {
  return <table>
    <tbody>
      {header && <tr class='border-b border-gray-200'>
        {header.map((cell, i) =>
          <th
            class={'py-1 ' + (i < header.length - 1 ? 'pr-4' : '')}
            style={{ 'text-align': 'left', 'vertical-align': 'middle' }}
          >{cell}</th>
        )
      }</tr>}

      {rows.map((row, i) =>
        <tr class={i < rows.length - 1 ? 'border-b border-gray-200' : ''}>
          {row.map((cell, i) =>
            <td
              class={'py-1 ' + (i < row.length - 1 ? 'pr-4' : '')}
              style={{ 'vertical-align': 'middle' }}
            >{cell}</td>
          )}
        </tr>
      )}
    </tbody>
  </table>
}

//  Blocks -----------------------------------------------------------------------------------------
const BlockControls: FunctionComponent<{ controls: VNode[], hover?: boolean }> = ({ controls, hover }) => {
  return controls.empty7() ? null : <div
    class={
      'pblock-controls block absolute right-0 top-1 flex bg-white rounded p-1' +
      (hover ? ' hidden_controls' : '')
    }
    style={{ 'margin-top': 0 }}
  >{controls}</div>
}

const BlockWarns: FunctionComponent<{ warns: string[] }> = ({ warns }) => {
  return warns.empty7() ? null : <div class='pblock-warns block border-l-4 border-orange-800'>
    {warns.map(warn => <div class='inline-block text-orange-800 ml-2'>{warn}</div>)}
  </div>
}

export const Tag: FunctionComponent<{ tag: string, class?: string }> = (props) => {
  const klass = `mr-1 rounded px-1 border text-blue-800 bg-blue-50 border-blue-50 ${props.class || ''}`
  return <a class={klass} href={tag_path(props.tag)}>{props.tag}</a>
}

const BlockTags: FunctionComponent<{ tags: string[] }> = ({ tags }) => {
  return tags.empty7() ?
    null :
    <div class='pblock-tags block -mr-1'>{tags.map(tag => <Tag tag={tag}/>)}</div>
}

export const BlockLayout: FunctionComponent<{
  id?: string, name?: string, warns?: string[], controls?: VNode[], tags?: string[], hover?: boolean
}> = ({ id, name, warns, controls, tags, hover, children }) => {
  const klass = name +
    ' pblock flex flex-col space-y-1 c ' +
    'py-1 pr-2 pl-2 md:pr-6 md:pl-6 ' +
    (hover && 'pblock_hover')
  return <div class={klass} id={id}>
    {warns && <BlockWarns warns={warns}/>}
    {children}
    {tags && <BlockTags tags={tags}/>}
    {/* Controls should be the last, otherwise the first element will have extra margin */}
    {controls && !controls.empty7() && <BlockControls controls={controls} hover={hover}/>}
  </div>
}

export const Pagination: FunctionComponent<{
  count: number, page: number, per_page: number, url: (n: number) => string
}> = ({ count, page, per_page, url }) => {
  if (count <= per_page) return null
  const pages = (count / per_page).floor()
  const next_pages_count = count - page * per_page
  return <BlockLayout name='pblock-pagination'>
    <div>
      {page <= pages && <TextLink text={`Next ${next_pages_count} →`} url={url(page + 1)} class='block float-right' />}
      <TextLink text='← Back' url={url(page - 1)} class={page > 1 ? 'block' : 'block hidden' }/>
    </div>
  </BlockLayout>
}

//  Search -----------------------------------------------------------------------------------------
export interface FoundItem { before: string, after: string, match: string }
export const FoundBlock: FunctionComponent<{ title: string, matches: FoundItem[], url: string }> = (props) => {
  const { title, matches, url } = props
  assert(!matches.empty7(), "at least one match expected")
  const controls = <IconLink icon='link' url={url}/>
  return <BlockLayout name='pfound-block' controls={[controls]}>
    <div class='found-items block'>
      <TextLink text={title} url={url}/>
      {matches.map(({ before, match, after }) =>
        <span class='found-item ml-4'>
          {before}<span class='bg-yellow-100'>{match}</span>{after}
        </span>
      )}
    </div>
  </BlockLayout>
}

// Headers -----------------------------------------------------------------------------------------
function header_tags(tags?: string[]) {
  return tags && tags.map((tag, i) => <> <Tag tag={tag} class={'relative bottom-0.5 ' + (i == 0 ? 'pl-2' : '')}/></>)
}
export const DocHeader: FunctionComponent<{ title: string, hint?: string, tags?: string[] }> = (props) => {
  const { title, tags, hint } = props
  return <div>
    <span class="text-2xl" title={hint ?? ''}>{title}</span> {header_tags(tags)}
  </div>
}

export const SectionHeader: FunctionComponent<{ title: string, tags?: string[] }> = ({ title, tags }) => {
  return <div>
    <span class="text-2xl">{title}</span> {header_tags(tags)}
  </div>
}

export const ChapterHeader: FunctionComponent<{ title: string, tags?: string[] }> = ({ title, tags }) => {
  return <div>
    <span class="text-xl">{title}</span> {header_tags(tags)}
  </div>
}

//  App --------------------------------------------------------------------------------------------
export const App: FunctionComponent<{
  title?: string, title_hint?: string, title_controls?: VNode[], title_hover?: boolean,
  warns?: string[],
  tags?: string[],
  right?: VNode[], right_down?: VNode[],
  show_block_separator?: boolean
}> = (props) => {
  const { title, title_hint, title_hover, title_controls, warns, tags, right, right_down } = props
  return <div class={`papp block w-full flex ${no_mockup('min-h-screen')} c`}>
    <div class='papp-left block w-full md:w-9/12 c'>
      <div class={
        'pdoc flex flex-col space-y-1 mt-2 mb-2 c ' + (props.show_block_separator && 'show_block_separator')
      }>
        {title && <BlockLayout name='pblock-doc-title' warns={warns} controls={title_controls} hover={title_hover}>
          {/* <div class='text-2xl' title={title_hint ? title_hint : ''}>{props.title}</div> */}
          <DocHeader title={title} hint={title_hint} tags={tags}/>
        </BlockLayout>}
        {props.children}
        {/* {props.tags && <BlockLayout name='pblock-doc-tags' tags={tags}/>} */}
      </div>
    </div>
    <div class={
      'papp-right hidden md:w-3/12 md:flex flex-col justify-between border-gray-300 border-l bg-slate-50' +
      no_mockup('fixed right-0 top-0 bottom-0 overflow-y-scroll')
    }>
      <div class='papp-right-up   flex flex-col space-y-2 m-2 c'>{right}</div>
      <div class='papp-right-down flex flex-col space-y-2 m-2 c'>{right_down}</div>
    </div>
  </div>
}

// Mockups -----------------------------------------------------------------------------------------
export const MockupSection: FunctionComponent<{ title: string }> = (props) => {
  return <div class='pmockup block c'>
    <div class='relative ml-5 text-2xl'>{props.title}</div>
    <div class='border border-gray-300 rounded m-5 mt-1'>{props.children}</div>
  </div>
}

function stub_data() {
  const links = [
    "How to trade safely", "Stock Option Insurance", "Simulating Risk", "Math Modelling"
  ].map<[string, string]>(t => [t, '#'])

  const tags_cloud = entries({
    "Stock": 1, "Trading": 0, "Market": 2, "Dollar": 0, "Euro": 1,
    "Taxes": 1, "Currency": 0, "Profit": 0, "Loss": 2, "Option": 1,
    "Strategy": 0, "Backtesting": 0
  }).map(([text, size]) => ({ text, size }))

  const tags = tags_cloud.map(({ text }) => text)

  const controls = <>
    <IconButton icon='edit'/>,
    <IconButton icon='controls'/>
  </>

  return { links, tags, tags_cloud, controls }
}

const SearchMockup: FunctionComponent = (props) => {
  const data = stub_data()

  // Tags for TagCloud
  const incl = ['Trading', 'Profit'], excl = ['Euro']
  const tags = data.tags_cloud.map<CloudTag>(({ text }) => ({
    tag: text, type: incl.includes(text) ? 'included' : excl.includes(text) ? 'excluded' : 'ignored'
  }))

  const right = [
    <SearchField text="finance/ About PlaceX" info="Found 300 blocks in 20ms"/>,
    <TagsCloud tags={tags}/>
  ]

  const search_controls = [
    <IconButton icon="cross"/>
  ]

  const matches = [
    {
      before: 'there are multiple reasons about ',
      match:  'PlaceX',
      after:  ' every single of those reasons is big enough to stay away from such investment. PlaceX has all of them'
    },
    {
      before: 'Insane leverage. The minimal transaction Super',
      match:  'PlaceX',
      after:  ' is one lot equal to 100k$. If you'
    }
  ]
  return <MockupSection title='Search'>
    <App title='Found' title_controls={search_controls} show_block_separator right={right}>
      {Array.fill(4, 0).map(_ =>
        <FoundBlock title='About PlaceX' matches={matches} url="#"/>
      )}
      <Pagination count={200} page={2} per_page={30} url={_page => 'stub'}/>
    </App>
  </MockupSection>
}

const WarnsView: FunctionComponent = (props) => {
  return <MockupSection title='Warns'>
    <App title='Warns'>
      <BlockLayout name='pblock'>
        <Table rows={[
          [<Link text="Page 1" link="#"/>, <Warnings warns={[['12 warns', '/warns']]}/>],
          [<Link text="Page 2" link="#"/>, <Warnings warns={[['2 warns', '/warns']]}/>],
          [<Link text="Page 3" link="#"/>, <Warnings warns={[['2 warns', '/warns']]}/>],
        ]}></Table>
      </BlockLayout>
    </App>
  </MockupSection>
}

const NoteMockup: FunctionComponent = (props) => {
  const [get_doc, set_doc] = useState<{ loaded?: Result<{ source: string, doc: Doc }> }>({})
  async function load_doc() {
    if (get_doc.loaded) return
    const source = await browser_load_text('/keep/samples/sample-page.ht')
    const doc = await parse_htext(source)

    const ctx: ResolveContext = { errors: [], ensure_assets: [],
      async resolve_path(path) {
        path = path.replace(/^this/, '/keep/samples/sample-page')
        if (path == '/keep/samples/sample-page/knots/losa') return { type: 'file', path: '/keep/samples/sample-page/knots/losa.jpg' }
        return { type: 'file', path }
      },
      async read_dir(path) {
        if (path == 'this/knots/') return [
          'begushii.jpg', 'blakes-hitch.png', 'bulin.jpg', 'butterfly.jpg',
          'constrictor-carabine.jpg', 'dvoynoy-bulin.jpg', 'eight-slow.jpg', 'eight.jpg',
          'kreplenie-palatki.png', 'losa.jpg'
        ].map<EntryHead>(p => ({ type: 'file', path: `/keep/samples/sample-page/knots/${p}` }))

        return []
      }
    }

    await doc.resolve(ctx)
    await ensure_loaded(...ctx.ensure_assets)

    doc.errors.add(...ctx.errors)

    set_doc({ loaded: to_success({ doc, source }) })
  }
  load_doc()

  const data = stub_data()

  const right = [
    <SearchField text="finance/ About PlaceX" info="Found 300 blocks in 20ms"/>,
    <Favorites links={data.links}/>,
    <TagsCloud tags={data.tags_cloud.map(t => ({ tag: t.text }))}/>,
    <Backlinks links={data.links}/>,
    <RBlock name='prblock-other' title='Other' closed/>
  ]

  const right_down = [
    <RBlock name='prblock-warnings'>
      <Warnings warns={[['12 warns', '/warns']]}/>
    </RBlock>
  ]

  let content: VNode
  if (!get_doc.loaded)            content = <Progress/>
  else if (get_doc.loaded.error7) content = <Message text={get_doc.loaded.error} type='error'/>
  else {
    const { doc } = get_doc.loaded.result
    const ctx: HtmlContext = {
      link_path:  path => path,
      tag_path:   tag => '/tag/' + tag,
      asset_path: path => path,
    }

    // Rendering doc blocks
    const errors: DocError[] = [{ error: 'Some error', context: 'Some context' }, ...doc.errors]
    const blocks: VNode[] = []
    for (const section of doc.sections) {
      if (section.title || !section.tags.empty7()) { blocks.add(
        <BlockLayout name='pblock' hover controls={[<IconButton icon='edit'/>]}>
          <SectionHeader title={section.title || 'Section'} tags={section.tags}/>
        </BlockLayout>
      ) }
      for (const chapter of section.chapters) {
        if (chapter.title || !chapter.tags.empty7()) { blocks.add(
          <BlockLayout name='pblock' hover controls={[<IconButton icon='edit'/>]}>
            <ChapterHeader title={chapter.title || 'Chapter'} tags={section.tags}/>
          </BlockLayout>
        ) }
        for (const block of chapter.blocks) {
          const html = block.to_html(ctx)
          blocks.add(
            <BlockLayout name='pblock' tags={block.tags} hover controls={[<IconButton icon='edit'/>]}>
              {string7(html) ?
                <div class="htext" dangerouslySetInnerHTML={{ __html: html }}/> :
                <div class="htext">{html}</div>
              }
            </BlockLayout>
          )
        }
      }
    }

    content = <App title='Note' tags={doc.tags} right={right} right_down={right_down}>
      {errors.length > 0 && <BlockLayout name='pblock-errors'>
        {errors.map(e =>
          <Message text={`${e.error} (${e.context})`} type='error'/>
        )}
      </BlockLayout>}
      {blocks}
    </App>
  }

  return <MockupSection title='Note'>
    {content}
  </MockupSection>
}


export function run_as_mockup() {
  // document.getElementById('styles')!.innerHTML = styles.join("\n")
  render(<Mockup/>, document.body)
}

export const Mockup: FunctionComponent<{}> = () => {
  assert.equal(parse('a: 1', 'yaml'), { a: 1 }) // Checking parsers are loaded

  return <PaletteContext.Provider value={{ mockup_mode: true, assets_path: '/keep', tags_path: 'stub' }}>
    <NoteMockup/>

    <SearchMockup/>

    <WarnsView/>

    <MockupSection title="Message">
      <App>
        <BlockLayout>
          <Message text="Some message"/>
        </BlockLayout>
      </App>
    </MockupSection>

    <MockupSection title="Top Message">
      <Message text="Some message" top/>
    </MockupSection>

    <MockupSection title='Plot'>
      <App>
        <BlockLayout>
          <PlotView plot={{
            plot: ['bar', { x: 'a', type: 'nominal' }, { y: 'b' }],
            data: { a: [1, 2, 3, 4, 5], b: [1, 3, 2, -1, -2] } }}
          />
        </BlockLayout>
      </App>
    </MockupSection>
  </PaletteContext.Provider>
}


