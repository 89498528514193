import { value_to_s, def } from './support'
import { EnumerableImpl } from './enumerable'
import './number'
import './object'

def(Array.prototype, EnumerableImpl, { overload: false })

def(Array.prototype, {
  size<T>(this: T[]): number { return this.length },

  equal7<T>(this: T[], other: T[]): boolean {
    if (this.length != other.length) return false
    for (let i = 0; i < this.length; i++) if (!equal7(this[i], other[i])) return false
    return true
  },

  add<T>(this: T[], ...v: T[]) { this.push(...v) },

  to_s: array_to_s,
  toStringOriginal: Array.prototype.toString,
  toString: array_to_s,

  get<T>(this: T[], i: number, i2?: number): T | T[] {
    // Both indexes inclusive
    if (i2 == undefined) {
      if (i < 0) {
        const j = i + this.length
        if (j < 0) raise(`index out of bounds: ${i}`)
        return this[j]
      } else {
        if (i >= this.length) raise(`index out of bounds: ${i}`)
        return this[i]
      }
    } else {
      // i = (i   >= 0 ? i  : this.length + i ).max(0).min(this.length - 1)
      // i2 = (i2 >= 0 ? i2 : this.length + i2).max(i).min(this.length - 1)
      // if (i > i2) raise(`invalid indexes, i should be <= i2: ${i}, ${i2}`)
      i =  i  >= 0 ? i  : this.length + i
      i2 = i2 >= 0 ? i2 : this.length + i2
      if (i < 0)            raise(`index out of bounds i: ${i}`)
      if (i > i2)           raise(`invalid indexes, i should be less or equal i2: ${i}, ${i2}`)
      if (i2 > this.length) raise(`index out of bounds i2: ${i2}`)
      return this.slice(i, i2 + 1)
    }
  },

  get_relaxed<T>(this: T[], i: number, i2: number): T[] {
    i =  i  >= 0 ? i  : this.length + i
    i2 = i2 >= 0 ? i2 : this.length + i2
    if (i < 0)            i = 0
    if (i2 > this.length) i2 = this.length - 1
    if (i > i2)           i = i2
    return this.length == 0 ? [] : this.slice(i, i2 + 1)
  },

  set<T>(this: T[], i: number, v: T): void {
    if (i < 0) {
      const j = i + this.length
      if (j < 0) raise(`index out of bounds: ${i}`)
      this[j] = v
    } else {
      if (i >= this.length) raise(`index out of bounds: ${i}`)
      this[i] = v
    }
  },

  delete_at<T>(this: T[], i: number): T {
    let r
    if (i < 0) {
      const j = i + this.length
      if (j < 0) raise(`index out of bounds: ${i}`)
      r = this[i]
      this.splice(j, 1)
    } else {
      if (i >= this.length) raise(`index out of bounds: ${i}`)
      r = this[i]
      this.splice(i, 1)
    }
    return r
  },

  del_at<T>(this: T[], i: number): T {
    let r
    if (i < 0) {
      const j = i + this.length
      if (j < 0) raise(`index out of bounds: ${i}`)
      r = this[i]
      this[j] = this[this.length - 1]
      this.pop()
    } else {
      if (i >= this.length) raise(`index out of bounds: ${i}`)
      r = this[i]
      this[i] = this[this.length - 1]
      this.pop()
    }
    return r
  },

  del<T>(this: T[], op: T | ((v: T, i: number) => boolean)): void {
    const i = this.find_i(op)
    if (i != undefined) this.del_at(i)
  },

  delete<T>(this: T[], op: T | ((v: T, i: number) => boolean)): void {
    const i = this.find_i(op)
    if (i != undefined) this.delete_at(i)
  },

  /** More efficient implementatkon than in the Enumerable */
  quantile(this: number[], q: number, sorted7 = false): number {
    if (this.length == 0) throw new Error("can't calculate quantile on empty array")
    const sorted = sorted7 ? this : [...this].order((a, b) => a - b)
    const pos = (sorted.length - 1) * q
    const base = Math.floor(pos)
    const rest = pos - base
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base])
    } else {
      return sorted[base]
    }
  }
})

Array.fill = function<T>(n: number, op: T | ((i: number) => T)): T[] {
  const r = new Array(n)
  if (typeof op == 'function') for (let i = 0; i < n; i++) r[i] = (op as (i: number) => T)(i)
  else                         for (let i = 0; i < n; i++) r[i] = op
  return r
}

function array_to_s(this: Array<unknown>): string {
  return '[' + (this as unknown as unknown[]).map(value_to_s).join(', ') + ']'
}