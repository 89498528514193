import 'base'
import { escape_html } from 'ext/el'
import * as fschema from 'htext/parser/schema'
import { El, HText, ResolveContext, HtmlContext } from './doc'
import { el_from_htext as from_htext } from './doc/htext_parsers'
import { get_base_name } from 'ext/fs'

// Tag ---------------------------------------------------------------------------------------------
export class TagEl implements El {
  type = 'tag'
  constructor(readonly tag: string) {}

  to_text() { return [`#${this.tag}`] }

  to_html(ctx: HtmlContext) {
    return `<a class="tag" href="${escape_html(ctx.tag_path(this.tag))}">${escape_html(this.tag)}</a>`
  }
}
from_htext('tag', async ({ tag }: fschema.TagItem) => new TagEl(tag))

// Code --------------------------------------------------------------------------------------------
export class CodeEl implements El {
  type = 'code'
  constructor(readonly code: string) {}

  to_text() { return [this.code] }

  to_html() { return `<code>${escape_html(this.code)}</code>` }
}
from_htext('code',  async ({ code }: fschema.CodeItem)  => new CodeEl(code))
from_htext('embed', async ({ text }: fschema.EmbedItem) => new CodeEl(text))
from_htext('eval',  async ({ text }: fschema.EmbedItem) => new CodeEl(text))

// Math --------------------------------------------------------------------------------------------
export class MathEl implements El {
  type = 'math'
  constructor(readonly math: string) {}

  to_text() { return [this.math] }

  async resolve(ctx: ResolveContext): Promise<void> {
    ctx.ensure_assets.add(
      '/vendor/katex-0.16.11/katex.min.js',
      '/vendor/katex-0.16.11/katex.css',
    )
  }

  to_html() {
    try {
      const katex = ensure((window as any).katex, 'KaTeX not loaded')
      return katex.renderToString(this.math, { throwOnError: true })
    } catch (e) {
      return error_to_html(this.math, [error_message(e)])
    }
  }
}
from_htext('math', async ({ text }: fschema.EmbedItem)  => new MathEl(text))

// Error -------------------------------------------------------------------------------------------
export class ErrorEl implements El {
  type = 'error'
  constructor(readonly text: string, readonly errors: string[]) {}

  to_text() { return [this.text] }

  to_html() { return error_to_html(this.text, this.errors) }
}
from_htext('error', async ({ text, errors }: fschema.ErrorItem, ctx) => {
  ctx.errors.add(...errors.map(error => ({ error, context: text })))
  return new ErrorEl(text, errors)
})

export function error_to_html(text: string, errors: string[]): string {
  return `<span class="error" title="${escape_html(errors.join(', '))}">${escape_html(text)}</span>`
}

// Link --------------------------------------------------------------------------------------------
export class LinkEl implements El {
  type = 'link'
  resolved?: Result<string>
  constructor(readonly path: string, readonly title?: string) {}

  async resolve(ctx: ResolveContext) {
    if (is_global_link(this.path)) {
      this.resolved = to_success(this.path)
    } else {
      const entry = await ctx.resolve_path(this.path)
      if (!entry)             {
        const error = `Link not found: ${this.path}`
        ctx.errors.add({ error })
        this.resolved = to_error(error)
      } else {
        this.resolved = to_success(entry.path)
      }
    }
  }

  to_text() { return [this.path] }

  to_html(ctx: HtmlContext) {
    const resolved = ensure(this.resolved)
    if (resolved.error7) return error_to_html(this.path, [resolved.error])
    const path = resolved.result, klass = is_global_link(path) ? 'glink' : 'link'
    if (path.start_with7('file://')) {
      // Browser can't open local files, showing it as a path
      const fpath = escape_html(this.path.replace('file://', ''))
      return `${this.title ? escape_html(this.title) + ' ' : ''}<code>${fpath}</code>`
    } else {
      return `<a class="${klass}" href="${escape_html(ctx.link_path(path))}" target="_blank">` +
        escape_html(this.title ?? get_base_name(path)) +
      `</a>`
    }
  }
}
from_htext('link', async ({ link, title }: fschema.LinkItem) => new LinkEl(link, title))

function is_global_link(path: string) { return /^[a-z]+:\/\//.test(path) }

// Image -------------------------------------------------------------------------------------------
export class ImageEl implements El {
  type = 'image'
  resolved?: Result<string>
  constructor(readonly path: string, readonly title?: string) {}

  async resolve(ctx: ResolveContext) {
    this.resolved = await resolve_image_path(this.path, ctx)
  }

  to_text() { return this.title ? [this.path, this.title] : [this.path] }

  to_html(ctx: HtmlContext) {
    const resolved = ensure(this.resolved)
    if (resolved.error7) return error_to_html(this.path, [resolved.error])
    const image_path = ctx.asset_path(resolved.result)
    return `<img class="image" src="${escape_html(image_path)}" title="${escape_html(this.title || '')}"/>`
  }
}
from_htext('image', async ({ path, title }: fschema.ImageItem) => new ImageEl(path, title))
from_htext('img', async ({ text }: fschema.EmbedItem) => new ImageEl(text))

export async function resolve_image_path(path: string, ctx: ResolveContext): Promise<Result<string>> {
  const entry = await ctx.resolve_path(path)
  if (!entry)             {
    const error = `Image not found: ${path}`
    ctx.errors.add({ error })
    return to_error(error)
  } else if (entry.type != 'file') {
    const error = `Image expected to be a file, not directory: ${path}`
    ctx.errors.add({ error })
    return to_error(error)
  } else {
    return to_success(entry.path)
  }
}

// Html --------------------------------------------------------------------------------------------
export class HtmlEl implements El {
  type = 'html'
  constructor(readonly html: string) {}

  to_text() { return [this.html] }

  to_html() { return this.html }
}
from_htext('html', async ({ text }: fschema.HtmlItem) => new HtmlEl(text))

// Text --------------------------------------------------------------------------------------------
export class TextEl implements El {
  type = 'text'
  constructor(readonly text: string) {}

  to_text() { return [this.text] }

  to_html() { return escape_html(this.text) }
}
from_htext('text', async ({ text }: fschema.TextItem) => new TextEl(text))


// Helpers -----------------------------------------------------------------------------------------
export function to_html(els: HText, ctx: HtmlContext): string {
  const r: string[] = []; let em = false

  for (const el of els) {
    if ((el.em ?? false) != em) {
      r.push(el.em ? '<em>' : '</em>')
      em = el.em ?? false
    }
    r.add(el.to_html(ctx))
  }
  if (em) r.add('</em>')
  return r.join('')
}

export function to_text(els: HText): string {
  return els.map(el => el.to_text()).flat().join(' ')
}