import 'base'
import { error_to_html, resolve_image_path, to_html, to_text } from './elements'
import { to_html as els_to_html, h } from 'ext/el'
import * as fschema from 'htext/parser/schema'
import { block_from_htext as from_htext } from './doc/htext_parsers'
import { BlockEl, HText, HTextItem, ResolveContext, HtmlContext } from './doc'
import { parse_text_items } from './doc/htext'

import './blocks/images'
import './blocks/table'
import './blocks/plot'
import './blocks/datatable'

export type Props = Record<string, unknown>

// Paragraphs --------------------------------------------------------------------------------------
export class ParagraphsBlockEl implements BlockEl {
  type = 'paragraphs'; show_tags = false

  constructor(readonly id: string | nil, readonly tags: string[], readonly paragraphs: HText[]) {}

  async resolve(ctx: ResolveContext) {
    const promises: (Promise<void> | nil)[] = []
    this.paragraphs.map(pr => pr.each(item => promises.add(item.resolve?.(ctx))))
    await Promise.all(promises)
  }

  to_text() { return [...head_to_text(this.tags, {}), ...this.paragraphs.map(p => to_text(p)).flat() ] }

  to_html(ctx: HtmlContext) {
    return els_to_html(this.paragraphs.map(p => h('p', {}, h('raw', {}, to_html(p, ctx)))))
  }
}
from_htext('paragraphs', async (id, tags, b: fschema.ParagraphsBlock, ctx) => {
  return new ParagraphsBlockEl(id, tags, await parse_text_items(b.paragraphs, ctx))
})

// List --------------------------------------------------------------------------------------------
export class ListBlockEl implements BlockEl {
  type = 'list'; show_tags = false
  constructor(readonly id: string | nil, readonly tags: string[], readonly list: HText[]) {}

  async resolve(ctx: ResolveContext) {
    const promises: (Promise<void> | nil)[] = []
    this.list.each(pr => pr.each(item => promises.add(item.resolve?.(ctx))))
    await Promise.all(promises)
  }

  to_text() { return [...head_to_text(this.tags, {}), ...this.list.map(p => to_text(p)).flat() ] }

  to_html(ctx: HtmlContext) {
    return h('ul', {},
      ...this.list.map(p => h('li', {}, h('raw', {}, to_html(p, ctx))))
    ).to_html()
  }
}
from_htext('list', async (id, tags, b: fschema.ListBlock, ctx) => {
  return new ListBlockEl(id, tags, await parse_text_items(b.list, ctx))
})

// Code --------------------------------------------------------------------------------------------
export class CodeBlockEl implements BlockEl {
  type = 'code'
  constructor(readonly id: string | nil, readonly tags: string[], readonly code: string, readonly lang?: string) {}

  async resolve(ctx: ResolveContext) {
    ctx.ensure_assets.add(
      '/vendor/highlight-11.9.0/highlight.min.js',
      '/vendor/highlight-11.9.0/styles/default.min.css'
    )
  }

  to_text() { return [...head_to_text(this.tags, {}), this.code ] }

  to_html() {
    try {
      const hl = ensure((window as any).hljs, 'highlight.js not loaded')
      const code = this.lang ?
        hl.highlight(this.code, { language: this.lang }).value :
        hl.highlightAuto(this.code).value
      return h('pre', { lang: this.lang }, h('raw', {}, code)).to_html()
    } catch (_e) {
      return h('pre', { lang: this.lang }, this.code).to_html()
    }
  }
}
from_htext('code', async (id, tags, b: fschema.CodeBlock) => {
  return new CodeBlockEl(id, tags, b.code, b.lang)
})

// Image -------------------------------------------------------------------------------------------
export class ImageBlockEl implements BlockEl {
  type = 'image'
  resolved?: Result<string>
  constructor(readonly id: string | nil, readonly tags: string[], readonly path: string, readonly title?: string) {}

  async resolve(ctx: ResolveContext) {
    this.resolved = await resolve_image_path(this.path, ctx)
  }

  to_text() { return [...head_to_text(this.tags, {}), this.path, ...(this.title ? [this.title] : []) ] }

  to_html(ctx: HtmlContext) {
    const resolved = ensure(this.resolved)
    if (resolved.error7) return error_to_html(this.path, [resolved.error])

    const path = ctx.asset_path(resolved.result)
    return h('img', { src: path, title: this.title || '' }).to_html()
  }
}
from_htext('image', async (id, tags, b: fschema.ImageBlock) => {
  return new ImageBlockEl(id, tags, b.path, b.title)
})

// TextItem ----------------------------------------------------------------------------------------
export class TextItemBlockEl implements BlockEl {
  type = 'text_item'
  constructor(readonly id: string | nil, readonly tags: string[], readonly item: HTextItem) {}

  async resolve(ctx: ResolveContext) { await this.item.resolve?.(ctx) }

  to_text() { return [...head_to_text(this.tags, {}), ...this.item.to_text()] }

  to_html(ctx: HtmlContext) { return this.item.to_html(ctx) }
}
from_htext('text_item', async (id, tags, b: fschema.TextItemBlock, ctx) => {
  return new TextItemBlockEl(id, tags, await parse_text_items(b.item, ctx))
})

// Html --------------------------------------------------------------------------------------------
export class HtmlBlockEl implements BlockEl {
  type = 'html'
  constructor(readonly id: string | nil, readonly tags: string[], readonly html: HText) {}

  async resolve(ctx: ResolveContext) {
    const promises: (Promise<void> | nil)[] = []
    this.html.each(item => promises.add(item.resolve?.(ctx)))
    await Promise.all(promises)
  }

  to_text() { return [...head_to_text(this.tags, {}), ...to_text(this.html)] }

  to_html(ctx: HtmlContext) {
    return to_html(this.html, ctx)
  }
}
from_htext('html', async (id, tags, b: fschema.HtmlBlock, ctx) => {
  return new HtmlBlockEl(id, tags, await parse_text_items(b.html, ctx))
})

// Unknown -----------------------------------------------------------------------------------------
export class UnknownBlockEl implements BlockEl {
  type = 'unknown'
  constructor(readonly id: string | nil, readonly tags: string[], readonly block_type: string) {}

  to_text() { return [...head_to_text(this.tags, {}), `Unknown block: ${this.block_type}` ] }

  to_html() {
    return h('div', { class: 'border-l-4 border-orange-800 text-orange-800' },
      h('div', { class: 'ml-2' }, `Unknown block: ${this.block_type}`)
    ).to_html()
  }
}

// Helpers -----------------------------------------------------------------------------------------
// export function generate_id(...data: unknown[]) {
//   return crypto.hash(to_s(data)).get(0, 4)
// }

export function head_to_text(tags: string[], ...args: unknown[]): string[] {
  const text = tags.map(t => `#${t}`)
  args.each(v => data_to_text(v, text))
  return text
}

function data_to_text(v: unknown, buff: string[]) {
  if (v === undefined || v === null || v === '') return

  if       (array7(v)) v.each(v => data_to_text(v, buff))
  else if (record7(v)) entries(v).each((entry) => data_to_text(entry, buff))
  else                 buff.add(to_s(v))
}