import 'base'
import { Pos, name_chars, name_start_chars } from './schema'

export function escape_data(text: string): string {
  const positions: Pos[] = []

  let i = 0, inside_of_multiline_html_block = false
  function count(r: [number, number, number] | nil) {
    if (r == nil) return
    const [new_i, start, end] = r
    i = new_i
    positions.add([start, end])
  }
  for (; i < text.size(); i++) {
    if (inside_of_multiline_html_block) {
      if (html_multiline_block_end7(text, i))   inside_of_multiline_html_block = false
    } else {
      if (html_multiline_block_start7(text, i)) inside_of_multiline_html_block = true
    }

    count(try_consume_bracket_expression(text, i))
    count(try_consume_tick_expression(text, i))
    count(try_consume_multiline_block(text, i))
    if (!inside_of_multiline_html_block) count(try_consume_indented_block(text, i))
  }

  const buff: string[] = []; let j = 0
  for (const [start, end] of positions) {
    while (j < start) buff.add(text[j++])
    while (j <= end)  buff.add(replace_with_stub(text[j++]))
  }
  while (j < text.length) buff.add(text[j++])

  return buff.join('')
}

function replace_with_stub(raw: string): string { return raw.replace(/[^\n\s]/g, 'X') }

function try_consume_bracket_expression(text: string, i: number): [number, number, number] | nil {
  if (!(text[i] == '{')) return

  i++
  const start = i
  let level = 0
  while (i < text.size() && (level > 0 || text[i] != "}")) {
    if (text[i] == "{") level++
    if (text[i] == "}") level--
    i++
  }
  return [i, start, i - 1]
}

function try_consume_tick_expression(text: string, i: number): [number, number, number] | nil {
  if (!(text[i] == '`' && text[i+1] != '`')) return nil

  i++
  const start = i
  while (i < text.size() && text[i] != "`") i++
  return [i, start, i - 1]
}

function try_consume_multiline_block(text: string, i: number): [number, number, number] | nil {
  // if (!(text.start_with7(/```[\S\s]/g, i) && (i == 0 || at7(text, i - 1, "\n")))) return nil
  if (!((text[i] == '`' && text[i+1] == '`' && text[i+2] == '`') && (i == 0 || text[i - 1] == "\n"))) return nil

  i += 3
  const start = i
  while (i < text.size() && !text.start_with7("\n```", i)) i++
  return [i + 4, start, i - 1]
}

function try_consume_indented_block(text: string, i: number): [number, number, number] | nil {
  // if (!(text.start_with7(/  [\S\s]/g, i) && (i == 0 || at7(text, i - 1, "\n")))) return nil
  if (!((text[i] == ' ' && text[i+1] == ' ') && (i == 0 || text[i - 1] == "\n"))) return nil

  const start = i
  while (i < text.size() && !(text[i] == "\n" && !text.start_with7("\n  ", i))) i++
  return [i, start, (i - 1).min(text.length - 1)]
}

// Inside multiline html indented block should be ignored.
function html_multiline_block_start7(text: string, i: number): boolean {
  return (
    at7(text, i, '<') &&
    (i == 0 || at7(text, i - 1, "\n")) && // Start of line
    text.start_with7(new RegExp(`<[${name_start_chars}][^>]*>\s*\n`, 'gi'), i)
  )
}
function html_multiline_block_end7(text: string, i: number): boolean {
  return (
    at7(text, i, '<') &&
    (i == 0 || at7(text, i - 1, "\n")) && // Start of line
    text.start_with7(new RegExp(`</[${name_start_chars}][${name_chars}]*>\s*\n`, 'gi'), i)
  )
}

// Helpers -----------------------------------------------------------------------------------------
function at7(text: string, i: number, char: string): boolean {
  return i < text.length ? text[i] == char : false
}