import { key_to_s, value_to_s } from './support'
import './number'
import './string'
import './array'
import { compare_iterables } from './misc'

class Hash<K, V> {
  _buckets = new Map<number, [K, V][]>()
  _size    = 0

  // constructor()
  // constructor(items: (K extends string ? Record<string, V> | undefined : undefined))
  // constructor(items: [K, V][])
  // constructor(items?: Record<string, V>) {
  //   if (items) for (let k in items) this.set(k as any, items[k])
  // }
  constructor() {}

  get(k: K): V {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, v] of pairs) if (equal7(vk, k)) return v
    raise(`key not found: ${k}`)
  }

  get_or(k: K, dflt: V | ((k: K) => V)): V {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, v] of pairs) if (equal7(vk, k)) return v
    const d = dflt instanceof Function ? dflt(k) : dflt
    this.set(k, d)
    return d
  }

  set(k: K, v: V) {
    const hash = k?.hash_code() ?? 0
    let pairs = this._buckets.get(hash)
    if (!pairs) this._buckets.set(hash, pairs = [])
    for (const [vk, _v] of pairs) if (equal7(vk, k)) return
    pairs.push([k, v])
    this._size++
  }

  has7(k: K): boolean {
    const hash = k?.hash_code() ?? 0
    const pairs = this._buckets.get(hash)
    if (pairs) for (const [vk, _v] of pairs) if (equal7(vk, k)) return true
    return false
  }

  equal7(other: Hash<K, V>): boolean {
    if (this._size != other._size) return false
    for (const [k, v] of this) if (!equal7(v, other.get(k))) return false
    return true
  }

  keys(): K[] { return [...this].map(([k, _v]) => k) }

  values(): V[] { return [...this].map(([_k, v]) => v) }

  entries(): [K, V][] { return [...this] }

  *[Symbol.iterator](): Iterator<[K, V]> {
    for (const pairs of this._buckets.values()) for (const pair of pairs) yield pair
  }

  hash_code(): number {
    return [...this].order((pair) => pair[0]).hash_code()
  }

  compare(other: this): number {
    return compare_iterables([...this].order((pair) => pair[0]), [...other].order((pair) => pair[0]))
  }

  size(): number { return this._size }

  empty7(): boolean { return this._size == 0 }

  map<R>(op: (v: V, k: K) => R): Hash<K, R> {
    const r = new Hash<K, R>()
    for (const [k, v] of this) r.set(k, op(v, k))
    return r
  }

  to_object(): Record<string, V> {
    const obj: Record<string, V> = {}
    for (const [k, v] of this) if (!string7(k)) raise(`key must be a string: ${k}`)
    for (const [k, v] of this) obj[k as string] = v
    return obj
  }

  to_s(): string {
    const entries = [...this].map(([k, v]) => key_to_s(to_s(k)) + ': ' + value_to_s(v))
    return this.constructor.name + '(' + entries.join(', ') + ')'
  }
}
(window as any).Hash = Hash

function H<V>(items: Record<string, V>): Hash<string, V>
function H<K, V>(items: [K, V][]): Hash<K, V>
function H<K, V>(items: Record<string, V> | [K, V][]): Hash<any, any> {
  const h = new Hash()
  if (items instanceof Array) {
    for (const [k, v] of items) h.set(k, v)
  } else {
    for (const k in items) h.set(k, items[k])
  }
  return h
}
(window as any).H = H